import React, { useContext, useEffect, useState, useReducer } from "react";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  ListSubheader,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import SearchIcon from "@material-ui/icons/Search";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import AddParticipantsInternalGroupModal from "../AddParticipantsInternalGroupModal";
import api from "../../services/api";
import InternalChatImg from "../../assets/internalChat.png";
import InternalGroupParticipantsContextMenu from "../InternalGroupParticipantsContextMenu";
import toastError from "../../errors/toastError";

import SocketTicketUsers from "../../context/Socket/Listeners/SocketTicketUsers";

const reducer = (state, action) => {
  if (action.type === "LOAD_TICKET_USERS") {
    const ticketUsers = action.payload;
    const newTicketUsers = [];

    ticketUsers.forEach((dispatchSending) => {
      const ticketUserIndex = state.findIndex((tu) => tu.id === dispatchSending.id);
      if (ticketUserIndex !== -1) { state[ticketUserIndex] = dispatchSending; }
      else { newTicketUsers.push(dispatchSending); }
    });

    return [...state, ...newTicketUsers];
  }

  if (action.type === "PROMOTE_DEMOTE_TICKET_USERS") {
    const ticketUser = action.payload;
    const ticketUserIndex = state.findIndex((tu) => tu.id === ticketUser.id);

    if (ticketUserIndex !== -1) {
      state[ticketUserIndex] = ticketUser;
      return [...state];
    }
    else { return [ticketUser, ...state]; }
  }

  if (action.type === "ADD_TICKET_USERS") {
    const ticketUsers = action.payload;
    return [...ticketUsers, ...state];
  }

  if (action.type === "DELETE_TICKET_USER") {
    const userId = action.payload;
    const ticketUserId = state.findIndex((tu) => tu.userId === userId);
    if (ticketUserId !== -1) { state.splice(ticketUserId, 1); }
    return [...state];
  }

  if (action.type === "RESET") return [];
};

const useStyles = makeStyles(theme => ({  
  // *************
  // ** Content **
  // *************
  dialogTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  iconButton: { padding: "0px", },

  dialogContent: { overflowY: "scroll", ...theme.scrollbarStyles, },

  participantCard: { borderRadius: "20px", padding: "10px", marginBottom: "5px", },

  participantCardActionArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "20px",
    padding: "5px",
  },

  participantName: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.5em",
    fontSize: "16px",
  },

  floatingButton: {
    transition: "transform 0.3s",
    "&:hover": { transform: "translateY(-5px)", },
  },



  //  ***********************
  //  ** Circular Progress **
  //  ***********************
  circularProgressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const InternalGroupParticipantsModal = ({ open, onClose, ticketId }) => {
  //  *************
  //  ** Classes **
  //  *************
  const { user } = useContext(AuthContext);
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  const [filteredParticipantsList, setFilteredParticipantsList] = useState([]);
  const [participantsList, dispatch] = useReducer(reducer, []);

  const [isAddParticipantsModalOpen, setIsAddParticipantsModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isParticipantsContextMenuOpen, setIsParticipantsContextMenuOpen] = useState(false);
  const [selectedParticipantContextMenu, setSelectedParticipantContextMenu] = useState(null);



  //  *****************
  //  ** Use-Effects **
  //  *****************
  useEffect(() => {
    if (open) {
      const delayDebounceFn = setTimeout(() => {
        const fetchParticipants = async () => {
          setLoading(true);

          try {
            const profileHierarchy = { superadmin: 1, admin: 2, member: 3 };
            const { data: participantsList } = await api.get(`/internalGroups/participants/${ticketId}`);
            const sortedParticipantsList = participantsList.sort((participantA, participantB) => {
              const profileComparison = profileHierarchy[participantA.userProfile] - profileHierarchy[participantB.userProfile];
              if (profileComparison !== 0) return profileComparison;
              return participantA.user.name.localeCompare(participantB.user.name);
            });

            dispatch({ type: "LOAD_TICKET_USERS", payload: sortedParticipantsList });
            setFilteredParticipantsList(sortedParticipantsList);
          }
          catch (exception) { toastError(exception); }
          finally { setLoading(false); }
        };

        fetchParticipants();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [open, ticketId]);

  useEffect(() => {
    if (searchParam === "") {
      setFilteredParticipantsList(participantsList);
    }
    else {
      setFilteredParticipantsList(participantsList.filter(
        participant => participant.user.name && participant.user.name.toLowerCase().includes(searchParam)
      ));
    }
  }, [searchParam, participantsList]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();

    setLoading(false);
    setSearchParam("");

    setIsAddParticipantsModalOpen(false);

    setIsParticipantsContextMenuOpen(false);
    setSelectedParticipantContextMenu(null);
    setAnchorEl(null);

    setFilteredParticipantsList([]);
    dispatch({ type: "RESET" });
  };

  const handleOpenAddParticipantsModal = () => {
    setIsAddParticipantsModalOpen(true);
  };

  const handleCloseAddParticipantsModal = () => {
    setIsAddParticipantsModalOpen(false);
  };

  const handleOpenContextMenu = (event, participant) => {
    setAnchorEl(event.currentTarget);
    setSelectedParticipantContextMenu(participant);
    setIsParticipantsContextMenuOpen(true);
  };

  const handleCloseContextMenu = () => {
    setIsParticipantsContextMenuOpen(false);
    setSelectedParticipantContextMenu(null);
    setAnchorEl(null);
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase().trim();
    setSearchParam(searchValue);

    if (searchValue === "") {
      setFilteredParticipantsList(participantsList);
    }
    else {
      setFilteredParticipantsList(participantsList.filter(
        participant => participant.user.name && participant.user.name.toLowerCase().includes(searchValue)
      ));
    }
  };

  const renderDivider = () => {
    return (
      <>
        <br />
        <Divider />
        <br />
      </>
    );
  };

  const renderParticipantsCardList = (participantProfile, addDividerInTheEnd) => {
    const participantsToRender = filteredParticipantsList.filter(
      participant => participant.userProfile === participantProfile
    );

    return (
      <>
        <ListSubheader disableSticky>
          {i18n.t(`internalGroupParticipantsModal.userProfile.${participantProfile}`)}
        </ListSubheader>

        {participantsToRender.map(participant => (
          <Card className={classes.participantCard}>
            <CardActionArea
              className={classes.participantCardActionArea}
              onClick={(event) => handleOpenContextMenu(event, participant)}
            >
              <Avatar
                alt={participant?.user?.name}
                src={InternalChatImg}
                className={classes.participantAvatar}
              />

              <CardContent>
                <Typography className={classes.participantName} component="h3" gutterBottom>
                  {participant?.user?.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}

        {addDividerInTheEnd && renderDivider()}
      </>
    );
  };


  
  //  ************
  //  ** Return **
  //  ************
  return (
    <Dialog fullWidth open={open} onClose={handleClose} maxWidth="xs" scroll="paper">
      <SocketTicketUsers dispatch={dispatch} />

      <DialogTitle>
        <div className={classes.dialogTitleContainer}>
          <span>{participantsList.length} {i18n.t("internalGroupParticipantsModal.title.up")}</span>

          {participantsList.some(
            participant => participant.userId === user.id && participant.userProfile !== "member"
          ) && (
            <Tooltip title={i18n.t("internalGroupParticipantsModal.tooltips.addButton")} placement="top-start" arrow>
              <Button
                color="primary"
                variant="contained"
                className={classes.floatingButton}
                onClick={handleOpenAddParticipantsModal}
              >
                <IconButton color="inherit" className={classes.iconButton}>
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              </Button>
            </Tooltip>
          )}
        </div>

        <br /><br/>

        <TextField
          autoFocus
          fullWidth

          type="search"
          placeholder={i18n.t("internalGroupParticipantsModal.searchPlaceholder")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: "gray" }} />
              </InputAdornment>
            ),
          }}

          value={searchParam}
          onChange={handleSearch}
        />
      </DialogTitle>

      <DialogContent className={classes.dialogContent} dividers>
        {!loading && open && participantsList.length > 0 && (
          <>
            {renderParticipantsCardList("superadmin", true)}
            {renderParticipantsCardList("admin", true)}
            {renderParticipantsCardList("member", false)}
            
            <InternalGroupParticipantsContextMenu
              open={isParticipantsContextMenuOpen}
              onClose={handleCloseContextMenu}
              anchorEl={anchorEl}
              selectedParticipant={selectedParticipantContextMenu}
              allParticipants={participantsList}
            />

            <AddParticipantsInternalGroupModal
              open={isAddParticipantsModalOpen}
              onClose={handleCloseAddParticipantsModal}
              ticketId={ticketId}
              participantIds={participantsList.map(participant => participant.userId)}
            />
          </>
        )}

        {loading && (
          <div className={classes.circularProgressContainer}>
            <CircularProgress size={24} className={classes.buttonProgress} />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InternalGroupParticipantsModal;
