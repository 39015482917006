import { writeTitle, writeHeaderInfo } from "./writers";
import { alignRatingsReportHeader, alignCenterAndMiddleHeader, alignTicketsDataReportFooter } from "./alignments";

import { areDatesSameDay } from "../datetime/areDatesSameDay";
import { dateFormatterConstructor, dateTimeFormatterConstructor } from "../intl/formatters";

export const header = ({ document, titleText, headerText, headerInitialPeriod, headerFinalPeriod }) => {
  const pageWidth = document.internal.pageSize.width;
  const dateFormatter = dateFormatterConstructor({ locale: localStorage.getItem("i18nextLng") });
  const isPeriodSingleDay = areDatesSameDay(headerInitialPeriod, headerFinalPeriod);

  writeTitle({
    document
    , text: titleText
    , positionX: pageWidth / 2
    , positionY: 10
    , fontSize: 15
  });

  writeHeaderInfo({
    document
    , text1: headerText
    , positionX1: 10
    , positionY1: 20
    , text2: isPeriodSingleDay
      ? dateFormatter.format(headerInitialPeriod)
      : `${dateFormatter.format(headerInitialPeriod)} - ${dateFormatter.format(headerFinalPeriod)}`
    , positionX2: 28
    , positionY2: 20
    , fontSize: 12
  });

  document.line(0, 25, pageWidth, 25);
};

export const footer = ({ document, footerCreationDateTimeText, data }) => {
  const pageHeight = document.internal.pageSize.height;
  const marginLeft = data.settings.margin.left;
  const dateTimeFormatter = dateTimeFormatterConstructor({ locale: localStorage.getItem("i18nextLng"), dateStyle: "full", timeStyle: "medium" });

  document.setFont("helvetica", "normal");
  document.setFontSize(10);
  document.text(`Bestzap - ${dateTimeFormatter.format(new Date(footerCreationDateTimeText))}`, marginLeft, pageHeight - 10);
};

export const alignHeader = ({ data }) => {
  const tableId = data.table.id;
  const isDataSectionHead = data.section === "head";
  if (!isDataSectionHead) return;

  if (tableId === "ratingsData") {
    alignRatingsReportHeader({ data });
    return;
  }
  
  if (["statisticsRatingsData", "ticketsData", "statisticsTicketsData"].includes(tableId)) {
    alignCenterAndMiddleHeader({ data });
    return;
  }
};

export const alignFooter = ({ data }) => {
  const tableId = data.table.id;
  const isDataSectionFooter = data.section === "foot";
  if (!isDataSectionFooter) return;

  if (tableId === "ticketsData") {
    alignTicketsDataReportFooter({ data });
    return;
  }
};