import { i18n } from "../../translate/i18n";

function millisecondsToTime(ms) {
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / 1000 / 60) % 60);
  const hours = Math.floor((ms  / 1000 / 3600 ) % 24);
  const days = Math.floor((ms  / 1000 / 3600) / 24)
  let timeDescription = "";

  if (days > 0) {
    timeDescription += days + " " + i18n.t("ticketsList.timeAwaitDay");
    if (days > 1) { timeDescription += "s"; }
  } 

  else if (hours > 0) {
    timeDescription += hours + " " + i18n.t("ticketsList.timeAwaitHour");
    if (hours > 1) { timeDescription += "s"; }
  } 
  
  else if (minutes > 0) {
    timeDescription += minutes + " " + i18n.t("ticketsList.timeAwaitMinute");
    if (minutes > 1) { timeDescription += "s"; }
  } 
  
  else if (seconds > 0) {
    timeDescription += seconds + " " + i18n.t("ticketsList.timeAwaitSecond");
    if (seconds > 1) { timeDescription += "s"; }
  }

  // tempo iniciado neste momento
  if (timeDescription === "") { timeDescription = i18n.t("ticketsList.timeAwaitNow"); }
  
  return timeDescription;
}	

export default millisecondsToTime;