import { quantile, mean, standardDeviation, sum  } from "simple-statistics";

import { numberFormatterConstructor } from "../intl/formatters";

export const calculateSimpleDescribe = ({ data, returnResultAsObject = true }) => {
  const decimalNumberFormatter = numberFormatterConstructor({
    locale: localStorage.getItem("i18nextLng")
    , style: "decimal"
    , minimumFractionDigits: 2
    , maximumFractionDigits: 2
  });

  const integerNumberFormatter = numberFormatterConstructor({
    locale: localStorage.getItem("i18nextLng")
    , style: "decimal"
    , minimumFractionDigits: 0
    , maximumFractionDigits: 0
  });

  const meanResult = mean(data);
  const standardDeviationResult = standardDeviation(data);
  const rangeResult = `${decimalNumberFormatter.format(meanResult - standardDeviationResult)} |- ${decimalNumberFormatter.format(meanResult)} -| ${decimalNumberFormatter.format(meanResult + standardDeviationResult)}`;

  const firstQuartileResult = decimalNumberFormatter.format(quantile(data, 0.25));
  const secondQuartileResult = decimalNumberFormatter.format(quantile(data, 0.50));
  const thirdQuartileResult = decimalNumberFormatter.format(quantile(data, 0.75));
  const fourthQuartileResult = decimalNumberFormatter.format(quantile(data, 1.00));

  const sumResult = integerNumberFormatter.format(sum(data));
  
  return returnResultAsObject
    ? {
      mean: decimalNumberFormatter.format(meanResult)
      , standardDeviation: decimalNumberFormatter.format(standardDeviationResult)
      , range: rangeResult
      , firstQuartile: firstQuartileResult
      , secondQuartile: secondQuartileResult
      , thirdQuartile: thirdQuartileResult
      , fourthQuartile: fourthQuartileResult
      , sum: sumResult
    }
    : [[
      decimalNumberFormatter.format(meanResult)
      , decimalNumberFormatter.format(standardDeviationResult)
      , rangeResult
      , firstQuartileResult
      , secondQuartileResult
      , thirdQuartileResult
      , fourthQuartileResult
      , sumResult
    ]];
};