import React, { useContext, useState } from "react";
import { Button, ClickAwayListener, useMediaQuery, Tooltip } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { BrushOutlined, Collections, CreateOutlined, CancelOutlined, ReplayOutlined } from "@material-ui/icons";
import { copyImageToClipboard } from "copy-image-clipboard";
import { ReactPainter } from "react-painter";

import { i18n } from "../../translate/i18n";
import { ModalImageCorsContext } from "../../context/ModalImageCors";
import EditImageModal from "../EditImageModal";

const useStyles = makeStyles((theme) => ({
  imageMedia: {
    cursor: "pointer",
    objectFit: "cover",
    transition: "all 0.30s",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  deletedImageMedia: { opacity: 0.7, filter: "grayscale(80%)", },

  editModal: { display: "none" },

	downloadMedia: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "center",
		gap: "3px",
	},

	floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)' },
  },
}));

const CustomTooltipStyles = {
	tooltip: { padding: "10px", fontSize: "13px", borderRadius: "8px", boxShadow: "0 20px 80px 0", }
};

const CustomTooltip = withStyles(CustomTooltipStyles)(Tooltip);

const MessageImage = ({ message, ticketId, replyMessage }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { isDeleted, openImageModalCors } = useContext(ModalImageCorsContext);

  const [openTooltip, setOpenTooltip] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [editImageModalOpen, setEditImageModalOpen] = useState(false);
  
  const imageName = message.mediaUrl?.split("public/")[1]?.split(".")[0];



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenEditImageModal = () => { setEditImageModalOpen(true); };
	const handleCloseEditImageModal = () => { setEditImageModalOpen(false); };

  const handleTooltipOpen = () => { setOpenTooltip(true); };
	const handleTooltipClose = () => { setOpenTooltip(false); };

  const handleCopyImageToClipboard = () => {
    copyImageToClipboard(message.mediaUrl);
    handleTooltipOpen();
  };

  const handleEditImage = (imageName) => {
		setShowImageEditor(true);
		document.getElementById(imageName).style.display = "block";
	};

  const handleCancelImage = (imageName) => {
		setShowImageEditor(false);
		document.getElementById(imageName).style.display = "none";
	};

  const handleResetImage = () => {
		setShowImageEditor(false);
		setTimeout(function setShowImageEditorTrue() {setShowImageEditor(true)}, 1);
	}

  const handleTriggerSaveEditedImage = (triggerSave) => {
    triggerSave();

    const textArea = document.getElementById("textArea");
    textArea.placeholder = i18n.t("image.success");

    setTimeout(function changeTextAreaPlaceholder() {
      textArea.placeholder = !isSmallScreen
        ? i18n.t("messagesInput.placeholderOpen")
        : i18n.t("messagesInput.placeholderOpenMobile")
    }, 5000);
  };

  const handleCopyEditedImage = (editedImageUrl) => { copyImageToClipboard(editedImageUrl); };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <center>
        <img
          className={message.isDeleted ? `${classes.imageMedia} ${classes.deletedImageMedia}` : classes.imageMedia}
          src={message.mediaUrl}
          alt="Message received"
          onClick={() => openImageModalCors(message.mediaUrl, ticketId, replyMessage, message.isDeleted)}
        />
      </center>

      <br />

      {!replyMessage && (
        <>
          <EditImageModal
            open={editImageModalOpen}
            onClose={handleCloseEditImageModal}
            imageUrl={message.mediaUrl}
            ticketId={ticketId}
          />

          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div align="center" className={classes.downloadMedia}>
              <CustomTooltip
                arrow
                title={i18n.t("image.tooltipCopyButton")}
                PopperProps={{ disablePortal: true }}
                onClose={handleTooltipClose}
                open={openTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <Button
                  className={classes.floatingButton}
                  startIcon={<Collections />}
                  color="inherit"
                  variant="outlined"
                  onClick={handleCopyImageToClipboard}
                >
                  {i18n.t("image.copyButton")}
                </Button>
              </CustomTooltip>

              <Button
                className={classes.floatingButton}
                startIcon={<CreateOutlined />}
                color="inherit"
                variant="outlined"
                disabled={isDeleted}
                onClick={() => handleEditImage(imageName)}
              >
                {i18n.t("image.doodleButton")}
              </Button>

              <Button
                className={classes.floatingButton}
                startIcon={<BrushOutlined />}
                color="inherit"
                variant="outlined"
                disabled={isDeleted}
                onClick={handleOpenEditImageModal}
              >
                {i18n.t("image.editButton")}
              </Button>
            </div>
          </ClickAwayListener>

          <div id={imageName} className={classes.editModal}>
            <br />
            <hr />
            <br />

            {showImageEditor
              ? <ReactPainter
                  width={690}
                  height={690}
                  image={message.mediaUrl}
                  initialColor={localStorage.getItem("editImageColor")}
                  render={({ triggerSave, canvas, setColor, imageDownloadUrl, getCanvasProps }) => (
                    <div>
                      <img
                        id={`editedImageBackup${imageName}`}
                        src={message.mediaUrl}
                        alt="Editing Modal"
                        hidden
                      />

                      <center>
                        <canvas id={`editedImagePanel${imageName}`} {...getCanvasProps()} />
                      </center>

                      <center>
                        <input
                          value={localStorage.getItem("editImageColor")}
                          type="color"
                          onChange={(event) => {
                            setColor(event.target.value);
                            localStorage.setItem("editImageColor", event.target.value);
                          }}
                        />
                      </center>
                      <br />

                      <center className={classes.downloadMedia}>
                        <Button
                          className={classes.floatingButton}
                          startIcon={<CancelOutlined />}
                          color="inherit"
                          variant="outlined"
                          onClick={() => handleCancelImage(imageName)}
                        >
                          {i18n.t("image.cancelButton")}
                        </Button>

                        <Button
                          id={`clearEditedImage${imageName}`}
                          className={classes.floatingButton}
                          startIcon={<ReplayOutlined />}
                          color="inherit"
                          variant="outlined"
                          onClick={handleResetImage}
                        >
                          {i18n.t("image.resetButton")}
                        </Button>

                        <Button
                          id={`copyEditedImage${imageName}`}
                          className={classes.floatingButton}
                          startIcon={<Collections />}
                          color="inherit"
                          variant="outlined"
                          onClick={() => handleTriggerSaveEditedImage(triggerSave)}
                        >
                          {i18n.t("image.copyButton")}
                        </Button>

                        {imageDownloadUrl && handleCopyEditedImage(imageDownloadUrl)}
                      </center>
                    </div>
                  )}
                />
              : null
            }
          </div>
        </>
      )}
    </>
  );
};

export default MessageImage;
