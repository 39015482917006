const messages = {
  es: {
    translations: {   
      languagesMenu: {
        language1: 'Português',
        language2: 'English',
        language3: 'Español',
      },
      modalImage: {
        openNewTab: "Abrir imagen en una pestaña nueva.",
      },
      page404: {
        message: "Parece que te perdiste. ¡Toque una de las opciones de menú en el lado izquierdo para volver a la página!",
      },
      messagesListLabels: {
        ticketEnd: "Fin del chat",
      },
      errorBoundary: {
        message: "Oops, No pudimos cargar los datos.",
        button: "Intentar de nuevo",
      },
      reactions: {
        fromMe: "Enviada por mí",
        fromContact: "Enviada por el Contacto",
      },
      adsComponent: {
        identification: "Mensaje de Anuncio",
      },
      releasesModal: {
        title: "Novedades",
        upToDateSpan: "¡Tu estas actualizado! 🎉",
        buttons: {
          close: "Cerrar",
        },
        patch: "Patch",
        labels: {
          implementations: "🔨 Implementaciones",
          adjustments: "🔃 Mejoras",
          bugFixes: "✅ Correcciones",
        },
        messages: {
          patch5: {
            implementations: {
              i1: "- mensajes reenviados (conexión predeterminada)",
              i2: "- compartir tarjetas de contacto",
              i3: "- nombre de la empresa incluido en el mensaje de saludo por texto",
            },
            adjustments: {
              a1: "- filtro de chats pendientes y en asistencia para administradores",
              a2: "- tratando la traducción automática desde el navegador Google Chrome",
              a3: "- los chats en el menú de asistencia ahora son flexibles en la versión móvil",
            },
            bugFixes: {
              b1: "- adjuntar archivos por usuarios comunes",
              b2: "- interacciones de grupo por usuarios comunes",
              b3: "- agregar nuevos contactos",
            },
          },
          patch4: {
            implementations: {
              i1: "- opción para importar mensajes de WhatsApp",
              i2: "- identificación del final de cada chat",
              i3: "- usuarios prioritizados para contactos",
              i4: "- mensaje de bienvenida que se envía incluso si el chip no tiene ningún departamento",
            },
            adjustments: {
              a1: "- nuevo estilo para los filtros de departamento, sector y usuario en los chats activos",
            },
            bugFixes: {
              b1: "- transferencia de contactos a usuarios sin acceso a departamentos",
            },
          },
          patch3: {
            implementations: {
              i1: "- visualización gráfica de pantalla de proyección",
              i2: "- opciones para copiar mensajes de texto",
              i3: "- identificación de llamadas de voz y video recibidas",
              i4: "- edición de departamento en chats pendientes",
              i5: "- filtro de departamento en chats abiertos y pendientes",
            },
            adjustments: {
              a1: "- paso a paso de cómo conectar un chip de WhatsApp en la aplicación",
              a2: "- etiqueta para identificar si un chat ha sido devuelto a pendiente",
              a3: "- paneles de control con un mejor rendimiento",
            },
            bugFixes: {
              b1: "- las transmisiones ya no crean chats pendientes",
            },
          },
          patch2: {
            implementations: {
              i1: "- ahora puedes crear chats para notas, destacar mensajes y expresar tus sentimientos reaccionando a los mensajes ❤️😮",
              i2: "- configuración para enviar listas, botones o mensajes de texto a contactos",
              i3: "- selección de zona horaria",
              i4: "- nuevas animaciones en los botones de acción",
              i5: "- se ha agregado el campo de código de cliente en los contactos",
              i6: "- tiempo máximo de espera para chats pendientes",
              i7: "- nuevos filtros en el panel de chats",
              i8: "- número ilimitado de departamentos",
            },
            adjustments: {
              a1: "- el mensaje de ausencia es opcional para los chips",
              a2: "- carga más rápida de los paneles de control",
              a3: "- elige si el chat se asignará a un usuario o quedará pendiente al abrir chats",
            },
            bugFixes: {
              b1: "- el tiempo de espera se reinicia al abrir chats de nuevo",
              b2: "- la función de arrastrar y soltar ya no hace que la aplicación se bloquee",
              b3: "- etiquetas con información en tiempo real al abrir chats para usuarios",
            },
          },
          patch1: {
            implementations: {
              i1: "- panel de conversaciones por usuario",
              i2: "- panel de conversaciones interactivo",
              i3: "- seguimiento del tiempo de respuesta",
              i4: "- opción para cerrar tickets pendientes sin necesidad de aceptarlos (solo para administradores)",
              i5: "- menú disponible para móviles",
              i6: "- visualización de la foto de perfil de los contactos",
              i7: "- ahora puedes enviar archivos a tus contactos simplemente arrastrándolos y soltándolos",
            },
            adjustments: {
              a1: "- ya no aparece 'NaN' en el panel general cuando se usa la aplicación por primera vez",
              a2: "- los navegadores vuelven a notificar nuevos mensajes",
              a3: "- la selección y envío de colas y botones también funciona para WhatsApp Business",
              a4: "- al ver una conversación que ya ha sido cerrada, la página permanece en la lista de cerradas",
              a5: "- se han separado los mensajes de grupos y conversaciones privadas",
            },
            bugFixes: {
              b1: "- inclusión de imágenes en la aplicación",
              b2: "- cambio de colores del título de las páginas",
              b3: "- cambio de colores de los botones secundarios",
              b4: "- todas las expansiones del menú comienzan cerradas",
              b5: "- se han agregado opciones de adjuntar archivos y grabar audio para administradores cuando los tickets están pendientes",
              b6: "- los departamentos, categorías y sectores se han agrupado en el menú",
              b7: "- se ha agregado una lista de usuarios a la opción de transferencia de conversación",
              b8: "- nuevo estilo de la barra de desplazamiento",
            },
          },
        },
      },
      dragDropModal: {
        span: "¡Arrastra y suelta tu archivo aquí!",
        obs: "(solo un archivo por intento)",
        await: "¡Espera mientras enviamos tu archivo!",
        confirmationModal: {
          title: "¿Quieres enviar el archivo?",
          messages: {
            format: "Formato:",
            size: "Tamaño:",
            name: "Nombre:",
          },
        },
      },
      openAi: {
        expander: "BestZap Bot",
        inputPlaceholder: "Digite aqui sua pergunta",
      },
      locationPreview: {
        viewButton: "Ver",
      },
      listPreview: {
        select: "Seleccionar",
      },
      listPreviewModal: {
        title: "Seleccionar",
        toasts: {
          notSelectedOption: "¡Selecciona una opción antes de enviar el mensaje!",
        },
      },

      eventPreview: {
        videoCall: "Llamada de video en WhatsApp",
        voiceCall: "Llamada de voz en WhatsApp",
        seeDetails: "Ver Detalles",
      },

      pollPreview: {
        multipleAnswers: "Seleccione una o más opciones",
        singleAnswer: "Seleccione una opción",
        viewVotes: "Ver Votos",
      },
      groupInvitePreview: {
        description: "Invitación de Grupo",
        joinButton: "Únete",
        expiredButton: "Caducado",
        confirmationModal: {
          title: "¿Quieres unirte al grupo:",
        },
        toasts: {
          invalidInviteCode: "Código de invitación no válido. ¡Solicite otra invitación al administrador del grupo!",
          inviteExpired: "Código de invitación no caducado. ¡Solicite otra invitación al administrador del grupo!",
        },
      },
      vCard: {
        confirmationModal: {
          title: "Tu gustaria hablar con",
        },        
        button: 'Hablar',
        info: "¡No se puede iniciar un chat con este contacto porque su número de teléfono no se comparte!",
        toasts: {
          noNumberProvided: 
            "No es posible iniciar una conversación con un contacto compartido sin un número de WhatsApp válido. ¡Pídele al contacto que envió el mensaje que lo envíe de nuevo!",
        },
      },  
      multiVCard: {
        buttons: {
          showCards: "Ver todos",
          talk: "Hablar",
        },
        texts: {
          and: "y más",
          otherContacts: "contactos",
        },
      },  
      image: {
        copyButton: "Copiar",
        doodleButton: "Garabatear",
        editButton: "Editar",
        resetButton: "Reiniciar",
        undoButton: "Deshacer",
        cancelButton: "Cancelar",
        sendButton: "Mandar",
        tooltipCopyButton: "Copiado!",
        editingPanel: "Edición de Imagen",
        success: "¡Imagen copiada! Ahora puedes pegarlo aquí si quieres.",
      },
      badges: {
        closedContent: "Cerrado",
      },
      signup: {
        title: "Registro",
        toasts: {
          fail: "Error creando el usuario. Verifica la data reportada.",
        },
        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Regístrate",
          login: "¿Ya tienes una cuenta? ¡Inicia sesión!",
        },
      },
      login: {
        title: "BestZap",
        subtitle: "Inicio de Sesión",
        subtitleGroup: "Escriba su correo electrónico",
        subtitlePassword: "Escribe tu contraseña",
        form: {
          email: "Correo Electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Ingresa",
          back: "Voltar",
          register: "¿No tienes cuenta? ¡Regístrate!",
        },
      },
      whatsappButtonComponent: {
        tooltip: "¡Háblanos!",
      },
      dashboard: {
        emptyBox: "No se han encontrado datos en este período",

        settings: {
          allowGroupsDisabled: "Los grupos no están habilitados",
        },

        charts: {
          ticketsPerQueues: {
            title: "Conversaciones por Departamentos",
          },
          ticketsPerSubqueues: {
            title: "Conversaciones por Sectors",
          },
          ticketsPerConnection: {
            title: "Conversaciones por Conexiones",
          },
          openTicketsPerUsers: {
            title: "Chats Abiertos por Usuarios",
          },
          closedTicketsPerUsers: {
            title: "Chats Cerrados por Usuarios",
          },
          timePerUsers: {
            title: "Tiempo Promedio por Usuarios (minutos)",
          },
          totalTimePerUsers: {
            title: "Tiempo Total por Usuarios (minutos)",
          },
        },
        fields: {
          initialDate: "Fecha Inicial",
          finalDate: "Fecha Final",
          resaleSerialNumber: "Número del Distribuidor",
          serialNumber: "Número de Serie",
          clientCode: "Código del Cliente",
          messageKeyword: "Palabra Clave",
        },
        buttons: {
          update: "Actualizar",
          filterMessageKeywords: "Filtrar por palabra clave",
        },
        messages: {
          inAttendance: {
            title: "En servicio"
          },
          waiting: {
            title: "Esperando"
          },
          closed: {
            title: "Finalizado"
          },
          newContacts: {
            title: "Nuevos Contactos"
          },
          messages: {
            title: "Mensajes"
          },
          attendant: {
            title: "Auxiliares"
          },
          awaitingTime: {
            title: "Tiempo de espera (Promedio)"
          },
          attendanceTime: {
            title: "Tiempo de atención (Promedio)"
          },
          time: {
            minute: "Minuto",
            minutes: "Minutos",
            hour: "Hora",
            hours: "Horas",
            never: "Nunca",
          }
        }
      },
      generalDashboard: {
        title: "Dashboard General",
      },
      attendantsDashboard: {
        title: "Dashboard de Asistents",
      },

      report: {
        validations: {
          tooManyRequests: "¡Un informe ya está siendo generado. Por favor, espere a que se complete!",
          emptyReport: "¡No hay datos disponibles para el informe!",
        },

        statistics: {
          variables: {
            mean: "Media",
            standardDeviation: "Desviación Estándar",
            range: "Rango",
            firstQuartile: "25%",
            secondQuartile: "50%",
            thirdQuartile: "75%",
            fourthQuartile: "100%",
          },

          caption: {
            title: "Subtítulo:",
            mean: "el promedio de los datos; un valor central que representa el conjunto;",
            standardDeviation: "qué tan dispersos están los datos respecto a la media; una medida de variación;",
            range: "el rango de valores alrededor de la media, considerando la variabilidad;",
            firstQuartile: "el valor por debajo del cual se encuentra el 25% de los datos;",
            secondQuartile: "el valor por debajo del cual se encuentra el 50% de los datos; también llamado Mediana;",
            thirdQuartile: "el valor por debajo del cual se encuentra el 75% de los datos;",
            fourthQuartile: "el valor más alto; el 100% de los datos están por debajo o en este punto."
          },
        },

        footer: {
          total: "Total:",
        },
      },

      ratingsDashboard: {
        title: "Dashboard de Evaluaciones",

        cards: {
          rate: "Nota (Promedio)",
          tickets: "Chats",
          contacts: "Contactos",
        },

        captions: {
          top5Users: "😀 Notas Más Altas",
          bottom5Users: "😥 Notas Más Bajas",
          allUsers: "✨ Todos los Usuarios",
        },

        table: {
          id: "#",
          user: "Usuario",
          rate: "Nota",
          ratingsCount: "Evaluaciones",
        },

        tooltips: {
          tableView: "Vista de Tabla",
          chartView: "Vista de Gráfico",
          generateReport: "Generar Informe",
        },
      },

      ratingsReport: {
        document: {
          table: {
            info: {
              title: "EVALUCIONES DE USUARIOS",
              period: "Período:",
              ratings: "Evaluciones",
              statistics: "Estadísticas",
            },
      
            header: {
              user: "Usuario",
              rate: "Evalución Promedio",
              numberOfRatings: "Cantidad de Evaluciones",
            },
          },
        },
      },

      groupsDashboard: {
        title: "Dashboard de Grupos",

        cards: {
          chats: "Conversaciones",
          newGroups: "Nuevos Grupos",
        },
      },

      internalChatTicketsDashboard: {
        title: "Dashboard de Chats Internos",
        amount: "Cantidad de Chats",
        faster: "Chat más Rápido",
        longer: "Chat más Largo",
        minute: "minuto",
        minutes: "minutos",
        hour: "hora",
        hours: "horas",

        table: {
          header: {
            id: "Conversación",
            user: "Usuario",
            chatName: "Nombre de la Conversación",
            protocol: "Protocolo",
            time: "Tiempo",
            endedAt: "Cerrado En",
          },
          rows: {
            minute: "minuto",
            minutes: "minutos",
            hour: "hora",
            hours: "horas",
          },
        },
      },

      internalGroupTicketsDashboard: {
        title: "Dashboard de Grupos Internos",
        amount: "Cantidad de Chats",

        table: {
          header: {
            id: "Grupo",
            groupName: "Nombre del Grupo",
            participants: "Participantes",
            protocol: "Protocolo",
            createdAt: "Creado En",
            endedAt: "Cerrado En",
          },
        },
      },

      ticketsDashboard: {
        title: "Dashboard de Chats",
        amount: "Cantidad de Chats",
        faster: "Chat más Rápido",
        longer: "Chat más Largo",
        minute: "minuto",
        minutes: "minutos",
        hour: "hora",
        hours: "horas",

        table: {
          header: {
            id: "Conversación",
            connection: "Conexion",
            platform: "Plataforma",
            rate: "Nota",
            user: "Usuario",
            contactName: "Nombre de Contacto",
            contactNumber: "Número de Contacto",
            resaleSerialNumber: "Número del Distribuidor",
            serialNumber: "Número de Serie",
            clientCode: "Código del Cliente",
            queue: "Linha",
            category: "Categoría",
            subqueue: "Sublinha",
            protocol: "Protocolo",
            time: "Tiempo",
            endedAt: "Cerrado En",
          },
          rows: {
            minute: "minuto",
            minutes: "minutos",
            hour: "hora",
            hours: "horas",
          },
        },

        tooltips: {
          generateReport: "Generar Informe",
        },

        charts: {
          title: "Distribución de Chats: Usuario",
        },
      },

      ticketsReport: {
        document: {
          table: {
            info: {
              title: "Conversaciones",
              period: "Período:",
              chats: "Conversaciones",
              statistics: "Estadísticas",
            },

            header: {
              createdAt: "Iniciado",
              endedAt: "Cerrado",
              user: "Usuario",
              contactName: "Contato",
              contactNumber: "Número",
              queue: "Linha",
              protocol: "Protocolo",
              time: "Tiempo (min)",
            },
          },
        },
      },

      connections: {
        title: "Conexiones",
        usedChipsCount: {
          outOf: "de",
          chips: "chip(s)",
        },
        card: {
          defaultConnection: "Conexión Predeterminada",
          notDefaultConnection: "Conexão No-Predeterminada",
        },
        confirmationModal: {
          deleteTitle: "Borrar",
          deleteMessage: "¿Estás seguro? Este proceso no puede ser revertido.",
          disconnectTitle: "Desconectar",
          disconnectMessage: "Estás seguro? Deberá volver a leer el código QR",
        },
        buttons: {
          add: "Agrega WhatsApp",
          disconnect: "Desconectar",
          delete: "borrar",
          info: "Info",
          tryAgain: "Inténtalo de nuevo",
          qrcode: "QR CODE",
          newQr: "Nuevo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          copyApiToken: "Copiar Token de API",
          copied: "¡Copiado!",
          disconnected: {
            title: "No se pudo iniciar la sesión de WhatsApp",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y vuelva a intentarlo o solicite un nuevo código QR",
          },
          qrcode: {
            title: "Esperando la lectura del código QR",
            content:
              "Haga clic en el botón 'CÓDIGO QR' y lea el Código QR con su teléfono celular para iniciar la sesión",
          },
          connected: {
            title: "Conexión establecida",
          },
          timeout: {
            title: "Se perdió la conexión con el teléfono celular",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y que WhatsApp esté abierto, o haga clic en el botón 'Desconectar' para obtener un nuevo código QR",
          },

          importingMessages: {
            title: "Se están importando mensajes",
          },

          importedMessages: {
            title: "Todos los mensajes han sido importados.",
          },
        },
        table: {
          name: "Nombre",
          status: "Estado",
          lastUpdate: "Última Actualización",
          default: "Por Defecto",
          actions: "Acciones",
          session: "Sesión",
        },
      },
      whatsappInfoModal: {
        title: "Conexión info",
        datas: {
          platform: "Plataforma:",
          whatsAppUserName: "Nombre de usuario del WhatsApp:",
          phoneNumber: "Número de teléfono:",
        },
      },
      whatsappModal: {
        title: {
          add: "Agrega WhatsApp",
          edit: "Edita WhatsApp",
        },
        form: {
          settings: "Configuraciones",
          name: "Nombre",
          default: "Por Defecto",
          dispatch: "Disparo",
          sendRating: "Encuesta de satisfaccion",
          shouldUseChipNameOnAutomaticMessages: "Usar nombre de la conexión en el título de los mensajes automáticos",
          closeNewTicketsWithMessageOutsideApplication: "Cerrar conversaciones iniciadas por mensajes fuera de la aplicación",
          waitForBotInteraction: "Espere por la interacción con el robot",
          sendSignature: "Enviar firma en mensajes",
          greetingMessage: "Mensaje de Saludo",
          awaitingMessage: "Mensaje de Espera",
          farewellMessage: "Mensaje de Despedida",
          absenceMessage: "Mensaje de Ausencia",
          declinedCallMessage: "Mensaje de Llamada Rechazada",
          alertContactPhoneNumber: "Número de Celular para Avisos",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        info: {
          message: "Para agregar datos dinámicos, escríbalos entre paréntesis: (nombrecontacto)",
          variablesText: "Variables disponibles:",
          variablesList: "(nombrecontacto), (saludo)",

          alertContactPhoneNumberMessage: "El número de celular solo recibe notificaciones sobre conversaciones pendientes fuera del horario de atención",
        },
        tooltips: {
          waitForBotInteraction: "Las conversaciones pendientes solo se muestran a los usuarios después de que el contacto interactúa con el bot. Los administradores pueden ver todas las conversaciones pendientes.",
          invalidAlertContactPhoneNumber: "¡Número de celular para alertas inválido!",
          alertContactPhoneNumberNotOnWhatsapp: "¡Número de celular para alertas no está registrado en WhatsApp!",
        },
      },
      qrCode: {
        message: "Lé el QR Code para empezar la sesión.",
        steps: {
          message1: "- Abre WhatsApp en tu teléfono",
          message2: "- Toca 'Menú' o 'Configuración' y selecciona 'Dispositivos vinculados'",
          message3: "- Toca en 'Vincular dispositivo'",
          message4: "- Apunta tu teléfono a esta pantalla para capturar el código QR",
          message5: "- Considera cambiar al Tema Claro para una mejor captura del código QR",
        },
        loadingScreenStatus: {
          loadingChats: "Cargando tus conversaciones",
        },
      },
      contacts: {
        title: "Contactos",
        tooltips: {
          listLayout: "Diseño de lista",
          cardLayout: "Diseño de card",
          contactsLayout: "Contactos",
          groupsLayout: "Grupos",
          channelsLayout: "Canales",
          blockedLayout: "Bloqueados",
          inactivatedLayout: "Contactos deshabilitados",
        },
        messages: {
          outOf: "de",
          contacts: "contactos",
        },
        toasts: {
          groupsNotEnabled: "¡Los grupos no están activados! Puede habilitarlos en la página Configuraciónes.",
          channelsNotEnabled: "¡Los canales no están activados! Puede habilitarlos en la página Configuraciónes.",
        },
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          blockTitle: "Bloquear contacto",
          unblockTitle: "Desbloquear contacto",
          blockMessage: "¿Estás seguro de que deseas bloquear el contacto?",
          unblockMessage: "¿Estás seguro de que deseas desbloquear el contacto?",

          activeTitle: "Activar",
          inactiveTitle: "Deactivar",
          activeMessage: "¿Estás seguro de que deseas activar este contacto?",
          inactiveMessage: "¿Estás seguro de que deseas desactivar este contacto? Ya no podrás chatear con este contacto.",

          importContacts: "Importar Contactos",
          importGroups: "Importar Grupos",
          importChannels: "Importar Canales",

          deleteTitle: "Borrar",
          importTitlte: "Importar contactos",
          importMessagesTitle: "Importar Mensajes",

          deleteMessage: "¿Estás seguro que deseas borrar este contacto? Todas las conversaciones relacionadas se perderán.",
          deleteGroupMessage: "¿Estás segura que deseas borrar este grupo? Su conversación se perderá.",
          deleteChannelMessage: "¿Estás seguro que deseas borrar este canale? Su conversación se perderá.",
          importMessage: "¿Quieres importar todos los contactos desde tu teléfono?",
          importMessagesMessage: "¿Estás seguro de que deseas importar todos los mensajes del teléfono? Importará los últimos 1000 mensajes de los últimos tres meses para cada chat en tu teléfono.",

          form: { chip: "Chip" },

          openPrivateChat: { title: "¿Te gustaría iniciar una conversación con" },
          reopenPrivateChat: { title: "¿Te gustaría reiniciar la conversación?" },

          toast: {
            importingMessages: "Los mensajes se están importando, ¡puedes seguir trabajando mientras hacemos el trabajo por ti!",
            noConnectedChip: "¡No hay chips conectados!",
          },
        },
        accordion: {
          title: "Labels",
          title2: "Conexión Estandár",
          title3: "Medios",
          noLabels: "No hay labels asignadas al contacto",
          showAllMedias: "Mostrar todo",
        },
        buttons: {
          importMessages: "Importar Mensajes",
          import: "Importar Contactos",
          add: "Agregar Contacto",
          exportCSV: "Exportar contactos por archivo CSV",
          importCSV: "Importar contactos por archivo CSV",
        },
        table: {
          name: "Nombre",
          label: "Label",
          whatsapp: "WhatsApp",
          email: "Correo Electrónico",
          actions: "Acciones",
        },
      },

      viewTicketModal: {
        title: "Chat",

        buttons: {
          return: "Volver",
          exportToPDF: "Exportar a PDF",
          openPrivateChat: "Charlar",
        },
      },

      viewParticipantsModal: {
        title: "Participantes",
      },

      exportCSVModal: {
        title: "Exportar contactos a un archivo CSV",
        form: {
          contactType: {
            label: "Tipo de Contacto",
            nonBlockedOption: "No Bloqueado",
            blockedOption: "Bloqueado",
            inactivatedOption: "Inactivados",
            bothOption: "Todos",
          },
          separateContacts: {
            label: "Separar los contactos desbloqueados de los bloqueados y los inactivados",
          },
        },        
        buttons: { cancel: "Cancelar", export: "Exportar", },
        toasts: {
          error: "No se pudo exportar los contactos. ¡Inténtalo de nuevo más tarde!",
          noContactsToExport: "¡No hay contactos para exportar!"
        }
      },
      importCSVModal: {
        title: "Importar contactos por archivo CSV",
        importSpan: "Haga clic aquí para cargar su archivo",
        fileName: "Archivo para ser importado:",
        info1: "El archivo CSV debe tener cuatro columnas llamadas ContactName, ContactNumber, ContactEmail y ContactConnectionName.",
        info2: "¡También debe contener un encabezado y todos los valores deben ser strings!",
        warning: "¡Algunas líneas en el archivo no tienen las variables obligatorias completadas (Nombre y número de contacto), por lo que se ignoraron!",
        buttons: {
          cancel: "Cancelar",
          import: "Importar",
        },
        toasts: {
          notSupportedFormat: "¡Solo se admiten archivos CSV!",
          notUploadedFile: "¡Ningún archivo CSV seleccionado!",
          moreVariablesThanLimit: "¡El archivo tiene más variables de las esperadas! Prueba con otro archivo.",
          lessVariablesThanLimit: "¡El archivo tiene menos variables de las esperadas! Prueba con otro archivo.",
          headerNotExpected: "El archivo no contiene las columnas requeridas.",
          noDefaultWhatsappConnected: "No tienes una conexión por defecto o no está conectada. ¡Mira la página de Conexiones!",
          contactsImported: "¡Todos los contactos han sido importados! ¡Recarga la página para ver tus nuevos contactos!",
        },
        importationWords: {
          imported: "Importado",
          of: "de",
          contacts: "contactos",
        },
        tooltips: {
          stopImportation: "Detener importación",
        },
      },
      contactModal: {
        title: {
          add: "Agregar contacto",
          edit: "Editar contacto",
        },
        toasts: {
          invalidCPF: "¡CPF no Válido!",
          nonexistentCPF: "¡CPF Inexistente!",
          invalidCNPJ: "¡CNPJ no Válido!",
          nonexistentCNPJ: "¡CNPJ Inexistente!",
          invalidBirthday: "¡La Fecha de Cumpleaños no puede ser posterior a hoy!",
        },
        form: {
          mainInfo: "Detalles del contacto",
          extraInfo: "Información adicional",
          whatsapp: "Conexión estándar",
          name: "Nombre",
          label: "Label",
          number: "Número de Whatsapp",
          cpfCnpj: "CPF/CNPJ",
          birthday: "Cumpleaños",
          surname: "Apodo",
          serialNumber: "Número de Serie",
          resaleSerialNumber: "Número del Distribuidor",
          clientCode: "Código del Cliente",
          email: "Correo Electrónico",
          extraName: "Nombre del Campo",
          extraValue: "Valor",
          isBot: "No enviar mensajes automaticos",
          sendButtonsLists: "No enviar botones y listas",
          fieldLabel: "Priorizar usuario",
        },
        buttons: {
          addExtraInfo: "Agregar información",
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
          importMessages: "Importar mensajes",
        },
        importingMessagesBaileys: "Los mensajes están siendo importados. ¡Puedes volver a trabajar mientras hacemos el trabajo por ti!",
        confirmationModal: {
          titleBaileys: "¿Te gustaría importar los mensajes?",
          messageBaileys: "Esta acción solo puede realizarse una vez por contacto. ¡Es posible que algunas imágenes, videos, audios y archivos no se puedan importar!",
        },
      },

      internalGroupEditionModal: {
        title: "Editar Grupo Interno",

        form: {
          name: "Nombre",
        },

        buttons: {
          save: "Guardar",
          cancel: "Cancelar",
        },
      },

      quickAnswersModal: {
        title: {
          add: "Agregar respuesta rápida",
          edit: "Editar respuesta rápida",
        },
        form: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
      },
      categoryModal: {
        title: {
          add: "Agregar categoría",
          edit: "Editar categoría",
        },
        form: {
          name: "Nombre",
        },
        buttons: {
          okAdd: "Añadir",
          okEdit: "Ahorrar",
          cancel: "Cancelar",
        },
      },
      subqueueModal: {
        title: {
          add: "Agregar sector",
          edit: "Editar sector",
        },
        form: {
          name: "Nombre",
          description: "Descripción",
          category: "Categoría",
          queue: "Departamento",
        },
        buttons: {
          okAdd: "Añadir",
          okEdit: "Ahorrar",
          cancel: "Cancelar",
        },
      },
      queueModal: {
        title: {
          add: "Agregar departamento",
          edit: "Editar departamento",
        },
        form: {
          isDefault: "Predeterminada",
          name: "Nombre",
          color: "Color",
          greetingMessage: "Mensaje de saludo",
        },
        buttons: {
          okAdd: "Añadir",
          okEdit: "Ahorrar",
          cancel: "Cancelar",
        },
      },
      viewOnceFileContextModal: {
        title: {
          up: "Enviar Archivo con Visualización Única",
        },
      },
      fileContextModal: {
        title: {
          up: "Enviar Archivo",
        },
        tooltips: {
          cancel: "Cancelar",
          confirm: "Confirmar",
        },
        textfield: "Subtitulo",
        noVisualizationMessage: "¡Vista previa no disponible!",
        addFile: "Añadir archivo",
      },
      editMessageModal: {
        title: {
          up: "Editar Mensaje",
        },
        textfield: "Nuevo mensaje",
        toasts: {
          blankInput: "¡No es posible editar un mensaje para dejarlo en blanco!",
        },
      },
      editMessageHistoryModal: {
        title: {
          up: "Historial de Mensajes Editados",
        },
      },

      messagePropertiesModal: {
        title: { up: "Propiedades del Mensaje", },

        spans: {
          internalMessage: "Mensaje Interno",
          date: "Fecha",
          hour: "Hora",
          sentBy: {
            sentBy: "Enviado por",
            me: "Yo",
            contact: "Contacto",
          },
          user: "Usuario",
          connection: "Conexión",
          connectionNumber: "Número de la Conexión",
        },

        buttons: { cancel: "Cancelar", },

        tooltips: { copy: "Copiar", copied: "Copiado", },
      },

      forwardMessageContactModal: {
        title: {
          up: "Reenviar Mensaje para Contacto",
        },
        tooltips: {
          send: "Reenviar",
        },
        contactType: {
          group: "Grupo",
        },
        confirmationModal: {
          title: "¿Te gustaría reenviar mensajes para contacto?",
          name: "Nombre:",
          number: "Numero:",
        },
      },

      eventDetailsModal: {
        title: {
          up: "Detalles del Evento",
        },

        buttons: {
          startDateTime: "Agendar en el calendario",
          location: "Ver en el mapa",
          call: "Copiar enlace",
        },

        tooltips: {
          copied: "Copiado",
        },
      },

      pollVotesModal: {
        title: {
          up: "Votos de la Encuesta",
        },
        votes: "Votos",
      },
      multiVCardModal: {
        title: {
          up: "Ver contactos",
        },
        confirmationModal: {
          title: "Tu gustaria hablar con",
        },
        toasts: {
          noNumberProvided: 
            "No es posible iniciar una conversación con un contacto compartido sin un número de WhatsApp válido. ¡Pídele al contacto que envió el mensaje que lo envíe de nuevo!",
        },
      },
      imageToStickerModal: {
        title: {
          up: "Crear Pegatina",
        },
        buttons: {
          cancel: "Cancelar",
          send: "Enviar",
        },
      },
      editImageModal: {
        title: {
          up: "Editar Imagen",
        },

        toasts: {
          copiedEditedImage: "¡La imagen editada fue copiada!",
          errorSendingEditedImage: "No fue posible enviar la imagen editada, ¡intenta nuevamente más tarde!",
        },

        buttons: {
          cancel: "Cancelar",
          copy: "Copiar",
          send: "Enviar",
          confirm: "Confirmar",
        },
      },
      mediasGalleryModal: {
        title: {
          up: "Galería de medios",
        },
        tabBar: {
          images: "Imágenes",
          videos: "Vídeos",
          audios: "Audios",
          others: "Otros",
        },
      },
      stickersModal: {
        title: {
          up: "Pegatinas",
        },
        tabBar: {
          dracula: "Drácula",
          penguin: "Pingüino",
          pumpkin: "Calabaza",
          bunny: "Conejo",
          bot: "Robot",
          elf: "Elfo",
        },
        toasts: {
          sendSticker: "¡No se puede enviar la figurinha, inténtalo de nuevo más tarde!",
        },
      },

      groupParticipantsModal: {
        title: {
          up: "Participantes",
        },
      },

      internalGroupParticipantsModal: {
        title: {
          up: "Participantes",
        },

        tooltips: {
          addButton: "Añadir Participante",
        },

        searchPlaceholder: "Buscar...",

        userProfile: {
          superadmin: "Superadministrador",
          admin: "Administradores",
          member: "Miembros",
        },
      },

      addParticipantsInternalGroupModal: {
        title: {
          up: "Añadir Participantes",
        },

        searchPlaceholder: "Buscar...",

        buttons: {
          cancel: "Cancelar",
          add: "Agregar",
        },

        toasts: {
          success: "¡Nuevos participantes añadidos con éxito!",
        },
      },

      newContactsModal: {
        title: {
          up: "Nuevo contactos",
        },
        contactType: {
          group: "Grupo",
        },
      },
      shareContactModal: {
        title: {
          up: "Compartir contacto",
        },
        buttons: {
          send: "Compartir",
        },
        confirmationModal: {
          title: "¿Te gustaría compartir el contacto?",
        },
        toast: {
          limit: "¡Solo se pueden enviar 5 contactos a la vez!",
        }
      },
      cameraModal: {
        title: "Tomar foto",
        buttons: {
          cancel: "Cancelar",
        },
        messages: {
          noVideoInputs: "Su dispositivo no contiene ninguna entrada de video.",
        },
        toasts: {
          exceptionListingDevices: "No fue posible listar las cámaras disponibles. Verifique si se ha concedido el permiso de la cámara. Si es así, intente nuevamente más tarde!"
        },
        tooltips: {
          turnOnCamera: "Encender camera",
          turnOffCamera: "Apagar camera",
          snapCamera: "Tomar foto",
          copyPhoto: "Copiar foto",
          sendPhoto: "Enviar foto",
        },
      },
      pollModal: {
        title: "Encuesta",
        fields: {
          allowMultipleAnswers: "¿Permitir múltiples respuestas?",
          titleLabel: "Título",
          optionA: "Opción A",
          optionB: "Opción B",
          newOptionLabel: "Nueva opción",
        },
        schemaValidations: {
          tooShort: "¡Muy corto!!",
          tooLong: "¡Muy largo!",
          required: "Obligatorio",
        },
        buttons: {
          send: "Enviar",
          cancel: "Cancelar",
          newExtraOption: "Nueva opción",
        },
      },
      locationModal: {
        title: "Enviar ubicación",
        buttons: {
          currentLocation: "Actual",
          companyLocation: "Empresa",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Agregar usuario",
          edit: "Editar usuario",
        },

        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
          whatsappNumber: "Número de WhatsApp",
          profile: "Perfil",
          whatsapp: "Conexión estándar",
          configEnabled: "Permitir el acceso a Configuración",
          forceAcceptLongAwaitingTimeTickets: "Forzar la aceptación de chats con mucho tiempo de espera",
        },

        tooltips: {
          invalidWhatsappNumber: "¡Número de celular inválido!",
          whatsappNumberNotOnWhatsapp: "¡Número de celular no está registrado en WhatsApp!",
        },

        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },

        defaultConnectionMustBeSelected: "La conexión predeterminada también debe seleccionarse en el campo 'Conexiones'",
        emailAlreadyRegistered: "¡Email ya registrado!",
      },
      chat: {
        noTicketMessage: "Selecciona una conversación para empezar a chatear.",
      },
      ticketsManager: {
        fields: {
          name: "Nombre",
        },

        buttons: {
          newTicket: "Nueva Conversación",
          newNote: "Nueva Anotación",
          newInternalChat: "Nueva Conversación Interna",
          newInternalGroup: "Nuevo Grupo Interno",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Linhas",
      },
      ticketInfo: {
        composing: "Escribiendo...",
        recording: "Grabando audio...",
        lastSeenToday: "Última vez visto hoy a las",
        lastSeenAt: "Última vez visto a las",
      },
      tickets: {
        notification: {
          message: "Mensaje de",
          internalChat: "Conversación Interna",
          expirationWarning: "Cierre automático en @ minuto(s)",
          hasMessageFromPhone: "Hablando en la aplicación WhatsApp",
        },
        tabs: {
          open: { title: "Bandeja" },
          closed: { title: "Resueltos" },
        },
        buttons: {
          showAll: "Mostrar todas las conversaciónes",
          showAllOff: "Ocultar todas las conversaciones",
        },
      },
      editTicketModal: {
        title: "Editar Conversacion",

        fieldLabelLabel: "Label",
        fieldLabelPlaceholder: "Label",
        labelInfo: "No tienes ninguna label. ¡Por favor registre algunas labels antes de cerrar este chat!",

        fieldQueueLabel: "Departamento",
        fieldQueuePlaceholder: "Seleccione una departamento",
        fieldSubqueueLabel: "Sector",
        fieldSubqueuePlaceholder: "Seleccione una sector",

        buttons: {
          ok: "Editar",
          cancel: "Cancelar",
        },
        toast: {
          subqueuesNotLoaded: "Comprobación de la linha elegida. ¡Inténtalo de nuevo en unos segundos!",
        },
      },

      searchMessageModal: {
        title: "Buscar Mensaje",
        searchPlaceholder: "Palabras-Clave",

        toasts: {
          smallLengthSearchParam: "Texto corto para pesquisa, adicione mais informaçõs para melhorar a pesquisa!",
        },

        confirmationModal: {
          title: "¿Te gustaría ir al historial del mensaje seleccionado?",
        },
      },

      transferTicketModal: {
        title: "Transferir Conversacion",
        fieldLabel: "Seleccione un usuario",
        fieldQueueLabel: "Transferir a la departamento",
        fieldConnectionLabel: "Transferir to conexión",
        fieldQueuePlaceholder: "Seleccione una departamento",
        fieldConnectionPlaceholder: "Seleccione una conexión",
        fieldText: "Observación de transferencia",
        noOptions: "No se encontraron usuarios con ese nombre",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
        toast: {
          userNotSelected: "¡Necesitas seleccionar un usuario antes de transferir un chat!",
        },
        listSubheaders: {
          onlineUsers: "Usuarios en Línea",
          offlineUsers: "Usuarios fuera de Línea",
        },
      },
      ticketsList: {
        timeAwaitDay: "dia",
        timeAwaitHour: "hora",
        timeAwaitMinute: "minuto",
        timeAwaitSecond: "segundo",
        groupHeader: "Grupos",
        channelHeader: "Canales",
        timeAwaitNow: "Ahora",
        pendingHeader: "Departamento",
        assignedHeader: "Trabajando en",
        noTicketsTitle: "¡Nada acá!",
        connectionTitle: "Conexión que se está utilizando actualmente.",
        noTicketsMessage:
          "No se encontraron conversaciones con este estado o término de búsqueda",
        isReturned: "Chat Devuelto",
        isTransfered: "Chat transferido",
        buttons: {
          accept: "Acceptar",
        },
      },
      newTicketModal: {
        title: "Crear conversacion",
        fieldLabel: "Escribe para buscar un contacto",
        connection: "Conexion",
        add: "Añadir",
        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
          toogle: "¿Asignar chat a mí?",
        },
        toasts: {
          whatsappNotSelected: "¡Se debe seleccionar una conexión!",
        },
      },
      newNoteModal: {
        title: "Crear Anotación",
        form: {
          name: "Nombre",
          extraInfo: "Información Adicional",
          extraName: "Nombre del Campo",
          extraValue: "Valor",
        },
        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
          toogle: "¿Asignar anotación a mí?",
          addExtraInfo: "Agregar Información",
        },
      },
      newInternalChatModal: {
        title: "Crear Conversación Interna",

        form: {
          name: "Nombre",
          user: "Usuario",
        },

        listSubheaders: {
          onlineUsers: "Usuarios en Línea",
          offlineUsers: "Usuarios fuera de Línea",
        },

        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
        },

        toasts: {          
          chatNameInfo: "¡Es necesario agregar un nombre con al menos 3 caracteres a la conversación!",
          selectedUserIdInfo: "¡Es necesario seleccionar un usuario para conversar!",
        },
      },

      newInternalGroupModal: {
        title: "Crear Grupo Interno",

        form: {
          name: "Nombre",
        },

        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
        },

        toasts: {          
          groupNameInfo: "¡Es necesario agregar un nombre con al menos 3 caracteres a la conversación!",
          selectedUserIdsInfo: "¡Es necesario seleccionar al menos 2 usuarios!",
        },
      },

      newOpenAiModal: {
        title: "Crear Bot Chat",
        form: {
          name: "Nombre",
        },
        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
          toogle: "¿Asignar chat bot a mí?",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          schedule: "Agenda",
          general: "General",
          attendants: "Asistentes",
          connections: "Conexiones",
          tickets: "Chats",
          groupsDashboard: "Grupos",
          internalGroupsDashboard: "Grupos Internos",
          ratings: "Evaluaciones",
          internalChats: "Chats Internos",
          ticketsOpen: "Activas",
          ticketsClosed: "Cerradas Hoy",
          ticketsSearch: "Buscar",
          contacts: "Contactos",
          labels: "Labels",
          messages: "Mensajes",
          automaticMessages: "Mensajes Automáticos",
          quickAnswers: "Respuestas Rápidas",
          starredMessages: "Favoritos",
          businessHours: "Horas de Trabajo",
          screening: "Triagem",
          queues: "Linhas",
          categories: "Categorías",
          subqueues: "SubLinhas",
          flow: "Flow",
          administration: "Administración",
          users: "Usuarios",
          profile: "Organización",
          dispatch: "Disparo",
          managerDispatch: "Gerenciador",
          feedbackDispatch: "Feedback",
          settings: "Configuración",
          releases: "Novidades",
        },
        appBar: {
          sound: "Alertas",
          language: "Idioma",
          theme: "Tema",
          user: {
            profile: "Perfil",
            logout: "Cerrar Sesión",
          },
        },
      },
      notifications: {
        noTickets: "Sin notificaciones.",
        notificationsNotSupported: "¡Notificaciones no son compatibles con este navegador!",
      },
      businessHours: {
        title: {
          title1: "Horas de Trabajo",
        },
        buttons: {
          save: "Guardar",
        },
        assets: {
          to: "a",
          personalizedMessage: "Mensaje Personalizado",
          businessHour: "Expediente",
          intervalHour: "Intervalo",
        },
        checkbox: {
          open: "Abierto",
          closed: "Cerrado",
        },
        weekdays: {
          sunday: "Domingo",
          monday: "Lunes",
          tuesday: "Martes",
          wednesday: "Miércoles",
          thursday: "Jueves",
          friday: "Viernes",
          saturday: "Sábado",
        },
      },
      categories: {
        title: "Categorías",
        table: {
          name: "Nombre",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar categoría",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "¿Estás seguro? ¡Esta acción no se puede revertir! Las conversaciones en esa categoría seguirán existiendo, pero ya no tendrán ninguna categoría asignada.",
        },
      },
      subqueues: {
        title: "SubLinhas",
        table: {
          name: "Nombre",
          description: "Descripción",
          actions: "Acciones",
          category: "Categoría",
          queue: "Departamento",
        },
        buttons: {
          add: "Agregar sector",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "¿Estás seguro? ¡Esta acción no se puede revertir! Las conversaciones en eso sector seguirán existiendo, pero ya no tendrán ninguna sector asignado.",
        },
      },
      schedule: {
        title: "Agenda",
        buttons: {
          add: "Añadir Evento",
        },
        messages: {
          months: {
            january: 'Enero',
            february: 'Febrero',
            march: 'Marzo',
            april: 'Abril',
            may: 'Mayo',
            june: 'Junio',
            july: 'Julio',
            august: 'Agosto',
            september: 'Septiembre',
            october: 'Octubre',
            november: 'Noviembre',
            december: 'Deciembre',
          },
          monthsShort: {
            jan: 'Ene',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Abr',
            may: 'May',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Ago',
            sep: 'Sep',
            oct: 'Oct',
            nov: 'Nov',
            dec: 'Dec'
          },
          weekdays: {
            sunday: 'Domingo',
            monday: 'Lunes',
            tuesday: 'Martes',
            wednesday: 'Miércoles',
            thursday: 'Jueves',
            friday: 'Viernes',
            saturday: 'Sábado',
          },
          weekdaysShort: {
            sun: 'Dom',
            mon: 'Lun',
            tue: 'Mar',
            wed: 'Mié',
            thu: 'Jue',
            fri: 'Vie',
            sat: 'Sáb',
          },
          buttons: {
            month: 'Mes',
            week: 'Semana',
            day: 'Día',
            agenda: 'Agenda',
            work_week: 'Semana Laboral',

            today: 'Hoy',
            next: 'Siguient',
            previous: 'Anterior',

            date: 'Fecha',
            time: 'Hora',
            event: 'Evento',
            events: 'Eventos',

            noEventsInRange: 'No hay eventos en el rango',
            allDay: 'Todo el día',
          },
        },
      },
      flow: {
        title: "Flow",
        counts: {
          queues: "Linhas",
          categories: "Categorías",
          subqueues: "Sublinhas",
        },
        tree: {
          chip: "Chip",
          queue: "Linha",
          category: "Categoría",
          subqueue: "Sublinha",
        },
        messages: {
          noWhatsapp: "¡Todavía no has agregado ninguna conexión!",
          noFlow: "¡Todavía no has agregado ningún departamento, categoría o sector!",
        },
        speedDial: {
          newDepartment: "Nueva linha",
          newCategory: "Nueva categoría",
          newSector: "Nueva sublinha",
        },
      },
      queues: {
        title: "Linhas",
        table: {
          isDefault: "Predeterminada",
          name: "Nombre",
          color: "Color",
          greeting: "Mensaje de saludo",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar departamento",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "¿Estás seguro? ¡Esta acción no se puede revertir! Las conversaciones en eso departamento seguirán existiendo, pero ya no tendrán ninguna departamento asignado.",
        },
      },
      queueSelect: {
        inputLabel: "Linhas",
      },
      whatsappSelect: {
        inputLabel: "Conexiones",
      },
      userSelect: {
        inputLabel: "Usuarios",
      },
      contactSelect: {
        inputLabel: "Contactos",
        searchTextPlaceholder: "Buscar...",
      },
      categorySelect: {
        inputLabel: "Categorías",
      },
      subqueueSelect: {
        inputLabel: "Sublinhas",
      },
      connectionSelect: {
        inputLabel: "Conexión",
      },
      labelSelect: {
        inputLabel: "Label",
      },
      ticketTypeSelect: {
        inputLabel: "Plataforma",
      },
      starredMessages: {
        title: "Favoritos",
        loadMoreButton: "Buscar Mas",
        table: {
          actions: "Acciones",
          message: "Mensaje",
          user: "Usuario",
        },
        confirmationModal: {
          title: "¿Te gustaría quitar el mensaje de favorito?",
        },
      },

      automaticMessages: {
        title: "Mensajes Automáticos",
        searchPlaceholder: "Buscar...",

        confirmationModal: {
          deleteTitle: "¿Borrar Mensaje Automático?",
        },

        buttons: {
          add: "Agregar Mensaje Automático",
        },

        table: {
          name: "Nombre",
          keywords: "Palabras-Clave",
          body: "Mensaje",
          actions: "Acciones",
        },
      },

      automaticMessagesModal: {
        title: {
          add: "Agregar mensaje automático",
          edit: "Editar mensaje automático",
        },

        form: {
          name: "Nombre",
          body: "Mensaje",

          buttons: {
            keywords: "Palabras-Clave",
            medias: "Medios",
          },
        },

        validations: {
          name: "¡Se debe proporcionar un nombre y debe contener entre 3 y 25 caracteres!",
          body: "¡Se debe proporcionar un mensaje y debe contener entre 7 y 500 caracteres!",
          whatsappIds: "¡Se debe seleccionar al menos una conexión!",

          nameUnicity: "¡El nombre ya está siendo utilizado por otro Mensaje Automático. Considere usar otro nombre!",
          keywordsUnicity: "Algunas palabras clave están siendo utilizadas por otro Mensaje Automático. ¡Considere eliminarlas o cambiarlas por otra palabra!",
        },

        buttons: {
          okAdd: "Agregar",
          okEdit: "Editar",
          cancel: "Cancelar",
        },
      },

      automaticMessagesKeywordsModal: {
        title: "Palabras-Clave",

        duplicatedKeywords: "Palabras-Clave Duplicadas:",

        form: {
          mainKeyword: "Principal",
          alternativeKeyword: "Alternativa",
        },

        validations: {
          mainKeyword: "¡Se debe proporcionar la palabra-clave principal!",
        },

        buttons: {
          addAlternativeKeyword: "Agregar Palabra-Clave",
          confirm: "Agregar",
        },
      },

      automaticMessagesMediasModal: {
        title: {
          up: "Medios",
        },

        noVisualizationMessage: "¡Vista previa no disponible!",
        addFile: "Añadir Archivo",

        buttons: {
          cancel: "Cancelar",
          confirm: "Agregar",
        },
      },

      quickAnswers: {
        title: "Respuestas rápidas",
        table: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar respuesta rápida",
        },
        searchPlaceholder: "Buscar ...",
        confirmationModal: {
          deleteTitle:
            "¿Está seguro de que desea eliminar esta respuesta rápida?",
          deleteMessage: "Esta acción no se puede deshacer.",
        },
      },
      versionModal: {
        title: {
          up: "Versión Actual",
        },
        tooltips: {
          copy: "Copiar",
          copied: "Copiado",
        },
        spans: {
          year: "Año",
          month: "Mes",
          day: "Dia",
          hour: "Hora",
          minute: "Minuto",
          group: "Grupo",
          tenant: "Tenant",
        },
      },
      labelModal: {
        title: {
          edit: "Editar label",
          add: "Agregar label",
        },
        form: {
          name: "Nombre",
          color: "Color",
          description: "Descripción",
        },
        buttons: {
          cancel: "Cancelar",
          okEdit: "Ahorrar",
          okAdd: "Añadir",
        },
      },
      labels: {
        title: "Labels",
        searchPlaceholder: "Buscar...",
        buttons: {
          add: "Agregar Label",
        },
        table: {
          name: "Nombre",
          color: "Color",
          description: "Descripción",
          actions: "Acciones",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage: "¿Estás seguro? ¡Esta acción no se puede revertir! Los contactos en esa label seguirán existiendo, pero ya no tendrán ninguna label asignada.",
        },
      },

      dispatchManager: {
        title: "Disparo",
        searchPlaceholder: "Buscar ...",

        buttons: {
          add: "Agregar Disparo",
        },

        validation: {
          dispatchAlreadySent: "¡No fue posible concluir la acción porque al menos un envío ya fue realizado!",
        },
        

        table: {
          name: "Nombre",
          startDatetime: "Inicio",
          recurrence: "Recurrencia",
          connection: "Conexión",
          actions: "Acciones",

          recurrenceValues: {
            once: "Única",
            weekly: "Semanal",
            monthly: "Mensual",
          },

          statusValues: {
            cancelled: "Cancelado",
            concluded: "Concluido",
            inProgress: "En progreso",
            pending: "Pendiente",
          },
        },

        confirmationModals: {
          cancelTitle: "Cancelar Disparo?",
          deleteTitle: "Borrar Disparo?",
          uncancelTitle: "Descancelar Disparo?",
        },

        loadingModal: {
          titles: {
            creating: "Creando Disparo",
            deleting: "Eliminando Disparo",
            cancelling: "Cancelando Disparo",
            uncancelling: "Des-cancelando Disparo",
            updating: "Actualizando Disparo",
          },
          messages: {
            general: "Por favor, espere un momento. ¡Este proceso puede tardar unos minutos!",
          },
        },
      },

      buyDispatch: {
        message: "El disparo no está activado en tu cuenta. ¡Haz clic en el botón para saber más sobre la funcionalidad y contratarla!",

        buttons: {
          buy: "Contratar",
        },       
      },

      dispatchManagerModal: {
        title: {
          add: "Agregar disparo",
          edit: "Editar disparo",
        },

        buttons: {
          cancel: "Cancelar",
          okAdd: "Agregar",
          okEdit: "Guardar",
        },

        form: {
          name: "Nombre",
          datetimeToDispatch: "Inicio",
          recurrence: "Recurrencia",
          connection: "Conexión",

          recurrenceValues: {
            once: "Única",
            weekly:"Semanal",
            monthly: "Mensual",
          },

          buttons: {
            contacts: "Contactos",
            messages: "Mensajes",
          },
        },

        validations: {
          nameSize: "El nombre del disparo debe contener entre 3 y 50 caracteres!",
          datetimeToDispatchEmpty: "¡Seleccione una fecha y hora para enviar mensajes!",
          datetimeToDispatchInvalid: "¡La fecha y hora de inicio del envío de mensajes debe ser al menos una hora más tarde que ahora!",
          recurrenceInvalid: "¡Seleccione una recurrencia para enviar mensajes!",
          connectionInvalid: "¡Seleccione una tarjeta SIM para enviar mensajes!",

          message1Size: "¡El mensaje 1 debe ser completado y tener entre 3 y 500 caracteres!",
          otherMessagesSize: "¡Los mensajes completados deben contener entre 3 y 500 caracteres!",

          contactsSize: "¡Al menos 3 contactos deben ser seleccionados!",
        },
      },

      dispatchSendingsModal: {
        title: "Envios del Disparo",
        dispatch: "Disparo:",

        confirmationModals: {
          cancelTitle: "¿Cancelar Envio del Disparo?",
          uncancelTitle: "¿Descancelar Envio del Disparo?",
        },

        validation: {
          dispatchAlreadySent: "¡No fue posible concluir la acción porque el envío ya fue realizado!",
        },        

        filters: {
          nameNumber: "Nombre o Número",
        },

        table: {
          contactName: "Contacto",
          contactNumber: "Número",
          message: "Mensaje",
          status: "Status",
          errorDescription: "Error",
          lastUpdateAt: "Última Actualización",
          actions: "Acciones",

          statusValues: {
            cancelled: "Cancelado",
            concluded: "Concluido",
            error: "Error",
            inProgress: "En progreso",
            pending: "Pendiente",
          },
        },

        buttons: {
          close: "Cerrar",
        },
      },

      dispatchSendingMessageModal: {
        title: "Mensaje",
      },

      dispatchSendingErrorDescriptionModal: {
        title: "Descripción del Error",
      },

      ratingsSelect: {
        inputRating: "Evaluaciones",
      },

      dispatchStatusSelect: {
        inputStatus: "Status",

        statusValues: {
          cancelled: "Cancelado",
          concluded: "Concluido",
          error: "Error",
          inProgress: "En progreso",
          pending: "Pendiente",
        },
      },

      dispatchManagerMessagesModal: {
        title: "Mensajes",

        buttons: {
          cancel: "Cancelar",
          confirm: "Confirmar",
        },

        form: {
          message1: "Mensaje 1",
          message2: "Mensaje 2",
          message3: "Mensaje 3",
        },
      },

      dispatchManagerContactsModal: {
        title: "Contactos",

        filters: {
          nameNumber: "Nombre o Numero",
          birthday: "Compleaños",
          label: "Label",
        },

        buttons: {
          additionalContacts: "Contactos Adicionales",
          cancel: "Cancelar",
          confirm: "Confirmar",
        },
      },

      dispatchManagerAdditionalContactsModal: {
        title: "Contactos Adicionales",

        invalidContacts: "Contactos Inválidos",

        form: {
          additionalContacts: "Contactos Adicionales",
        },

        validations: {
          invalidAdditionalContacts: "¡Se han ingresado algunos números inválidos!",
        },      

        buttons: {
          cancel: "Cancelar",
          validate: "Validar",
        },
      },

      transferList: {
        choices: "Elecciones",
        chosen: "Elegidos",
        selected: "seleccionados",
      },

      users: {
        title: "Usuarios",
        searchPlaceholder: "Buscar ...",
        usedUsersCount: {
          outOf: "de",
          users: "usuario(s)",
        },
        tooltips: {
          listLayout: "Diseño de lista",
          cardLayout: "Diseño de card",
          bothLayout: "Todo",
          adminsLayout: "Administradores",
          usersLayout: "Usuarios",
          admin: "Administrador",
        },
        table: {
          name: "Nombre",
          email: "Correo Electrónico",
          profile: "Perfil",
          whatsapp: "Conexión estándar",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar usuario",
        },
        toasts: {
          notDeleted: "¡No puedes borrar a tu propio usuario!",
        },
        confirmationModal: {
          deleteTitle: "Borrar",
          deleteMessage:
            "Toda la información del usuario se perderá. Las conversaciones abiertas de los usuarios se moverán a la cola.",
        },
      },
      settings: {
        success: "Configuración guardada satisfactoriamente.",
        title: "Configuración",
        settings: {
          general: "General",
          privacy: "Privacidad",
          schedule: "Schedule",
          sendings: "Envíos",
          service: "Service",
          options: {
            enabled: "Enabled",
            disabled: "Disabled",
            
            lessRecent: "Menos Recientes",
            moreRecent: "Más Recientes",
            
            never: "Nunca",

            chats: "Chats",
            groups: "Grupos",
            open: "Abiertos",
            pending: "Pendientes",
            both: "Ambos",
          },
          ticketsListSortMethod: {
            name: "Ordenación las listas de conversaciones",
          },
          ticketsDynamicSorting: {
            name: "Ordenación dinámico de conversaciones",
            tooltip: "Las conversaciones se ordenan por el mensaje más reciente",
          },
          enterIsSendMobile: {
            name: "Enviar mensajes con Enter (solo en teléfonos móviles)",
          },
          fetchUnreadMessagesAfterConnecting: {
            name: "Busque mensajes no leídos después de conectar el chip",
          },
          userPermissionsByChip: {
            name: "Permisos de usuario por chip",
          },
          allowDefaultQueue: {
            name: "Permitir linha predeterminada",
          },
          allowGroups: {
            name: "Permitir grupos",
          },
          allowChannels: {
            name: "Permitir canales",
          },
          timezone: {
            name: "Zona horaria",
          },
          missedCalls: {
            name: "Crear chats a llamadas perdidas",
          },
          mergeMessagesFromDifferentConnectionsSetting: {
            name: "Ver mensajes de todas las conexiones",
          },
          showOnlyMessagesRelatedToUsersChats: {
            name: "Mostrar solo mensajes vinculados a las conversaciones de los usuarios",
            tooltip: "Válido solo para usuarios que no sean administradores",
          },
          showMessagesRelatedToUserQueues: {
            name: "Mostrar mensajes vinculadas a las linhas de los usuarios",
            tooltip: "Apenas funciona si la configuración 'Mostrar solo mensajes vinculados a las conversaciones de los usuarios' está desactivada",
          },
          viewPresenceUpdate: {
            name: "Mostrar presencia en las conversaciones",
            tooltip: "La opción debe estar activada en el WhatsApp de tu celular. Además, esta configuración no funciona para grupos y canales",
          },
          linkPreview: {
            name: "Enviar enlaces de vista previa",
          },
          notificationSoundForInternalChats: {
            name: "Sonido de notificación para conversaciones internas",
          },
          markMessagesAsRead: {
            name: "Marcar mensajes como leídos en la aplicación",
          },
          requireTagOnContacts: {
            name: "Label requerida en contactos",
          },
          sendSignatureOnMessages: {
            name: "Enviar firma en mensajes",
          },
          adminCanViewOtherChats: {
            name: "Abre la conversación si ya está activa con otro usuario",
          },
          automaticallyRejectCalls: {
            name: "Rechazar llamadas automáticamente",
            tooltip: "Cuando está activada, se envía automáticamente un mensaje al contacto",
          },
          apiToken: {
            name: "Token da API",
            copyTooltip: "Copiar",
            copiedTooltip: "Copiado",
          },
          prioritizeUsers: {
            name: "Priorizar usuarios para chatear con el contacto",
          },
          prioritizeUsersTime: {
            name: "Tiempo de prioridad de los usuarios (minutos)",
          },
          allowTransferTicketsToOfflineUsers: {
            name: "Permitir transferir chats a usuarios sin conexión",
          },
          allowResolveTicketWithoutGoodBye: {
            name: "Permitir cerrar conversaciones sin enviar mensaje de despedida",
          },
          adminsPendingTicketsControl: {
            name: "Los administradores controlan los chats pendientes",
          },
          chatExpiration: {
            name: "Tiempo de caducidad del chat - Pendente",
          },
          chatExpirationOpened: {
            name: "Tiempo de caducidad del chat - Abierto",
          },
          sendRatingPollOnChatExpiration: {
            name: "Enviar encuesta de satisfacción para conversaciones caducadas - Pendente",
          },
          sendRatingPollOnChatExpirationOpened: {
            name: "Enviar encuesta de satisfacción para conversaciones caducadas - Abierto",
          },
          sendListsButtons: {
            name: "Enviar listas y botones",
          },
          awaitingTime: {
            name: "Tiempo de espera (minutos)",
          },
          attendanceTime: {
            name: "Tiempo de atendimento (minutos)",
          },
          forceAcceptLongAwaitingTimeTickets: {
            name: "Forzar la aceptación de chats con mucho tiempo de espera",
          },
          userCreation: {
            name: "Creación de usuarios",
          },
          sendProtocolMessage: {
            name: "Enviar mensaje de protocolo",
          },
          sendTransferMessage:{
            name: "Enviar mensaje de transferencia",
          },
          sendWaitingAttendant: {
            name: "Enviar mensaje de asistente de espera por respuesta invalida",
          },
          sendNotificationsTo: {
            name: "Enviar notificaciones a",
          },
          sendNotifications: {
            name: "Enviar notificaciones de chats",
          },
        },
      },
      location: {
        errors: {
          permissionNotGranted: "¡El permiso de geoubicación no está habilitado!",
          positionUnavaible: "Su posición actual no está disponible en este momento, ¡inténtelo de nuevo más tarde!",
          timeout: "No se pudo identificar su posición actual, inténtelo de nuevo más tarde!",
        },
      },
      profile: {
        success: "Organización guardado satisfactoriamente.",
        title: "Organización",
        tooltips: {
          turnOnGeolocation: "Activar Geoubicación",
          turnOffGeolocation: "Desactivar Geoubicación",
        },
        labels: {
          name: "Nombre de la empresa",
          description: "Descripción de la empresa",
          logo: "Logotipo",
          logoField: "Clic abajo para agregar/reemplazar el logotipo",
          sendLogoAsSticker: "Enviar logo como pegatina",
          latitude: "Latitud",
          longitude: "Longitud",
          accuracy: "Precisión",
        },
        buttons: {
          save: "Guardar",
        },
      },

      messageNotAvailable: {
        deletedFile: "¡Este archivo ya no está disponible, pero puedes verlo en tu móvil a través de WhatsApp!",
      },

      messagesList: {
        header: {
          assignedTo: "Asignado a:",
          buttons: {
            return: "Devolver",
            close: "Cerrar",
            resolve: "Resolver",
            resolveWithoutFarewell: "Resolver sin despedida",
            reopen: "Reabrir",
            accept: "Aceptar",
          },
        },

        toast: {
          forwardMessage: {
            limit: "¡Solo se pueden reenviar 20 mensajes a la vez!",
            noSelectedMessages: "¡Debes seleccionar al menos 1 mensaje para reenviar!",
          },
          closeTicket: {
            whatsappQueuesNotLoaded: "Cargando datos de conversación antes de finalizar. ¡Inténtalo de nuevo en unos segundos!",
          },
        },

        confirmationModal: {
          openPrivateChat: {
            title: "¿Te gustaría iniciar una conversación con",
          },
          resendMessage: {
            title: "Te gustaría reenviar el mensaje?"
          },
        },
      },

      messageBodyWrapper: {
        readMore: "Leer Más",
        readLess: "Leer Menos",
      },

      shareMessage: {
        notSupport: "¡Su navegador no admite el uso compartido de archivos!",
        errorSharing: "¡No se puede compartir el archivo! Vuelva a intentarlo más tarde.",
      },

      messagesListChatView: {
        noMessagesText: "Sin mensajes en la conversación..",
      },

      messagesInput: {
        placeholderOpen: "Escriba un mensaje o presione '' / '' para usar las respuestas rápidas registradas",
        placeholderOpenMobile: "Escriba un mensaje",
        placeholderClosed: "Vuelva a abrir o acepte esta conversacion para enviar un mensaje.",
        placeholderBlockedContact: "¡No puedes enviar mensajes porque el contacto está bloqueado!",
        placeholderInactiveContact: "¡No puedes enviar mensajes porqué el contacto está bloqueado!",
        placeholderChannelContact: "¡No puedes enviar mensajes a conversaciones del canales!",
        placeholderReadOnlyGroup: "No puedes enviar mensajes porque ya no perteneces a este grupo.",
        signMessage: "Firmar",
        view: "Visualizar",
        download: "Download",
        forwardedMessage: "Mensaje Encaminhada",
        editedMessage: "Editado",
        scrollToSeeMessages: "Desplázate para ver nuevos mensajes",

        micAudioConditions: {
          permissionDeniedBySystem: "¡El sistema de su dispositivo denegó el acceso al micrófono!",
          lostConnection: "El navegador ha perdido la conexión con su micrófono. ¡Inténtelo de nuevo más tarde!",
          micNotEnabled: "¡Micrófono no habilitado!",
          micNotDetected: "¡No se detectó ningún micrófono!",
        },

        toasts: {
          sendGif: "¡No se puede enviar el GIF, inténtalo de nuevo más tarde!",
          audioAccessNotAllowed: "El navegador no puede acceder a su micrófono. ¡Habilite el permiso antes de cargar audios!",
          onlyImageAllowed: "¡Solo se pueden enviar imágenes como pegatinas!",
          onlyImageAndVideoAllowed: "¡Solo se pueden enviar imágenes y vídeos como visualización única!",
        },

        tooltips: {
          emojis: "Seleccionar emojis",
          record: "Grabar audio",
          internalMessage: "Mensaje interno",
          moreVert: "Más opciones",
          attach: "Adjuntar archivo",
          viewOnce: "Vista única",
          poll: "Encuesta",
          location: "Ubicación",
          takePhoto: "Tomar foto",
          vcard: "Compartir contacto",
          send: "Enviar mensaje",
          cancel: "Cancelar",
          confirm: "Confirmar",
          stickers: "Seleccionar pegatinas",
          createStickers: "Crear pegatinas",
          messageNotSent: "Mensaje no enviado",
        },
      },
      viewOnceRepliedMessage: {
        photo: "Foto",
        video: "Video",
      },
      audioComponent: {
        accordionTitle: "Transcripción",
        tooltips: {
          copy: "Copiar",
          copied: "Copiado",
        },
      },
      contactDrawer: {
        header: "Detalles del contacto",

        buttons: {
          edit: "Editar contacto",
          editGroup: "Editar grupo",
          participants: "Participantes",
          closeGroup: "Cerrar grupo",
          leaveGroup: "Salir",
        },

        extraInfo: "Otra información",

        tooltips: {
          copy: "Copiar",
          copied: "¡Copiado!",
        },

        confirmationModals: {
          closeInternalGroupTitle: "¿Te gustaría cerrar del Grupo Interno:",
          leaveInternalGroupTitle: "¿Te gustaría salir del Grupo Interno:",
        },
      },
      ticketOptionsMenu: {
        edit: "Editar",
        block: "Bloquear",
        unblock: "Desbloquear",
        delete: "Borrar",
        note: "Anotación",
        transfer: "Transferir",
        searchMessage: "Buscar",
        confirmationModal: {
          title: "¿Borrar conversacion #",
          titleFrom: "del contacto ",
          message:
            "¡Atención! Todos los mensajes Todos los mensajes relacionados con el conversacion se perderán.",
        },
        buttons: {
          delete: "Borrar",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },

      ticketOptionsContextMenu: {
        accept: "Aceptar",
        view: "Ver",
        markAsUnread: "Marcar como no leído",
        markAsRead: "Marcar como leído",
        transfer: "Transferir",
        edit: "Editar",
        return: "Devolver",
        reopen: "Reabrir",
        resolve: "Resolver",
        close: "Cerrar Menú",
      },

      internalGroupParticipantsContextMenu: {
        promote: "Promocionar",
        demote: "Degradar",
        remove: "Eliminar",
      },

      phoneNumberOptionsMenu: {
        chat: "Conversar con el Número",
        copy: "Copiar Número de Celular",
        close: "Cerrar",
        confirmationModal: {
          title: "¿Desea conversar con el número?",
          fields: {
            connection: "Conexion",
          },
        },
      },

      messageOptionsMenu: {
        transcript: "Transcribir",
        resend: "Reenviar",
        delete: "Borrar",
        reply: "Responder",
        forward: "Reenviar",
        edit: "Editar",
        copy: "Copiar",
        star: "Star",
        unstar: "Unstar",
        speech: "Habla",
        properties: "Propiedades",
        close: "Cerrar",
        confirmationModal: {
          title: "¿Borrar mensaje?",
          message: "Esta acción no puede ser revertida.",
        },
      },
      backendErrors: {
        GENERAL_ERROR: "No pude hacer eso en este momento. ¡Inténtalo de nuevo más tarde!",
        ERR_NO_OTHER_WHATSAPP:
          "Debe haber al menos una conexión de WhatsApp predeterminada.",
        ERR_NO_DEF_WAPP_FOUND:
          "No se encontró WhatsApp predeterminado. Verifique la página de conexiones.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sesión de WhatsApp no ​​está inicializada. Verifique la página de conexiones.",
        ERR_WAPP_CHECK_CONNECTION:
          "No se pudo verificar el contacto de WhatsApp. Verifique la página de conexiones.",
        ERR_WAPP_INVALID_CONTACT: "Este no es un número de whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "No se pudieron descargar los medios de WhatsApp. Verifique la página de conexiones.",
        ERR_INVALID_CREDENTIALS: "Error de autenticación. Vuelva a intentarlo.",
        ERR_SENDING_WAPP_MSG:
          "Error al enviar el mensaje de WhatsApp. Verifique la página de conexiones.",
        ERR_DELETE_WAPP_MSG: "No se pudo borrar el mensaje de WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Ya hay una conversacion abierta para este contacto.",
        ERR_OTHER_OPEN_TICKET_USER: "Chat está asignado al usuario:",
        ERR_OTHER_OPEN_TICKET_QUEUE: "Chat está asignado a linha:",
        ERR_OTHER_OPEN_TICKET_EXCEPTION:
          "Ya hay una conversacion abierta para este contacto. ¡Vea la aba Trabajando En y Departamento en Chats Activos!",
        ERR_SESSION_EXPIRED: "Sesión caducada. Inicie sesión.",
        ERR_USER_ALREADY_LOGIN: "El usuario ya inició sesión en otra computadora",
        ERR_SERVER_MAINTANCE: "El servidor está en mantenimiento, espere unos minutos",
        ERR_INVALID_TOKEN: "Simbolo no valido. Se creará uno nuevo en la próxima solicitud",
        ERR_USER_CREATION_DISABLED: "La creación de usuarios fue deshabilitada por el administrador",
        ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
        ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
        ERR_NO_SETTING_FOUND: "No se encontró ninguna configuración con este ID.",
        ERR_NO_CONTACT_FOUND: "No se encontró ningún contacto con este ID.",
        ERR_NO_TICKET_FOUND: "No se encontró ningún conversacion con este ID.",
        ERR_NO_USER_FOUND: "No se encontró ningún usuario con este ID.",
        ERR_NO_WAPP_FOUND: "No se encontró WhatsApp con este ID.",
        ERR_CREATING_MESSAGE: "Error al crear el mensaje en la base de datos.",                
        ERR_MYPROFILE_INVALID_NAME: "Nombre de perfil inválido.",
        ERR_UPDATE_MYPROFILE: "No se pudo actualizar el perfil. ¡Inténtalo de nuevo más tarde!",
        ERR_CREATING_TRANSFERENCE_OBSERVATION_MESSAGE: "Error al crear el mensaje de observación de transferencia en la base de datos. ¡Inténtalo de nuevo más tarde!",
        ERR_CREATING_INTERNAL_MESSAGE: "Error al crear el mensaje interno en la base de datos. ¡Inténtalo de nuevo más tarde!",
        ERR_CREATING_TICKET: "Error al crear el conversacion en la base de datos. ¡Inténtalo de nuevo más tarde!",
        ERR_FETCH_WAPP_MSG:
          "Error al obtener el mensaje en WhatsApp, tal vez sea demasiado antiguo o ha sido eliminado por el contacto.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Este color ya está en uso, elija otro.",
        ERR_WAPP_GREETING_REQUIRED:
          "El mensaje de saludo es obligatorio cuando hay más de un departamento.",
        ERR_FILE_SIZE_UPLOAD:
          "WhatsApp Web no admite archivos con más de 15 MB.",
        ERR_FILE_NOT_SUPPORTED:
          "¡Este tipo de archivo no es compatible!",
        ERR_REACHED_MAIN_QUEUES_LIMIT: 
          "¡No es posible registrar más de 3 departamentos!",
        LABEL_NOT_SELECTED:
          "¡Label desinformada!",
        QUEUE_NOT_SELECTED:
          "¡Departamento desinformado!",
        SUBQUEUE_NOT_SELECTED:
          "¡Sector desinformado!",
        DASHBOARD_INITIAL_FINAL_DATE_CLEAN:
          "¡No puedes borrar la fecha inicial y final!",
        DASHBOARD_INITIAL_DATE_AFTER_FINAL_DATE:
          "¡La fecha inicial no puede ser posterior a la fecha final!",
        BUSINESS_HOURS_INITIAL_HOUR_EQUAL_FINAL_HOUR:
          "¡La hora inicial no puede ser igual a la hora final si la empresa está abierta! Verifica si todas las horas han sido correctamente llenadas.",
        BUSINESS_HOURS_INITIAL_HOUR_AFTER_FINAL_HOUR:
          "¡La hora inicial no puede ser después de la hora final! Verifica si todas las horas han sido correctamente llenadas.",
        BUSINESS_HOURS_PERSONALIZED_MESSAGES_EMPTY:
          "¡El Mensaje Personalizado debe tener más de 3 caracteres cuando está habilitado! Verifica si todos los mensajes han sido llenados correctamente.",
        INTERVAL_HOURS_EQUALS_AFTER_FINAL_HOUR:
          "La Hora de Inicio del Intervalo no puede ser igual o posterior a la Hora Final del Intervalo.",
        INTERVAL_HOURS_NOT_BETWEEN_BUSINESS_HOURS:
          "El Horario de Intervalo no está entre el Horario Comercial.",
        SERIAL_NUMBER_NOT_INFORMED:
          "¡Número de Serie desinformado!",
        RESALE_SERIAL_NUMBER_NOT_INFORMED:
          "¡Número del Distribuidor desinformado!",
        ERR_STAR_MSG:
          "¡Erro al destacar mensaje!",
        ERR_UNSTAR_MSG:
          "¡Erro al desfavorecer mensaje! Tente novamente más tarde.",
        ERR_EMAIL_NOT_FOUND: 
          "¡Email no registrado!!",
        ERR_EMAIL_ALREADY_EXISTS:
          "¡Ya existe un usuario con este correo electrónico!",
        ERR_LABEL_NAME_ALREADY_EXISTS:
          "Este nombre ya está en uso, elija otro.",
        ERR_SUBQUEUE_INVALID_DESCRIPTION:
          "¡Proporcione una descripción para la sublinha!",
        ERR_INFO_NOT_FOUND:
          "No fue posible cargar la información del mensaje. ¡Probablemente el mensaje no existe en el teléfono!",        
        ERR_PAYMENT_REQUIRED:
          "Hay facturas vencidas en su cuenta. ¡Por favor, contacte a nuestro soporte para obtener más información!",
        ERR_SERVER_IN_MAINTENANCE:
          "El servidor está en mantenimiento en este momento, ¡por favor, inténtelo de nuevo en unos minutos!",
        ERR_SERVER_MIGRATION:
          "Su cuenta está en proceso de migración de servidor, ¡por favor, inténtelo de nuevo en unos minutos!",
        EDIT_MESSAGE_LIMIT_MINUTES_REACHED:
          "¡No es posible editar mensajes de texto después de 15 minutos de haberlos enviado!",
        EDIT_MESSAGE_INTERNAL_ERROR:
          "Hubo un error mientras se editaba el mensaje. ¡Inténtalo de nuevo más tarde!",
        NO_MESSAGE_FOUND_BY_ID:
          "¡No se encontró ningún mensaje con la identificación dada!",
        ERR_COMPANY_LOCATION_NOT_REGISTERED:
          "¡La ubicación no se informa en el perfil de la propiedad!",
        ERR_FORCE_ACCEPT_LONG_AWAITING_TIME_TICKETS: 
          "Hay conversaciones con tiempos de espera largos. ¡Es necesario finalizarlas primero!",
        ERR_OPENAI_TRANSCRIBE_AUDIO_NOT_FOUND:  "¡Audio no se encuentra para ser transcrito! Vuelva a intentarlo más tarde.",
        ERR_TRANSCRIBE_AUDIO: "No fue posible transcribir el audio. Inténtalo nuevamente más tarde!",
        ERR_INVALID_POLL_DATA:
          "¡Datos de encuesta no válidos! ¡Asegúrate de completar todos los campos obligatorios!",
        ERR_INVALID_POLL_MESSAGE:
          "¡Encuesta no encontrada! Vuelva a intentarlo más tarde.",
        ERR_INVALID_CPF:
          "¡CNPJ no Válido!",
        ERR_INVALID_CNPJ:
          "¡CNPJ Inexistente!",
        ERR_INVALID_BIRTHDAY:
          "¡La Fecha de Cumpleaños no puede ser posterior a hoy!",
        ERR_CATEGORY_NAME_ALREADY_EXISTS:
          "¡Nombre de categoría duplicado!",
        ERR_QUEUE_NAME_ALREADY_EXISTS:
          "¡Nombre de departamento duplicado!",
        ERR_UNBLOCK_CONTACT:
          "Se produjo un error al desbloquear el contacto. ¡Vuelva a intentarlo más tarde!",
        ERR_BLOCK_CONTACT:
          "Se produjo un error al bloquear el contacto. ¡Inténtelo de nuevo más tarde!",
        ERR_SETTING_NOT_FOUND: "¡Configuración no encontrada!",
        ERR_GROUP_NOTIFICATION: "🤔 ¡No es posible lidar con una notificación del grupo!",
        ERR_SENDING_WAPP_REACTION: "Error al enviar el reación de WhatsApp. Verifique la página de conexiones.",
        FAILED_TO_JOIN_GROUP_BY_INVITE_CODE: "No se pudo unir al grupo. ¡Solicite otra invitación al administrador del grupo!",
        ERR_SEND_POLL_VOTE: "No se puede votar en la encuesta, ¡inténtalo de nuevo más tarde!",
        ERR_MARK_CHAT_READ_UNREAD: "No se puede marcar la conversación como leída/no leída. ¡Inténtalo de nuevo más tarde!",
        ERR_IMPORT_MESSAGES: "No se pueden importar mensajes. ¡Inténtalo de nuevo más tarde!",
        ERR_SENDING_LIST_RESPONSE_MSG: "Error al enviar el mensaje de Respuesta de la Lista de WhatsApp. ¡Verifique la página de conexiones!",
        ERR_SENDING_BUTTON_RESPONSE_MSG: "Error al enviar el mensaje de Respuesta del Botón de WhatsApp. ¡Verifique la página de conexiones!",
        ERR_USER_NOT_ALLOWED: "¡Usuario sin permiso para realizar la operación!",
        NO_PERMISSION_UPDATE_GROUP_NAME: "No tienes permiso para cambiar el nombre del grupo. ¡Consulta con el administrador del grupo!",
        ERR_FORWARD_MESSAGE: "No se pudo reenviar el mensaje. ¡Inténtalo de nuevo más tarde!",
        ERR_MARK_INTERNAL_CHAT_MESSAGES_AS_READ: "¡No se pudieron marcar los mensajes como leídos!",
        ERR_CREATING_PHONE_NUMBER_MESSAGE_TICKET: "¡No se pudo establecer una conversación con el número proporcionado en el mensaje!",
        ERR_FETCHING_GROUP_PARTICIPANTS: "No fue posible listar a los participantes del grupo. ¡Inténtalo de nuevo más tarde!",
        ERR_FETCHING_REMAINING_GROUP_TICKETS: "No fue posible listar todas las conversaciones restantes del grupo. ¡Inténtalo de nuevo más tarde!",
        ERR_FETCH_MESSAGES: "No fue posible listar los mensajes del conversacione. ¡Inténtalo de nuevo más tarde!",
        ERR_WAPP_BECOMING_NOT_DEFAULT: "¡No es posible transformar directamente una conexión predeterminada a no-predeterminada!",
        ERR_SEARCH_MESSAGE: "No fue posible buscar mensajes. ¡Inténtalo de nuevo más tarde!",
        NOT_ALLOWED_TO_DELETE_MESSAGE: "¡No tienes permiso para borrar mensajes que no fueron enviados por ti!",
        NOT_ALLOWED_TO_EDIT_MESSAGE: "¡No tienes permiso para editar mensajes que no fueron enviados por ti!",
        ERR_MARK_BUTTON_LIST_AS_INTERACTED: "No se pudo marcar el botón/lista como interactuado. ¡Inténtalo de nuevo más tarde!",
        ERR_CREATE_EVENT_DETAILS: "¡No se pudo crear la asociación de los detalles del evento con el mensaje!",
        ERR_BUTTON_OPTION: "¡No se pudo crear la asociación de la opción del botón con el mensaje!",
        ERR_LIST_OPTION: "¡No se pudo crear la asociación de la opción de la lista con el mensaje!",
        ERR_POLL_OPTION: "¡No se pudo crear la asociación de la opción de la encuesta con el mensaje!",
        ERR_DISPATCH_CREATE: "No se pudo crear el envío. ¡Inténtalo de nuevo más tarde!",
        ERR_DISPATCH_UPDATE: "No se pudo actualizar el envío. ¡Inténtalo de nuevo más tarde!",
        ERR_UPDATE_INTERNAL_GROUP: "No se pude actualizar el grupo interno. ¡Inténtalo de nuevo más tarde!",
        ERR_CLOSE_INTERNAL_GROUP: "No se pude cerrar el grupo interno. ¡Inténtalo de nuevo más tarde!",
        ERR_LEAVE_INTERNAL_GROUP: "No fue posible salir del grupo interno. ¡Inténtalo de nuevo más tarde!",
        ERR_REMOVE_INTERNAL_GROUP_PARTICIPANT: "No fue posible remover al participante del grupo interno. ¡Inténtalo de nuevo más tarde!",
        ERR_FETCH_PARTICIPANTS_INTERNAL_GROUP: "No fue posible obtener los participantes del grupo interno. ¡Inténtalo de nuevo más tarde!",
        ERR_FETCH_NEW_PARTICIPANTS_INTERNAL_GROUP: "No fue posible obtener los nuevos participants del grupo interno. ¡Inténtalo de nuevo más tarde!",
        ERR_PROMOTE_INTERNAL_GROUP_PARTICIPANT: "No fue posible promocionar lo participante del grupo interno. ¡Inténtalo de nuevo más tarde!",
        ERR_DEMOTE_INTERNAL_GROUP_PARTICIPANT: "No fue posible degradar lo participante del grupo interno. ¡Inténtalo de nuevo más tarde!",
        ERR_ADD_PARTICIPANT_INTERNAL_GROUP: "No fue posible añadir un participante al grupo interno. ¡Inténtalo de nuevo más tarde!",
        ERR_NOTIFICATION_MESSAGE_INTERNAL_GROUP: "No fue posible agregar el mensaje de notificación en el grupo interno. ¡Inténtalo nuevamente más tarde!",
      },
    },
  },
};

export { messages };
