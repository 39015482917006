import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: { display: "flex", flexWrap: "wrap" },

	textField: { flex: 1 },

	btnWrapper: { position: "relative" },

	dialogContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		justifyContent: "center",
		gap: "1em",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	floatingButton: { transition: 'transform 0.3s', '&:hover': { transform: 'translateY(-5px)' }, },
}));

const InternalGroupEditionModal = ({ open, onClose, ticketId, ticketName }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState(ticketName);



	// 	****************
	// 	** Use Effect **
	// 	****************
	useEffect(() => {
		if (open && ![null, undefined, ""].includes(ticketName)) setName(ticketName);
	}, [open, ticketName]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
    setIsSubmitting(false);
    setName("");
  };

	const handleChangeName = (event) => {
		setName(event.target.value);
	};

  const handleSave = async () => {
    try {
			setIsSubmitting(true);

			if ([null, undefined].includes(name) || name.length < 3) {
				toast.info(i18n.t("newInternalGroupModal.toasts.groupNameInfo"));
				setIsSubmitting(false);
				return;
			}

			await api.patch(`/internalGroups/${ticketId}`, { noteName: name.trim() });
			handleClose();
		} catch (exception) {
			setIsSubmitting(false);
			console.log("Internal Group Edition Modal - Handle Save Exception:", exception);
			toastError(exception);
		}
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" scroll="paper">
        <DialogTitle>
          {i18n.t("internalGroupEditionModal.title")}
        </DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
					<TextField
						autoFocus
						fullWidth
						name="name"
						variant="outlined"
						margin="dense"

						className={classes.textField}
						label={i18n.t("internalGroupEditionModal.form.name")}
						
						value={name}
						onChange={(event) => handleChangeName(event)}
					/>
        </DialogContent>

        <DialogActions>
          <Button
            color="inherit"
            variant="outlined"
            className={classes.floatingButton}
            disabled={isSubmitting}
            onClick={handleClose}
          >
            {i18n.t("internalGroupEditionModal.buttons.cancel")}
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            className={`${classes.btnWrapper} ${classes.floatingButton}`}
            onClick={handleSave}
          >
            {i18n.t("internalGroupEditionModal.buttons.save")}
            {isSubmitting && (<CircularProgress size={24} className={classes.buttonProgress} />)}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InternalGroupEditionModal;
