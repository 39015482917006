import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import InternalChatImg from "../../assets/internalChat.png";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({  
  // *************
  // ** Content **
  // *************
  dialogContent: { overflowY: "scroll", ...theme.scrollbarStyles, },

  participantCard: { borderRadius: "20px", padding: "10px", marginBottom: "5px", },

  participantCardActionArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "20px",
    padding: "5px",
  },

  participantName: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.5em",
    fontSize: "16px",
  },

  floatingButton: {
    transition: "transform 0.3s",
    "&:hover": { transform: "translateY(-5px)", },
  },



  //  ***********************
  //  ** Circular Progress **
  //  ***********************
  circularProgressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const AddParticipantsInternalGroupModal = ({ open, onClose, ticketId, participantIds }) => {
  //  *************
  //  ** Classes **
  //  *************
  const classes = useStyles();

  const [loading, setLoading] = useState("");
  const [searchParam, setSearchParam] = useState("");

  const [filteredUsersList, setFilteredUsersList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedUsersList, setSelectedUsersList] = useState([]);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (open) {
      const delayDebounceFn = setTimeout(() => {
        const fetchUsers = async () => {
          setLoading(true);

          try {
            const { data: newParticipantsList } = await api.get("/internalGroups/fetchNewParticipants", {
              params: { participantIds }
            });

            const sortedNewParticipantsList = newParticipantsList.sort((participantA, participantB) => 
              participantA.name.localeCompare(participantB.name)
            );

            setUsersList(sortedNewParticipantsList);
            setFilteredUsersList(sortedNewParticipantsList);
          }
          catch (exception) { toastError(exception); }
          finally { setLoading(false); }
        };

        fetchUsers();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [open, participantIds]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();

    setLoading(false);
    setSearchParam("");

    setFilteredUsersList([]);
    setUsersList([]);
    setSelectedUsersList([]);
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase().trim();
    setSearchParam(searchValue);

    if (searchValue === "") {
      setFilteredUsersList(usersList);
    }
    else {
      setFilteredUsersList(usersList.filter(
        participant => participant.name && participant.name.toLowerCase().includes(searchValue)
      ));
    }
  };

  const handleSelectUser = (userId) => {
    const card = document.getElementById(`card-${userId}`);
    const checkbox = document.getElementById(`checkbox-${userId}`);

    if (!checkbox.checked) {
      card.style.backgroundColor = "rgba(255, 105, 97, 0.55)";
      checkbox.checked = true;
      checkbox.click();
      
      const selectedUser = usersList.filter(user => user.id === userId).map(user => {
        return { ticketId, userId: user.id, userProfile: "member", };
      });
      setSelectedUsersList([...selectedUsersList, ...selectedUser]);
    }
    else {
      card.style.backgroundColor = "inherit";
      checkbox.checked = false;
      checkbox.click();
      setSelectedUsersList(selectedUsersList.filter(user => user.id !== userId));
    }
  };

  const renderParticipantsCardList = () => {
    return (
      filteredUsersList.map(participant => (
        <Card
          id={`card-${participant.id}`}
          className={classes.participantCard}
          onClick={() => handleSelectUser(participant.id)}
        >
          <CardActionArea className={classes.participantCardActionArea}>
            <Checkbox id={`checkbox-${participant.id}`} style={{ display: "none" }} />

            <Avatar
              alt={participant?.name}
              src={InternalChatImg}
              className={classes.participantAvatar}
            />

            <CardContent>
              <Typography className={classes.participantName} component="h3" gutterBottom>
                {participant?.name}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))
    );
  };

  const handleAddParticipants = async () => {
    try {
      setLoading(true);
      await api.post("/ticketUsers/add", { users: selectedUsersList });
      toast.success(i18n.t("addParticipantsInternalGroupModal.toasts.success"));
      handleClose();
    }
    catch (exception) { toastError(exception); }
    finally { setLoading(false); }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <Dialog fullWidth open={open} onClose={handleClose} maxWidth="xs" scroll="paper">
      <DialogTitle>
        <span>{i18n.t("addParticipantsInternalGroupModal.title.up")}</span>

        <br /><br/>

        <TextField
          autoFocus
          fullWidth

          type="search"
          placeholder={i18n.t("addParticipantsInternalGroupModal.searchPlaceholder")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: "gray" }} />
              </InputAdornment>
            ),
          }}

          value={searchParam}
          onChange={handleSearch}
        />
      </DialogTitle>

      <DialogContent className={classes.dialogContent} dividers>
        {!loading && open && usersList.length > 0 && renderParticipantsCardList()}

        {loading && (
          <div className={classes.circularProgressContainer}>
            <CircularProgress size={24} className={classes.buttonProgress} />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          color="inherit"
          variant="outlined"
          className={classes.floatingButton}
          onClick={handleClose}
          disabled={loading}
        >
          {i18n.t("addParticipantsInternalGroupModal.buttons.cancel")}
        </Button>

        <Button
          color="primary"
          variant="contained"
          className={classes.floatingButton}
          onClick={handleAddParticipants}
        >
          {i18n.t("addParticipantsInternalGroupModal.buttons.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddParticipantsInternalGroupModal;
