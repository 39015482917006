import { dateFormatterConstructor, numberFormatterConstructor } from "../intl/formatters";

export const encodeZeroFloatDataToMissing = ({ data, dataKeys }) => {  
  if (
    data.row.section === "body"
    && dataKeys.includes(data.column.dataKey)
    && data.cell.raw === "0.00"
  ) {
    data.cell.text = ["-"];
  }
};

export const encodeEmptyStringDataToMissing = ({ data, dataKeys }) => {
  if (
    data.row.section === "body"
    && dataKeys.includes(data.column.dataKey)
    && [null, undefined].includes(data.cell.raw)
  ) {
    data.cell.text = ["-"];
  }
};

export const formatDate = ({ data, dataKeys }) => {
  const dateFormatter = dateFormatterConstructor({ locale: localStorage.getItem("i18nextLng") });
  
  if (
    data.row.section === "body"
    && dataKeys.includes(data.column.dataKey)
  ) {
    data.cell.text = [dateFormatter.format(new Date(`${data.cell.raw}T00:00:00`))];
  }
};

export const formatNumberData = ({ data, dataKeys }) => {
  const numberFormatter = numberFormatterConstructor({
    locale: localStorage.getItem("i18nextLng")
    , style: "decimal"
    , minimumFractionDigits: 2
    , maximumFractionDigits: 2
  });

  if (
    data.row.section === "body"
    && dataKeys.includes(data.column.dataKey)
    && numberFormatter.format(data.cell.text) !== "NaN"
  ) {
    data.cell.text = [numberFormatter.format(data.cell.text)]
  }
};