import React, { useState, useEffect, useContext, useReducer } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles, Paper } from "@material-ui/core";
import clsx from "clsx";

import { AuthContext } from "../../context/Auth/AuthContext";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import api from "../../services/api";
import ContactDrawer from "../ContactDrawer";
import DragDropModal from "../DragDropModal";
import FileContextModal from "../FileContextModal";
import ViewOnceFileContextModal from "../ViewOnceFileContextModal";
import ImageToStickerContextModal from "../ImageToStickerContextModal";
import MessageInput from "../MessageInput/";
import MessagesList from "../MessagesList";
import SocketTicket from "../../context/Socket/Listeners/SocketTicket";
import TicketActionButtons from "../TicketActionButtons";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import toastError from "../../errors/toastError";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 0 0 5px",
    borderRadius:"20px",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: { height: "90vh", },
  },

  ticketInfo: {
    borderRadius:"20px",
    backgroundColor: theme.palette.background.default,
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: { maxWidth: "80%", flexBasis: "80%", },
  },

  ticketActionButtons: {
    borderRadius:"20px",
    backgroundColor: theme.palette.background.default,
    flexBasis: "100%",
    display: "flex",    
    [theme.breakpoints.down("900px")]: { maxWidth: "100%", flexBasis: "100%", marginBottom: "5px", },
  },

  mainWrapper: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    borderRadius:"20px",
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderRadius:"20px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) state[messageIndex] = message;
      else newMessages.push(message);
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) state[messageIndex] = newMessage;
    else state.push(newMessage);

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);
    if (messageIndex !== -1) state[messageIndex] = messageToUpdate;
    return [...state];
  }

  if (action.type === "RESET") { return []; }
};

const Ticket = ({ setTabOpen, setAudioPlayer, setCurrentPlayingAudioTime }) => {
  //  ***************
  //  ** Varialbes **
  //  ***************
  const { ticketId } = useParams();
  window.selectedTicketId = ticketId;

  const history = useHistory();
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [messagesList, dispatch] = useReducer(reducer, []);

  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const [fileContextModalOpen, setFileContextModalOpen] = useState(false);
  const [medias, setMedias] = useState([]);

  const [viewOnceFileContextModalOpen, setViewOnceFileContextModalOpen] = useState(false);
  const [viewOnceMedia, setViewOnceMedia] = useState(null);

  const [imageToStickerContextModalOpen, setImageToStickerContextModalOpen] = useState(false);
  const [imageToSticker, setImageToSticker] = useState(null);
  

  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [lastSeenTimestamp, setLastSeenTimestamp] = useState(null);
  const [lastKnownPresenceCode, setLastKnownPresenceCode] = useState(0);
  
  const [isForwarding, setIsForwarding] = useState(false);
  const [selectedForwardMessages, setSelectedForwardMessages] = useState([]);

  const [lastMessage, setLastMessage] = useState(null);

  const [botAnswerDelay, setBotAnswerDelay] = useState(false);

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data: ticket } = await api.get("/tickets/" + ticketId);

          if (ticket.type === 0) {
            await api.head(`/presenceSubscribe/${ticket.contactId}`, {
              params: { whatsappId: ticket.whatsappId }
            });
          }

          handleBack(ticket.userId, ticket.userIdInternalChat, ticket.ticketUsers, ticket.type, ticket.status);
          setContact(ticket.contact);
          setTicket(ticket);
          setLoading(false);
          setIsForwarding(false);

          setLastSeenTimestamp(null);
          setLastKnownPresenceCode(0);
        } catch (error) {
          setLoading(false);
          console.log("Ticket Use Effect Error:", error);
          toastError(error);
        }
      };
      fetchTicket();
    }, 500);

    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId, history]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleBack = (ticketUserId, ticketUserIdInternalChat, ticketUsers, ticketType, ticketStatus) => {
    const cannotUserAccessGeneralTicket = ticketStatus !== "group_closed" &&
      ticketType !== 4 &&
      ticketUserId !== user.id &&
      ticketUserIdInternalChat !== user.id &&
      ticketUserId > 0 &&
      user.profile === "user";

    const cannotUserAccessInternalGroupTicket = (ticketStatus === "group_closed") ||
      (
        ![null, undefined].includes(ticketUsers) &&
        ticketUsers?.length > 0 &&
        ticketUsers?.every(ticketUser => ticketUser.userId !== user.id)
      );

    const cannotUserAccessTicket = cannotUserAccessGeneralTicket || cannotUserAccessInternalGroupTicket;

    if (cannotUserAccessTicket) history.push("/tickets");
  };

  const handleDrawerOpen = () => { setDrawerOpen(true); };
	const handleDrawerClose = () => { setDrawerOpen(false); };

  const handleOpenFileContextModal = (medias) => {
    setMedias(medias);
    setFileContextModalOpen(true);
  };

  const handleCloseFileContextModal = () => { setFileContextModalOpen(false); };

  const handleOpenViewOnceFileContextModal = (media) => {
    setViewOnceMedia(media);
    setViewOnceFileContextModalOpen(true);
  };

  const handleCloseViewOnceFileContextModal = () => { setViewOnceFileContextModalOpen(false); };

  const handleOpenImageToStickerContextModal = (images) => {
    setImageToSticker(images);
    setImageToStickerContextModalOpen(true);
  };

  const handleCloseImageToStickerContextModal = () => { setImageToStickerContextModalOpen(false); };


  //  ************
  //  ** Return **
  //  ************
  return (
    <>
    <SocketTicket
      handleBack={handleBack}
      history={history}
      setContact={setContact}
      setLastKnownPresenceCode={setLastKnownPresenceCode}
      setLastSeenTimestamp={setLastSeenTimestamp}
      setTicket={setTicket}
      ticketId={window.selectedTicketId}
    />

    {/* 
      ***************************************
      ** Drag-Drop and File Context Modals **
      ***************************************
    */}
    {ticket.type !== 2 && (
      <>
        {(
          (ticket.type === 0 && contact && !contact.isBlocked && !contact.isInactive)
          || ([1, 3, 4].includes(ticket.type))
        ) && (
          <DragDropModal handleOpenFileContextModal={handleOpenFileContextModal} />
        )}
        
        <FileContextModal
          open={fileContextModalOpen} 
          onClose={handleCloseFileContextModal}
          medias={medias}
          setMedias={setMedias}
        />

        <ImageToStickerContextModal 
          open={imageToStickerContextModalOpen}
          onClose={handleCloseImageToStickerContextModal}
          imageToSticker={imageToSticker}
          setImageToSticker={setImageToSticker}
          ticketId={ticketId}
        />

        <ViewOnceFileContextModal
          open={viewOnceFileContextModalOpen}
          onClose={handleCloseViewOnceFileContextModal}
          media={viewOnceMedia}
          setMedia={setViewOnceMedia}
        />
      </>
    )}



    {/* 
      *************
      ** Content **
      *************
    */}
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {[classes.mainWrapperShift]: drawerOpen})}
      >
        <TicketHeader loading={loading}> 
          <div className={classes.ticketInfo}>
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={handleDrawerOpen}
              lastKnownPresenceCode={lastKnownPresenceCode}
	            lastSeenTimestamp={lastSeenTimestamp}
            />
          </div>

          <div className={classes.ticketActionButtons}>
            <TicketActionButtons ticket={ticket} setTabOpen={setTabOpen} messagesListDispatch={dispatch} />
          </div>
        </TicketHeader>

        <ReplyMessageProvider>
          <MessagesList
            ticketId={ticketId}
            ticketType={ticket.type}
            ticketUsers={ticket.ticketUsers}
            ticketStatus={ticket.status}
            isGroup={ticket.isGroup}
            isForwarding={isForwarding}
            updateIsForwarding={setIsForwarding}
            selectedForwardMessages={selectedForwardMessages}
            updateSelectedForwardMessages={setSelectedForwardMessages}
            setAudioPlayer={setAudioPlayer}
            setCurrentPlayingAudioTime={setCurrentPlayingAudioTime}
            setLastMessage={setLastMessage}
            whatsappId={ticket.whatsappId}
            isContactBlocked={contact ? contact.isBlocked : false}
            isContactInactive={contact ? contact.isContactInactive : false}
            botAnswerDelay={botAnswerDelay}
            setTabOpen={setTabOpen}
            messagesList={messagesList}
            dispatch={dispatch}
          />

          {
            (
              !(ticket.status !== "open" && user.profile === "user") 
              || ticket.status === "group"
            )
            && (ticket.type !== 0 || (contact && !["WhatsApp Official ✔️", "Meta AI"].includes(contact.name)))
            && (
              <MessageInput 
                ticket={ticket}
                ticketStatus={ticket.status}
                isForwarding={isForwarding}
                updateIsForwarding={setIsForwarding}
                selectedForwardMessages={selectedForwardMessages}
                updateSelectedForwardMessages={setSelectedForwardMessages}
                ticketType={ticket.type}
                lastMessage={lastMessage}
                fileContextModalOpen={fileContextModalOpen}
                handleOpenFileContextModal={handleOpenFileContextModal}
                viewOnceMedia={viewOnceMedia}
                handleOpenViewOnceFileContextModal={handleOpenViewOnceFileContextModal}
                imageToStickerContextModalOpen={imageToStickerContextModalOpen}
                handleOpenImageToStickerContextModal={handleOpenImageToStickerContextModal}
                isContactModalOpen={isContactModalOpen}
                isContactBlocked={contact ? contact.isBlocked : false}
                isContactInactive={contact ? contact.isInactive : false}
                isContactChannel={contact ? contact.isChannel : false}
                setBotAnswerDelay={setBotAnswerDelay}
                ticketReadOnly={ticket.readOnly}
                whatsappId={ticket.whatsappId}
              />
            )
          }
        </ReplyMessageProvider>
      </Paper>

      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        ticket={ticket}
        loading={loading}
        setIsContactModalOpen={setIsContactModalOpen}
      />
    </div>
    </>
  );
};

export default Ticket;
