import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Checkbox,
	Chip,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	chips: { display: "flex", flexWrap: "wrap" },

	chip: {
		margin: 2,
		border: `2px solid ${theme.palette.primary.main}`,
		color: theme.palette.text.primary,
	},

	chipCheckbox: {
		color: theme.palette.primary.main,
		'&.Mui-checked': { color: theme.palette.primary.main }
	},

	selectInputField: {
    "& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary, },
    "& .MuiSvgIcon-root": { color: theme.palette.text.primary, },
	},
}));

const UserSelect = ({ selectedUserIds, onChange, usersOptions, styleSelectInputField=true, selectedItemsDisplayLimit = 10 }) => {
	// 	***************
	// 	** Variables **
	// 	***************
  const classes = useStyles();
	const [users, setUsers] = useState([]);



	// 	*****************
	// 	** Use Effects **
	// 	*****************
  useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/AllUsers");
				setUsers(data.users);
			} catch (error) {
				console.log("User Select Use Effect Error:", error);
				toastError(error);
			}
		})();
	}, []);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleChange = event => { onChange(event.target.value); };



	// 	************
	// 	** Return **
	// 	************
  return (
		<div>
			<FormControl
				fullWidth
				margin="dense"
				variant="outlined"
				classes={styleSelectInputField ? { root: classes.selectInputField } : { }}
			>
				<InputLabel>{i18n.t("userSelect.inputLabel")}</InputLabel>
				<Select
					multiple
					label={i18n.t("userSelect.inputLabel")}
					value={selectedUserIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: { vertical: "bottom", horizontal: "left" },
						transformOrigin: { vertical: "top", horizontal: "left" },
						getContentAnchorEl: null,
					}}
					renderValue={selected => {
						const displayedOptions = selected.slice(0, selectedItemsDisplayLimit);
						const remainingToDisplay = selected.length - selectedItemsDisplayLimit;

						if (displayedOptions?.length === 0) return <></>;

						return (
							<div className={classes.chips}>
								{displayedOptions.map(id => {
									const user = users.find(u => u.id === id);
									return user
										? <Chip key={id} variant="outlined" label={user.name} className={classes.chip} />
										: null;
								})}

								{remainingToDisplay > 0 && (
									<Chip variant="outlined" label={`+${remainingToDisplay} ${i18n.t("userSelect.inputLabel")}`} className={classes.chip} />
								)}
							</div>
						);
					}}
				>
					{usersOptions.map(user => (
						<MenuItem key={user.id} value={user.id}>
							<Checkbox className={classes.chipCheckbox} checked={selectedUserIds.indexOf(user.id) > -1} />
              <ListItemText primary={user.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default UserSelect;