import React from "react";
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	root: { display: "flex", flexWrap: "wrap", },
  dialog: { backdropFilter: "blur(5px)", },
  participantNames: { fontSize: "1.10em", },
}));


const ViewParticipantsModal = ({ open, onClose, participantsToView }) => {
  //	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();



	//	***************
  // 	** Functions **
	//	***************
  const handleClose = () => { onClose(); };




	//	************
  // 	** Return **
	//	************
	return (
		<div className={classes.root}>
      <Dialog
				maxWidth="sm"
				scroll="paper"
				fullWidth
				open={open}
				onClose={handleClose}
        className={classes.dialog}
			>
        <DialogTitle>
				  {i18n.t("viewParticipantsModal.title")}
				</DialogTitle>

        <DialogContent divider>
          <span className={classes.participantNames}>
            {participantsToView?.map(participant => participant.user.name).join(", ")}
          </span>
        </DialogContent>
      </Dialog>
    </div>
	);
};

export default ViewParticipantsModal;
