import React, { createContext, useState } from "react";

const ModalImageCorsContext = createContext();

const ModalImageCorsProvider = ({ children }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [ticketId, setTicketId] = useState(null);
  const [replyMessage, setReplyMessage] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);



  //  ***************
  //  ** Functions **
  //  ***************
  const openImageModalCors = (imageUrl, ticketId, replyMessage = false, isDeleted = false) => {
    setImageUrl(imageUrl);
    setTicketId(ticketId);
    setReplyMessage(replyMessage);
    setIsDeleted(isDeleted);
    setOpen(true);
  };

  const closeImageModalCors = () => {
    setOpen(false);
    setImageUrl(null);
    setTicketId(null);
    setReplyMessage(false);
    setIsDeleted(false);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <ModalImageCorsContext.Provider
      value={{
        open, imageUrl, ticketId, replyMessage, isDeleted,
        openImageModalCors, closeImageModalCors
      }}>
      {children}
    </ModalImageCorsContext.Provider>
  );
};

export { ModalImageCorsContext, ModalImageCorsProvider };
