import { useContext, useEffect } from "react";

import { AuthContext } from "../../../Auth/AuthContext";
import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketTicket = ({
  handleBack,
  history,
  setContact,
  setLastKnownPresenceCode,
  setLastSeenTimestamp,
  setTicket,
  ticketId
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { user } = useContext(AuthContext);
  const { getSocket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      if (ticketId) socket.emit("joinTicket", ticketId);

      const handleTicketUpdate = (data) => {
        if (data.ticketId.toString() === ticketId.toString() && `${api.defaults.headers.tenantId}` === `${data.tenantId}`) {
          handleBack(data.userId, data.userIdInternalChat, null, null, null);
        }
      };
  
      const handleTicketPresence = (data) => {
        if (data.action === "update" && ticketId === data.ticketId && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          setLastKnownPresenceCode(data.lastKnownPresenceCode);
          setLastSeenTimestamp(data.lastSeenTimestamp === null ? null : parseInt(data.lastSeenTimestamp, 10));
        }
      };
  
      const handleTicket = (data) => {
        if (data.action === "updateTicket" && data.ticket.id.toString() === ticketId.toString() && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          setTicket(data.ticket);
        }
  
        if (data.action === "deleteTicketDatabase" && data.ticketId.toString() === ticketId.toString() && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          history.push("/tickets");
        }

        if (data.action === "handleBackAllUsersFromTicket" && `${ticketId}` === `${data.ticketId}` && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          history.push("/tickets");
        }

        if (data.action === "handleBackSingleUserFromTicket" && `${ticketId}` === `${data.ticketId}` && `${user.id}` === `${data.userId}` && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          history.push("/tickets");
        }
      };
  
      const handleContact = (data) => {
        if (data.action === "update" && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          setContact((prevState) => {
            if (prevState !== null && prevState.id === data.contact?.id) {
              return { ...prevState, ...data.contact };
            }
            return prevState;
          });
        }
      };
  
      socket.on("ticketUpdate", handleTicketUpdate);
      socket.on("ticketPresence", handleTicketPresence);
      socket.on("ticket", handleTicket);
      socket.on("contact", handleContact);
  
      return () => {
        socket.off("ticketUpdate", handleTicketUpdate);
        socket.off("ticketPresence", handleTicketPresence);
        socket.off("ticket", handleTicket);
        socket.off("contact", handleContact);
      };
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleBack, history, setContact, setLastKnownPresenceCode, setLastSeenTimestamp, setTicket, ticketId]);


  
  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketTicket;