import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import { writeBodyInfo } from "./writers";

export const documentGenerator = ({
  documentProperties: {
    orientation = "p"
    , measure = "mm"
    , dimensions = "a4"
    , autoPrint = false
    , variant ="non-conform"
  }
  , period: { initialDate, finalDate }
}) => {
  const document = new jsPDF(orientation, measure, dimensions);
  if (autoPrint) document.autoPrint({ variant }); // 'non-conform' (default) or 'javascript' to activate different methods of automatic printing when opening in a PDF-viewer

  const headerInitialPeriod = new Date(`${initialDate}T00:00:00`);
  const headerFinalPeriod = new Date(`${finalDate}T00:00:00`);
  const creationDateTime = document.getCreationDate().split(":")[1].substring(0, 14);
  const footerCreationDateTimeText = `${creationDateTime.substring(0, 4)}-${creationDateTime.substring(4, 6)}-${creationDateTime.substring(6, 8)} ${creationDateTime.substring(8, 10)}:${creationDateTime.substring(10, 12)}:${creationDateTime.substring(12)}`;

  return { document, headerInitialPeriod, headerFinalPeriod, footerCreationDateTimeText };
};

export const tableGenerator = ({
  document
  , title: { text, positionX, positionY, fontSize }
  , table: { tableId, columns, rows, columnStyles, startY, foot, options }
}) => {
  writeBodyInfo({ document, text, positionX, positionY, fontSize });
  autoTable(document, { tableId, head: columns, body: rows, startY, foot, columnStyles, ...options });
};