import React, { useCallback, useEffect, useState } from "react";
import { Avatar, CardHeader, makeStyles } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import BezinhoImg from '../../assets/bezinho.svg';
import internalChatImg from "../../assets/internalChat.png";
import internalGroupImg from "../../assets/internalGroup.png";
import truncateString from "../../utils/truncateString";
import toastError from "../../errors/toastError";
import WhatsappOfficialImg from "../../assets/whatsappOfficial.jpg";

const useStyles = makeStyles(theme => ({
	internalChatHeaderContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "1em",
	},

	customBadge: {
		border: `1px solid ${theme.palette.primary.main}`,
		color: theme.palette.text.primary,
	
		padding: "3px",
		paddingLeft: "10px",
		paddingRight: "10px",
		borderRadius: "15px",
		fontSize: "10px",
		width: "fit-content",
		display: "flex",
		gap: "3px",
		alignItems: "center",
	},
}));

const TicketInfo = ({
	contact,
	ticket,
	onClick,
	lastKnownPresenceCode = 0,
	lastSeenTimestamp = null
}) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const [isLastSeenDateToday, setIsLastSeenDateToday] = useState(false);
	const [presenceMessage, setPresenceMessage] = useState("");
	const [viewPresenceUpdateSetting, setViewPresenceUpdateSetting] = useState("disabled");



	// 	***************
	// 	** Callbacks **
	// 	***************
	const checkIsLastSeenDateToday = useCallback(() => {
		const lastSeenDate = new Date(lastSeenTimestamp);
		const lastSeenDay = lastSeenDate.getDate();
		const lastSeenMonth = lastSeenDate.getMonth();
		const lastSeenYear = lastSeenDate.getFullYear();

		const currentDate = new Date();
		const currentDay = currentDate.getDate();
		const currentMonth = currentDate.getMonth();
		const currentYear = currentDate.getFullYear();

		const isLastSeenDateToday = currentDay === lastSeenDay
			&& currentMonth === lastSeenMonth
			&& currentYear === lastSeenYear;

		return isLastSeenDateToday;
	}, [lastSeenTimestamp]);

	const formatLastSeenPresenceMessage = useCallback((isTodayCheck) => {
		const lastSeenDate = new Date(lastSeenTimestamp);
			
		const hours = lastSeenDate.getHours().toString().padStart(2, "0");
		const minutes = lastSeenDate.getMinutes().toString().padStart(2, "0");
		const day = lastSeenDate.getDate().toString().padStart(2, "0");
		const month = (lastSeenDate.getMonth() + 1).toString().padStart(2, "0");
		const year = lastSeenDate.getFullYear().toString().slice(-2);

		return isTodayCheck
			? `${hours}:${minutes}`
			: `${hours}:${minutes} ${day}/${month}/${year}`;
	}, [lastSeenTimestamp]);



	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		// ***---- Fetch Settings ----***
		const fetchSettings = async () => {
			try {
				const apiCalls = [api.get("/settings/viewPresenceUpdate")];
				const [ViewPresenceUpdateResponse] = await Promise.all(apiCalls);
				setViewPresenceUpdateSetting(ViewPresenceUpdateResponse.data.value); // getting "viewPresenceUpdate" setting
			} catch (exception) {
				console.log("Ticket Info Use Effect Exception:", exception);
				toastError(exception);
			}
		};

		fetchSettings();



		// ***---- Setting Presence Message ----***
		if (lastKnownPresenceCode === 1) {
			setPresenceMessage(i18n.t("ticketInfo.composing"));
			setIsLastSeenDateToday(false);
		}

		else if (lastKnownPresenceCode === 2) {
			setPresenceMessage(i18n.t("ticketInfo.recording"));
			setIsLastSeenDateToday(false);
		}

		else if (lastSeenTimestamp !== null) {
			const isTodayCheck = checkIsLastSeenDateToday();
			const formattedDate = formatLastSeenPresenceMessage(isTodayCheck);
			setIsLastSeenDateToday(isTodayCheck);
			setPresenceMessage(formattedDate);
		}

		else {
			setPresenceMessage(" ");
			setIsLastSeenDateToday(false);
		}
	}, [
		checkIsLastSeenDateToday,
		formatLastSeenPresenceMessage,
		lastKnownPresenceCode,
		lastSeenTimestamp
	]);






	// 	************
	// 	** Return **
	// 	************
	return (
		<>
		{ticket.type === 0 && viewPresenceUpdateSetting === "enabled" && (
			<CardHeader
				onClick={onClick}
				style={{ cursor: "pointer" }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={contact?.name === "WhatsApp Official ✔️" ? WhatsappOfficialImg : contact?.profilePicUrl} alt="contact_image" />}
				title={`${truncateString(contact.name, 27)} #${ticket.id}`}
				subheader={
					lastSeenTimestamp === null
						? `${presenceMessage}`
						: `${isLastSeenDateToday ? i18n.t("ticketInfo.lastSeenToday") : i18n.t("ticketInfo.lastSeenAt")} ${presenceMessage}`
				}
			/>
		)}

		{ticket.type === 0 && viewPresenceUpdateSetting === "disabled" && (
			<CardHeader
				onClick={onClick}
				style={{ cursor: "pointer" }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={contact?.name === "WhatsApp Official ✔️" ? WhatsappOfficialImg : contact?.profilePicUrl} alt="contact_image" />}
				title={`${truncateString(contact.name, 27)} #${ticket.id}`}
			/>
		)}

		{ticket.type === 1 && (
			<CardHeader
				onClick={onClick}
				style={{ cursor: "pointer" }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={contact?.profilePicUrl} alt="contact_image" />}
				title={`${truncateString(ticket.noteName, 27)} #${ticket.id}`}
			/>
		)}

		{ticket.type === 2 && (
			<CardHeader
				onClick={onClick}
				style={{ cursor: "pointer" }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={BezinhoImg} alt="contact_image" />}
				title={`${truncateString(ticket.noteName, 27)} #${ticket.id}`}
			/>
		)}

		{ticket.type === 3 && (
			<CardHeader
				onClick={onClick}
				style={{ cursor: "pointer" }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={internalChatImg} alt="contact_image" />}
				title={
					<div className={classes.internalChatHeaderContainer}>
						{truncateString(ticket.noteName, 27)} #{ticket.id}
						<span className={classes.customBadge}>{ticket.user?.name} || {ticket.userInternalChat?.name}</span>
					</div>
				}
			/>
		)}

		{ticket.type === 4 && (
			<CardHeader
				onClick={onClick}
				style={{ cursor: "pointer" }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={internalGroupImg} alt="contact_image" />}
				title={`${truncateString(ticket.noteName, 27)} #${ticket.id}`}
			/>
		)}
		</>		
	);
};

export default TicketInfo;
