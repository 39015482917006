import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem } from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import getTicketsListAwaitingTimeFlag from "../../utils/getTicketsListAwaitingTimeFlag";
import ReopenTicketModal from "../ReopenTicketModal";
import toastError from "../../errors/toastError";

const TicketOptionsContextMenu = ({
  ticket, ticketsList, timeAwaitingFlag, awaitingTimeSetting, transferTicketModalOpen,
  editTicketModalOpen, menuOpen, handleClose, anchorEl, setTabOpen
}) => {
  //  ****************
  //  ** Variables  **
  //  ****************
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [openReopenTicketModal, setOpenReopenTicketModal] = useState(false);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenReopenTicketModal = () => {
    setOpenReopenTicketModal(true);
  };

  const handleCloseReopenTicketModal = () => {
    setOpenReopenTicketModal(false);
    handleClose();
  };

  const handleUpdateTicketStatus = async (status, userId) => {
    if (status === "open") {
      const { data: forceAcceptSetting } = await api.get("/settings/forceAcceptLongAwaitingTimeTickets");
      const ticketsListFlags = getTicketsListAwaitingTimeFlag(ticketsList, awaitingTimeSetting, true);

      //  - setting is "enabled"
      //  - ticket is not awaiting for so much time
      //  - there are pending tickets awaiting for long time
      //  - the user is forced to accept long awaiting time tickets
      if (
        forceAcceptSetting.value === "enabled"
        && !timeAwaitingFlag 
        && ticketsListFlags.includes(true)
        && user.forceAcceptLongAwaitingTimeTickets
      ) {
        toast.info(i18n.t("backendErrors.ERR_FORCE_ACCEPT_LONG_AWAITING_TIME_TICKETS"));
        handleClose();
        return;
      }

      try {
        await api.put(`/tickets/${ticket.id}/1`, {
          status: status,
          userId: userId,
          directClosedById: null,
          isReturned: false,
          isTransfered: ticket.isTransfered,
          isBotInteractionFinished: true,
          updateLastActivityAt: true
        });

        history.push(`/tickets/${ticket.id}`);
        setTabOpen("open");
        localStorage.setItem("tabOpenTickets", "open");
      } catch (error) {
        console.log("Handle Update Ticket Status Error:", error);
        toastError(error);
      }
      finally { handleClose(); }
    }
    else {
      try {
        await api.put(`/tickets/${ticket.id}/0`, {
          status: status,
          userId: userId && status !== "closed" ? userId : null,
          directClosedById: userId && status === "closed" ? userId : null,
          isReturned: status !== "pending" ? false : true,
          isTransfered: status === "pending" ? false : ticket.isTransfered,
          isBotInteractionFinished: true,
          updateLastActivityAt: status === "pending"
        });

        if (status === "pending") {
          history.push("/tickets");
          setTabOpen("pending");
          localStorage.setItem("tabOpenTickets", "pending");
        } else {
          history.push("/tickets");
        }
      } catch (error) {
        console.log("Handle Update Ticket Status Error:", error);
        toastError(error);
      }
      finally { handleClose(); }
    }
	};

  const handleViewTicket = (id, status) => {
    handleClose();
		if (status === "open" || status === "pending" || status === "group" || status === "channel") {
			history.push(`/tickets/${id}?tab=open`);
		}
		else if (status === "closed") {
			history.push(`/tickets/${id}?tab=closed`);
		}
	};

  const handleTicketMarkStatus = (id, isMarkedAsUnread) => {    
    handleClose();    
    isMarkedAsUnread ? handleMarkTicketAsRead(id) : handleMarkTicketAsUnread(id);
  };

  const handleMarkTicketAsUnread = (id) => {
    try {
      api.put(`/ticketsSetAsUnread/${id}`, { ticketType: ticket.type });
    } catch (error) {
      console.log("Handle Mark Ticket As Unread Error:", error);
      toastError(error);
    }
  };

  const handleMarkTicketAsRead = (id) => {
    try {
      api.put(`/ticketsSetAsRead/${id}`, { ticketType: ticket.type });
    } catch(error) {
      console.log("Handle Mark Ticket As Read Error:", error);
      toastError(error);
    }
  };

  const handleTransfer = () => {
    handleClose();
    transferTicketModalOpen();
  };

  const handleEdit = () => {
    handleClose();
    editTicketModalOpen();
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <ReopenTicketModal
        modalOpen={openReopenTicketModal}
        onClose={handleCloseReopenTicketModal}
        setTabOpen={setTabOpen}
        ticket={ticket}
      />

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        transformOrigin={{vertical: "top", horizontal: "right"}}
        open={menuOpen}
        onClose={handleClose}
        onContextMenu={event => event.preventDefault()}
      >

        {/* ***---- Accept ----*** */}
        {ticket.status === "pending" && (
          <MenuItem onClick={() => {handleUpdateTicketStatus("open", user?.id)}}>
            {i18n.t("ticketOptionsContextMenu.accept")}
          </MenuItem>
        )}

        {/* ***---- View ----*** */}
        <MenuItem onClick={() => handleViewTicket(ticket.id, ticket.status)}>
          {i18n.t("ticketOptionsContextMenu.view")}
        </MenuItem>

        {/* ***---- Mark as Read/Unread ----*** */}
        {(ticket.status === "pending" || ticket.status === "open") && (
          <MenuItem onClick={
            () => {
              let isTicketMarkedAsUnread = ticket.type === 3 && ticket.userIdInternalChat === user.id
                ? ticket.isMarkedAsUnreadInternalChat
                : ticket.isMarkedAsUnread;

              let isTicketWithUnreadMessages = ticket.unreadMessages > 0;
              
              handleTicketMarkStatus(ticket.id, isTicketMarkedAsUnread || isTicketWithUnreadMessages);
            }
          }>
            {
              (ticket.type === 3 && ticket.userId === user.id && ticket.isMarkedAsUnread)
              || (ticket.type === 3 && ticket.userIdInternalChat === user.id && ticket.isMarkedAsUnreadInternalChat)
              || (ticket.type !== 3 && ticket.isMarkedAsUnread)
              || ticket.unreadMessages > 0
                ? i18n.t("ticketOptionsContextMenu.markAsRead")
                : i18n.t("ticketOptionsContextMenu.markAsUnread")
            }
          </MenuItem>
        )}

        {/* ***---- Transfer ----*** */}
        {ticket.status === "open" && ticket.type !== 3 && (
          <MenuItem onClick={handleTransfer}>
            {i18n.t("ticketOptionsContextMenu.transfer")}
          </MenuItem>
        )}

        {/* ***---- Edit ----*** */}
        {(ticket.status === "pending" || ticket.status === "open") && ticket.type !== 3 && (
          <MenuItem onClick={handleEdit}>
            {i18n.t("ticketOptionsContextMenu.edit")}
          </MenuItem>
        )}
        
        {/* ***---- Return ----*** */}
        {ticket.status === "open" && ticket.type !== 3 && (
          <MenuItem onClick={() => {handleUpdateTicketStatus("pending", null)}}>
            {i18n.t("ticketOptionsContextMenu.return")}
          </MenuItem>
        )}

        {/* ***---- Reopen ----*** */}
        {ticket.status === "closed" && ticket.type !== 3 && (
          <MenuItem onClick={handleOpenReopenTicketModal}>
            {i18n.t("ticketOptionsContextMenu.reopen")}
          </MenuItem>
        )}
        
        {/* ***---- Resolve (Pending) ----*** */}
        {ticket.status === "pending" && user.profile === "admin" && (
          <MenuItem onClick={() => {handleUpdateTicketStatus("closed", user?.id)}}>
            {i18n.t("ticketOptionsContextMenu.resolve")}
          </MenuItem>
        )}

        {/* ***---- Close ----*** */}
        <MenuItem onClick={handleClose}>
          {i18n.t("ticketOptionsContextMenu.close")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default TicketOptionsContextMenu;
