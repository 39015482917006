import { useCallback, useContext, useEffect } from "react";

import { AuthContext } from "../../../Auth/AuthContext";
import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketTicketsList = ({
  dispatch,
  filter,
  selectedQueueIds,
  selectedUserIds,
	selectedConnectionIds,
  status
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocketAuthenticated();
  const { user } = useContext(AuthContext);



	// 	***************
	// 	** Functions **
	// 	***************
	const shouldUpdateTicket = (ticket) => !filter &&
		(!ticket.userId || ticket.userId === user?.id || ticket.userIdInternalChat === user?.id || ticket.ticketUsers?.some(ticketUser => ticketUser.userId === user.id) || localStorage.getItem("showAllTickets") === "1") &&
		(!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1 || ticket.userId === user?.id) &&
		!(ticket.hasMessageFromPhone && user?.profile === "user" && ticket.userId !== user?.id && ticket.status === "open");

	const notBelongsToUserQueues = (ticket) =>
		(ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1 && ticket.userId !== user?.id);

	const notBelongsToUserWhatsapps = async (ticket) => {
		let ticketVisible;
		if (!ticket) return true;

		if ([1, 2, 3, 4].includes(ticket.type)) {
			ticketVisible = true;
		}
		else {
			const { data: { value: userPermissionsByChip } } = await api.get("/settings/userPermissionsByChip");

			ticketVisible = userPermissionsByChip === "disabled" || user.whatsapps === undefined
				? true
				: user.whatsapps.length === 0;

			if (!ticketVisible) {
				user.whatsapps?.forEach((whatsapp) => {
					if (whatsapp.id === ticket.whatsappId) {
						ticketVisible = true;
						return;
					}
				});
			}
		}

		return ticketVisible;
	}



	// 	***************
	// 	** Callbacks **
	// 	***************
	const checkFilteredIds = useCallback((filteredIdsArray, filteredProperty, data, shouldCheckAction) => {
		const shouldValidateParametersCondition = shouldCheckAction
			? data.action === "updateTicket" && data.ticket?.status === "open" && filteredIdsArray
			: data.ticket?.status === "open" && filteredIdsArray;

		if (shouldValidateParametersCondition) {
			const isFilteredPropertyExistentCondition = data.ticket[filteredProperty] !== null && data.ticket[filteredProperty] !== undefined;
			const isFilteredPropertyInexistentAndIsFilteredIdArrayEmptyCondition = (data.ticket[filteredProperty] === null || data.ticket[filteredProperty] === undefined) && filteredIdsArray.length === 0;

			if (isFilteredPropertyExistentCondition) {
				return filteredIdsArray.length === 0 || filteredIdsArray.includes(data.ticket[filteredProperty]);
			}

			else if (isFilteredPropertyInexistentAndIsFilteredIdArrayEmptyCondition) {
				return true;
			}

			else { return false; }
		}
		else { return true; }
	}, []);

	const checkShowAllTicketsSetting = useCallback((data, shouldCheckAction) => {
		if (shouldCheckAction) {
			return localStorage.getItem("showAllTickets") === "0" && data.ticket?.status === "open" && data.action === "updateTicket"
				? data.ticket.userId === user.id || data.ticket.userIdInternalChat === user.id
				: true;
		}
		else {
			return localStorage.getItem("showAllTickets") === "0" && data.ticket?.status === "open"
				? data.ticket.userId === user.id || data.ticket.userIdInternalChat === user.id
				: true;
		}		
	}, [user]);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
		const socket = getSocket();

		if (socket) {
			const handleTicket = async (data) => {
				if (data.status && data.status !== status) return;
	
				if (data.action === "updateUnreadTicket" && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
					dispatch({ type: "RESET_UNREAD", payload: data.ticketId });
				}
	
				const userIdsFilterCondition = checkFilteredIds(selectedUserIds, "userId", data, true);
				const connectionIdsFilterCondition = checkFilteredIds(selectedConnectionIds, "whatsappId", data, true);
				const showAllTicketsCondition = checkShowAllTicketsSetting(data, true);
				const noTicketPermissionByUser = await notBelongsToUserWhatsapps(data.ticket);

				if (data.action === "updateTicket" && userIdsFilterCondition && connectionIdsFilterCondition && showAllTicketsCondition && shouldUpdateTicket(data.ticket) && (noTicketPermissionByUser) && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
					dispatch({ type: "UPDATE_TICKET", payload: data.ticket });
				}
	
				if (data.action === "updateTicket" && data.ticket && notBelongsToUserQueues(data.ticket) && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
					dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
				}
	
				if (data.action === "deleteTicket" && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
					dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
				}

				if (data.action === "deleteTicketForSingleUser" && user.id === data.userId && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
					dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
				}
			};
	
			const handleAppMessage = async (data) => {
				if (data.status && data.status !== status) return;
	
				const userIdsFilterCondition = checkFilteredIds(selectedUserIds, "userId", data, false);
				const connectionIdsFilterCondition = checkFilteredIds(selectedConnectionIds, "whatsappId", data, false);
				const showAllTicketsCondition = checkShowAllTicketsSetting(data, false);
				const noTicketPermissionByUser = await notBelongsToUserWhatsapps(data.ticket);

				if ((data.action === "createAppMessageTenant") && userIdsFilterCondition && connectionIdsFilterCondition && showAllTicketsCondition && shouldUpdateTicket(data.ticket) && (noTicketPermissionByUser) && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
					dispatch({ type: "UPDATE_TICKET_UNREAD_MESSAGES", payload: data.ticket });
				}
			};
	
			socket.on("ticket", handleTicket);
			socket.on("appMessage", handleAppMessage);
	
			return () => {
				socket.off("ticket", handleTicket);
				socket.off("appMessage", handleAppMessage);
			};
		}
		

    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, filter, selectedQueueIds, selectedUserIds, selectedConnectionIds, status]);



  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketTicketsList;