import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import { i18n } from "../../translate/i18n";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import NoDataImg from '../../assets/noData.svg';
import Title from "../../components/Title";
import useInternalGroupTicketsDashboardTable from "../../hooks/useInternalGroupTicketsDashboardTable";
import ViewParticipantsModal from "../../components/ViewParticipantsModal";
import ViewTicketModal from "../../components/ViewTicketModal";

const useStyles = makeStyles(theme => ({
  viewTicket: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100px",
    maxWidth: "100px",
    minWidth: "100px",
    alignItems: "center",
    gap: "2px",
  },
  
  viewButton: { cursor: "pointer", borderRadius: "50%", },
  
  mainContainerScroll: { overflowY: "scroll", ...theme.scrollbarStyles, paddingBottom: "20px", },

  container: { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), },

  flexContainer: {
    display: "flex",
    justifyContent: "right",
    alignItems: "baseline",
    gap: "15px",
    paddingRight: "10px",
  },

  customFixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    height: 120,
    transition: 'transform 0.3s',
    position: "relative",
    '&:hover': { filter: "brightness(0.85)", transform: 'translateY(-5px)', },
  },

  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down('sm')]: { justifyContent: "center", gap: "10px", },
  },

  cardItem: {
    width: "100%",
  },

  ticketsTable: {
    // scroll
    "& .MuiDataGrid-virtualScroller": { ...theme.scrollbarStyles, },

    // header
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      fontSize: 16,
    },

    // rows
    "& .MuiDataGrid-cell": { color: theme.palette.text.primary, },

    // pair rows
    "& .MuiDataGrid-virtualScrollerRenderZone": {
      "& .MuiDataGrid-row": {
        "&:nth-child(2n)": { backgroundColor: theme.palette.background.paper, }
      }
    },

    // buttons
    "& .MuiButtonBase-root": { color: theme.palette.text.primary, },

    // checkboxes - not checked
    "& [data-testId='CheckBoxOutlineBlankIcon']": { color: theme.palette.text.primary, },

    // checkboxes - checked
    "& [data-testId='CheckBoxIcon']": { color: theme.palette.primary.main, },

    // checkbox - header (unselect all)
    "& [data-testId='IndeterminateCheckBoxIcon']": { color: theme.palette.primary.main, },

    // footer - selected rows
    "& .MuiDataGrid-footerContainer": { color: theme.palette.text.primary, },

    // footer - pagination
    "& .MuiTablePagination-root": { color: theme.palette.text.primary, },
  },

  emptyBoxContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "50px",
  },

  emptyBoxImage: { width: "250px", height: "250px", },

  emptyBoxSpan: { fontSize: "20px", },

  dashboardContainer: {
    width: "100%",
    height: "400px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    padding: "20px 0px 20px 0px",
    textIndent: "15px",
  },

  actionButton: { "&:hover": { color: theme.palette.primary.main, }, },
}));

const InternalGroupsDashboard = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  const [viewTicketModalOpen, setViewTicketModalOpen] = useState(false);
  const [ticketId, setTicketId] = useState(null);

  const [viewParticipantsModalOpen, setViewParticipantsModalOpen] = useState(false);
  const [participantsToView, setParticipantsToView] = useState(null);

  let tickets = 0;
  let structuredTickets = 0;
  let viewTickets = 0;



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    if (month < 10) { month = `0${month.toString()}`; }
    if (day < 10) { day = `0${day.toString()}`; }

    setInitialDate(`${year}-${month}-${day}`);
    setFinalDate(`${year}-${month}-${day}`);
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenViewTicketModal = (ticketId) => {
    setViewTicketModalOpen(true);
    setTicketId(ticketId);
  };

  const handleCloseViewTicketModal = () => {
    setViewTicketModalOpen(false);
    setTicketId(null);
  };

  const handleOpenViewParticipantsModal = (participantsToView) => {
    setViewParticipantsModalOpen(true);
    setParticipantsToView(participantsToView);
  };

  const handleCloseViewParticipantsModal = () => {
    setViewParticipantsModalOpen(false);
    setParticipantsToView(null);
  };

  const handleDateFilter = (newDate, flagInitialDate) => {
    const dashboardInitialDate = document.getElementById("dashboardInitialDate");
    const dashboardFinalDate = document.getElementById("dashboardFinalDate");

    if (dashboardInitialDate.value === "" || dashboardFinalDate.value === "") {
      dashboardInitialDate.value = initialDate;
      dashboardFinalDate.value = finalDate;
    }
    else if (dashboardInitialDate.value > dashboardFinalDate.value) {
      toast.info(i18n.t("backendErrors.DASHBOARD_INITIAL_DATE_AFTER_FINAL_DATE"));
    }
    else if (flagInitialDate) {
      setInitialDate(newDate.target.value);
    }
    else {
      setFinalDate(newDate.target.value);
    }
  };

  const handleChangeInitialDate = (newDate) => { handleDateFilter(newDate, true); };
  
  const handleChangeFinalDate = (newDate) => { handleDateFilter(newDate, false); };

  const GetClosedInternalGroupTickets = (initialDate, finalDate) => {
    const structuredInitialDate = initialDate ? `${initialDate}T00:00:00` : "2022-12-29T00:00:00";
    const structuredFinalDate = finalDate ? `${finalDate}T23:59:59` : "2022-12-29T23:59:59";

    const tickets = useInternalGroupTicketsDashboardTable({
      initialDate: structuredInitialDate,
      finalDate: structuredFinalDate
    });

    return tickets;
  };



  //  **********************
  //  ** Table Data Query **
  //  **********************
  tickets = GetClosedInternalGroupTickets(initialDate, finalDate);

  structuredTickets = tickets === 0 ? 0 : tickets.map(ticket => {
    const { id, noteName, protocol, createdAt, isClosedAt, ticketUsers } = ticket;
    const formattedCreatedAt = createdAt ? new Date(createdAt).toLocaleDateString("pt-BR") : null;
    const formattedClosedAt = isClosedAt ? new Date(isClosedAt).toLocaleDateString("pt-BR") : null;

    return {
      id,
      noteName,
      protocol,
      createdAt: formattedCreatedAt,
      isClosedAt: formattedClosedAt,
      ticketUsers
    };
  });

  viewTickets = structuredTickets === 0 ? 0 : structuredTickets;



  //  *********************
  //  ** Table Structure **
  //  *********************
  const columns = [
    {
      field: "id", type: "number", headerName: i18n.t("internalGroupTicketsDashboard.table.header.id"), width: 100, align: "center",
      renderCell: (params) => (
        <div className={classes.viewTicket}>
          <MenuItem
            className={`${classes.actionButton} ${classes.viewButton}`}
            onClick={() => {handleOpenViewTicketModal(params.value)}}
          >
            <VisibilityOutlinedIcon />
          </MenuItem>

          {params.value}
        </div>
      ),
    },

    { field: "noteName", type: "string", headerName: i18n.t("internalGroupTicketsDashboard.table.header.groupName"), width: 200, align: "center", },

    {
      field: "ticketUsers", type: "string", headerName: i18n.t("internalGroupTicketsDashboard.table.header.participants"), width: 200, align: "center",
      renderCell: (params) => (
        <div className={classes.viewTicket}>
          <MenuItem
            className={`${classes.actionButton} ${classes.viewButton}`}
            onClick={() => handleOpenViewParticipantsModal(params.value)}
          >
            <PeopleOutlineOutlinedIcon />
          </MenuItem>
        </div>
      )
    },

    {
      field: "protocol", type: "string", headerName: i18n.t("internalGroupTicketsDashboard.table.header.protocol"), width: 130, align: "center",
      valueGetter: (params) => { return params.value ? params.value : "-" },
    },

    { field: "createdAt", type: "date", headerName: i18n.t("internalGroupTicketsDashboard.table.header.createdAt"), width: 130, align: "center", },

    { field: "isClosedAt", type: "date", headerName: i18n.t("internalGroupTicketsDashboard.table.header.endedAt"), width: 130, align: "center", },
  ];



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <ViewTicketModal
        open={viewTicketModalOpen}
        onClose={handleCloseViewTicketModal}
        ticketId={ticketId}
      />
      
      <ViewParticipantsModal
        open={viewParticipantsModalOpen}
        onClose={handleCloseViewParticipantsModal}
        participantsToView={participantsToView}
      />

      <MainContainer>
        {/* 
          ***********
          ** Title **
          ***********
        */}
        <MainHeader>
          <Title>{i18n.t("internalGroupTicketsDashboard.title")}</Title>
        </MainHeader>



        {/* 
          ***************
          ** Container **
          ***************
        */}
        <div className={classes.mainContainerScroll}>
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              {/* 
                *****************
                ** Date Inputs **
                *****************
              */}
              <Grid item xs={12}>
                <div className={classes.flexContainer}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="date"
                    id="dashboardInitialDate"
                    name="dashboardInitialDate"
                    label={i18n.t("dashboard.fields.initialDate")}
                    value={initialDate}
                    onChange={handleChangeInitialDate}
                  />

                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="date"
                    id="dashboardFinalDate"
                    name="dashboardFinalDate"
                    label={i18n.t("dashboard.fields.finalDate")}
                    value={finalDate}
                    onChange={handleChangeFinalDate}
                  />
                </div>
              </Grid>

              {/* when there are datas over the selected period, the table is shown */}
              {(viewTickets !== 0 && viewTickets.length > 0) && (
                <>
                  {/* 
                    ***********
                    ** Cards **
                    ***********
                  */}
                  <Grid item xs={12} className={classes.sectionContainer}>
                    {/* 
                    
                      ***---- Number of Chats ----***

                    */}
                    <Grid className={classes.cardItem}>
                      <Paper className={classes.customFixedHeightPaper}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("internalGroupTicketsDashboard.amount")}
                        </Typography>

                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {viewTickets.length}
                          </Typography>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>



                  {/* 
                    ***********
                    ** Table **
                    ***********
                  */}
                  <Grid item xs={12}>
                    <div style={{ height: 500, width: "100%" }}>
                      <DataGrid
                        className={classes.ticketsTable}
                        rows={viewTickets}
                        columns={columns}
                        rowsPerPageOptions={[7, 25, 50, 100]}
                        checkboxSelection={true}
                        disableColumnMenu={false}
                      />
                    </div>
                  </Grid>
                </>
              )}

              {/* when there are no datas over the selected period, a message is shown */}
              {(viewTickets === 0 || viewTickets.length === 0) && (
                <Paper className={classes.dashboardContainer}>
                  <div className={classes.emptyBoxContainer}>
                    <img className={classes.emptyBoxImage} src={NoDataImg} alt="No Data" />
                    <span className={classes.emptyBoxSpan}>{i18n.t("dashboard.emptyBox")}</span>
                  </div>
                </Paper>
              )}
            </Grid>
          </Container>
        </div>
      </MainContainer>
    </>
  );
};

export default InternalGroupsDashboard;