import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardActionArea,
  CardContent,
	Dialog,
	DialogContent,
	DialogTitle,
  IconButton,
	makeStyles,
	TextField,
  Typography,
} from "@material-ui/core";
import { AccessTime, Done, DoneAll, Search, SyncOutlined } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import extractDayMonthYearFromStringDateTime from "../../utils/datetime/extractDayMonthYearFromStringDateTime";
import extractHourMinuteFromStringDateTime from "../../utils/datetime/extractHourMinuteFromStringDateTime";
import HighlightedText from "../HighlightedText";
import toastError from "../../errors/toastError";
import truncateStringWithKeywordFocus from "../../utils/truncateStringWithKeywordFocus";

const useStyles = makeStyles((theme) => ({
  //  ****************
  //  ** Animations **
  //  ****************
  "@keyframes rollSyncButton": {
    "0%": { transform: "rotate(0deg)", },
    "50%": { transform: "rotate(180deg)", },
    "100%": { transform: "rotate(360deg)", },
  },



  //  ****************
  //  ** Components **
  //  ****************
  searchMessageTitleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
  },

  searchMessageDialog: { userSelect: "none", },

  searchContainer: {
    width: "300px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "1em",
  },

  floatingButton: {
    transition: "transform 0.30s",
    "&:hover": { color: theme.palette.primary.main, transform: "translateY(-5px)", },
  },

  syncButton: {
    color: theme.palette.primary.main,
    animation: "$rollSyncButton 1.50s infinite",
    cursor: "default",
  },

  dialogContent: { overflowY: "scroll", ...theme.scrollbarStyles, },

  messageFromMeCard: { marginBottom: "1em", borderRadius: "20px", backgroundColor: theme.palette.primary.messageBox, },
  
  messageNotFromMeCard: { marginBottom: "1em", borderRadius: "20px", backgroundColor: theme.palette.background.paper, },

  messageDate: { fontSize: "13px", },

  messageAckAndBody: { display: "flex", flexDirection: "row", gap: "3px", },

  ackIcons: { fontSize: 18, verticalAlign: "middle", marginLeft: 4, },

  ackDoneAllIcon: { color: theme.palette.secondary.ackCheck, },

  messageBody: { fontSize: "16px", width: "calc(100% - 1.50em)", height: "100%", },
}));

const SearchMessageModal = ({ modalOpen, onClose, ticketId, whatsappId, messagesListDispatch }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [memorySearchParam, setMemorySearchParam] = useState("");

  const [messagesArray, setMessagesArray] = useState([]);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);



  //  ***************
  //  ** Functions **
  //  ***************

  // ***---- Close Modal ----***
  const handleClose = () => {
    if (loading) return;

    onClose();

    setHasMore(false);
    setPageNumber(1);
    setSearchParam("");
    setMemorySearchParam("");
    setMessagesArray([]);

    setConfirmationModalOpen(false);
    setSelectedMessage(null);
  };



  // ***---- Type on Search Text Field ----***
  const handleChangeSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") handleSearch();
  };

  const focusSearchTextField = () => {
    const searchTextField = document.getElementById("searchMessageModal-searchTextField");
    if (searchTextField) searchTextField.focus();
  };



  // ***---- Subcomponents ----***
  const renderMessageAck = (message) => {
    if (message.fromMe) {
      if (message.ack === 0) {
        return <AccessTime fontSize="small" className={classes.ackIcons} />;
      }

      if (message.ack === 1 || message.ack === 2) {
        return <Done fontSize="small" className={classes.ackIcons} />;
      }

      if (message.ack === 3) {
        return <DoneAll fontSize="small" className={classes.ackIcons} />;
      }

      if (message.ack === 4 || message.ack === 5) {
        return <DoneAll fontSize="small" className={`${classes.ackIcons} ${classes.ackDoneAllIcon}`} />;
      }
    }

    else {
      return <DoneAll fontSize="small" className={classes.ackIcons} />
    }
  };



  // ***---- Scroll ----***
  const handleScroll = (event) => {
    if (loading) return;

    if (!hasMore) {
      setPageNumber(1);
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) handleMoreSearch();
  };



  // ***---- Search Message ----***
  const handleSearch = async () => {
    const searchParamLength = searchParam.trim().length;

    if (searchParamLength < 3) {
      toast.info(i18n.t("searchMessageModal.toasts.smallLengthSearchParam"));
    }

    else {
      try {
        setLoading(true);
        setMessagesArray([]);
        setHasMore(false);
        setPageNumber(1);
        setMemorySearchParam(searchParam);
        setConfirmationModalOpen(false);
        setSelectedMessage(null);

        const { data } = await api.get("/searchMessages/", {
          params: { ticketId, whatsappId, searchParam, pageNumber }
        });

        setMessagesArray(data.messages);
        setHasMore(data.hasMore);

        setLoading(false);
        focusSearchTextField();
      } catch (exception) {
        setLoading(false);
        focusSearchTextField();

        setMessagesArray([]);
        setHasMore(false);
        setMemorySearchParam("");

        setConfirmationModalOpen(false);
        setSelectedMessage(null);

        console.log("Search Message Modal - Handle Search Exception");
        toastError(exception);
      }
    }
  };

  const handleMoreSearch = async () => {
    const memorySearchParamLength = memorySearchParam.trim().length;

    if (memorySearchParamLength < 3) {
      toast.info(i18n.t("searchMessageModal.toasts.smallLengthSearchParam"));
    }

    else {
      try {
        setLoading(true);

        const { data } = await api.get("/searchMessages/", {
          params: { ticketId, whatsappId, searchParam: memorySearchParam, pageNumber: pageNumber + 1 }
        });

        setPageNumber((previousState) => previousState + 1);
        setMessagesArray((previousValue) => [...previousValue, ...data.messages]);
        setHasMore(data.hasMore);

        setLoading(false);
        focusSearchTextField();
      } catch (exception) {
        setLoading(false);
        focusSearchTextField();

        setMessagesArray([]);
        setHasMore(false);
        setMemorySearchParam("");

        setConfirmationModalOpen(false);
        setSelectedMessage(null);

        console.log("Search Message Modal - Handle Search Exception");
        toastError(exception);
      }
    }
  };



  // ***---- Confirmation Modal ----***
  const handleOpenConfirmationModal = (messageId, messageBody) => {
    setSelectedMessage([messageId, messageBody]);
    setConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setSelectedMessage(null);
    setConfirmationModalOpen(false);
  };

  const handleFetchSelectedMessageHistory = async () => {
    try {
      setLoading(true);

      const selectedMessageId = selectedMessage[0];

      const { data } = await api.get("/searchSelectedMessageHistory", {
        params: { ticketId, whatsappId, selectedMessageId }
      });

      messagesListDispatch({ type: "LOAD_MESSAGES", payload: data.messages });

      setLoading(false);
      handleClose();

      window.location = `${window.location.href.split("#")[0]}#messageContainer-${selectedMessageId}`;
    } catch (exception) {
      setLoading(false);
      setSelectedMessage(null);

      console.log("Search Message Modal - Handle Fetch Selected Message History Exception");
      toastError(exception);
    }
  };


  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      {selectedMessage && (
        <ConfirmationModal
          title={i18n.t("searchMessageModal.confirmationModal.title")}
          open={confirmationModalOpen}
          onClose={handleCloseConfirmationModal}
          onConfirm={handleFetchSelectedMessageHistory}
        >
          <q>
            <HighlightedText
              keywords={memorySearchParam}
              text={truncateStringWithKeywordFocus(selectedMessage[1], memorySearchParam, 150)}
            />
          </q>
        </ConfirmationModal>
      )}
      

      <Dialog className={classes.searchMessageDialog} open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle className={classes.searchMessageTitleContainer}>
          {i18n.t("searchMessageModal.title")}

          <br /><br />

          <div className={classes.searchContainer}>
            <TextField
              autoFocus
              id="searchMessageModal-searchTextField"
              variant="outlined"
              type="search"

              disabled={loading}
              fullWidth={false}
              value={searchParam}
              onChange={handleChangeSearch}
              onKeyDown={handleKeyDown}

              placeholder={i18n.t("searchMessageModal.searchPlaceholder")}

              style={{ width: "calc(90% - 1em)" }}
            />

            {!loading ? (
              <IconButton color="inherit" className={classes.floatingButton} onClick={handleSearch} style={{ width: "5%" }}>
                <Search />
              </IconButton>
            ) : (
              <IconButton color="inherit" className={classes.syncButton} style={{ width: "5%" }} disableRipple>
                <SyncOutlined />
              </IconButton>
            )}          
          </div>
        </DialogTitle>

        <DialogContent className={classes.dialogContent} onScroll={handleScroll} dividers>
          {messagesArray.length > 0 && messagesArray.map((message) => (
            <Card className={message.fromMe ? classes.messageFromMeCard : classes.messageNotFromMeCard}>
              <CardActionArea onClick={() => handleOpenConfirmationModal(message.id, message.body)}>
                <CardContent>
                  <Typography className={classes.messageDate} gutterBottom>
                    {extractDayMonthYearFromStringDateTime(message.createdAt)} {extractHourMinuteFromStringDateTime(message.createdAt)}
                  </Typography>

                  <Typography className={classes.messageAckAndBody} gutterBottom>
                    <span>{renderMessageAck(message)}</span>
                    <span className={classes.messageBody}>
                      <HighlightedText
                        keywords={memorySearchParam}
                        text={truncateStringWithKeywordFocus(message.body, memorySearchParam, 27)}
                      />
                    </span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SearchMessageModal;
