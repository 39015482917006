import { useEffect, useState } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useInternalGroupTicketsDashboardTable = ({ initialDate, finalDate }) => {
  const [rows, setRows] = useState(0);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchClosedTickets = async () => {
        try {
          const { data } = await api.get("/listInternalGroupsTicketsTable", {
            params: { initialDate, finalDate }
          });

          setRows(data);
        } catch (error) {
          console.log("Use Internal Chat Tickets Dashboard Table:", error);
          toastError(error);
        }
      };

      fetchClosedTickets();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [initialDate, finalDate]);

  return rows;
};

export default useInternalGroupTicketsDashboardTable;