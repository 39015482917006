import millisecondsToTime from "./datetime/millisecondsToTime";
import { i18n } from "../translate/i18n";

function calculateTimeAwaitingFlag(ticket, awaitingTimeSetting) {
  const timeAwaiting = millisecondsToTime(new Date() - new Date(ticket.createdAt));
  let [timeAwaitingNumber, timeAwaitingMetric] = [parseInt(timeAwaiting.split(" ")[0]), timeAwaiting.split(" ")[1]];
  const secondMetrics = [i18n.t("ticketsList.timeAwaitSecond"), i18n.t("ticketsList.timeAwaitSecond") + "s"];
  const hourDayMetrics = [
    i18n.t("ticketsList.timeAwaitHour"), i18n.t("ticketsList.timeAwaitDay"),
    i18n.t("ticketsList.timeAwaitHour") + "s", i18n.t("ticketsList.timeAwaitDay") + "s"
  ];

  timeAwaitingNumber = hourDayMetrics.includes(timeAwaitingMetric) ? timeAwaitingNumber * 60 : timeAwaitingNumber;
  return timeAwaitingNumber >= awaitingTimeSetting && !secondMetrics.includes(timeAwaitingMetric);
};

export default calculateTimeAwaitingFlag;