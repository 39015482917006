import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketTicketUsers = ({ dispatch }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocketAuthenticated();



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleTicketUsers = (data) => {
        if ((data.action === "promoteDemote") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          dispatch({ type: "PROMOTE_DEMOTE_TICKET_USERS", payload: data.ticketUser });
        }

        if ((data.action === "remove") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          dispatch({ type: "DELETE_TICKET_USER", payload: +data.ticketUserId });
        }

        if ((data.action === "add") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          dispatch({ type: "ADD_TICKET_USERS", payload: data.ticketUsers });
        }
      };

      socket.on("ticketUsers", handleTicketUsers);

      return () => {
        socket.off("ticketUsers", handleTicketUsers);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);



  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketTicketUsers;
