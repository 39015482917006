import React, { useContext } from "react";
import Viewer from "react-viewer";

import { ModalImageCorsContext } from "../../context/ModalImageCors";


const ModalImageCors = () => {
	// 	***************
	// 	** Variables **
	// 	***************
	const { open, imageUrl, closeImageModalCors} = useContext(ModalImageCorsContext);



	// 	************
	// 	** Return **
	// 	************
	return (
		<Viewer
			downloadable={true}
			downloadInNewWindow={true}
			changeable={false}
			noResetZoomAfterChange={true}
			visible={open}
			zIndex={10000}
			images={[{ src: imageUrl, downloadUrl: imageUrl }]}
			onClose={closeImageModalCors}
			onMaskClick={closeImageModalCors}
		/>
	);
};

export default ModalImageCors;