//  *************
//  ** Headers **
//  *************
export const alignCenterAndMiddleHeader = ({ data }) => {
  data.cell.styles.valign = "middle";
  data.cell.styles.halign = "center";
};

export const alignRatingsReportHeader = ({ data }) => {
  data.cell.styles.valign = "middle";

  if ([0].includes(data.column.index)) data.cell.styles.halign = "left";
  else if ([1, 2].includes(data.column.index)) data.cell.styles.halign = "center";
};



//  *************
//  ** Footers **
//  *************
export const alignTicketsDataReportFooter = ({ data }) => {
  data.cell.styles.valign = "middle";
  
  if ([6].includes(data.column.index)) data.cell.styles.halign = "right";
};