import React, { useState, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ModeCommentOutlinedIcon from "@material-ui/icons/ModeCommentOutlined";
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import SearchIcon from "@material-ui/icons/Search";
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import { useSocketAuthenticated } from "../../context/Socket/SocketContext";
import api from "../../services/api";
import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import NewInternalChatModal from "../../components/NewInternalChatModal";
import NewInternalGroupModal from "../../components/NewInternalGroupModal";
import SocketUsers from "../../context/Socket/Listeners/SocketUsers";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import truncateString from "../../utils/truncateString";
import UserModal from "../../components/UserModal";

const reducer = (state, action) => {
  if (action.type === "LOAD_USERS") {
    const users = action.payload;
    const newUsers = [];

    users.forEach((user) => {
      const userIndex = state.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) { state[userIndex] = user; }
      else { newUsers.push(user); }
    });

    return [...state, ...newUsers];
  }

  if (action.type === "UPDATE_USERS") {
    const user = action.payload;
    const userIndex = state.findIndex((u) => u.id === user.id);

    if (userIndex !== -1) {
      state[userIndex] = user;
      return [...state];
    }
    else { return [user, ...state]; }
  }

  if (action.type === "DELETE_USER") {
    const userId = action.payload;
    const userIndex = state.findIndex((u) => u.id === userId);
    if (userIndex !== -1) { state.splice(userIndex, 1); }
    return [...state];
  }

  if (action.type === "RESET") { return []; }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  icon: { color: theme.palette.text.primary, },

  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  },

  actionButton: { "&:hover": { color: theme.palette.primary.main, }, },

  userLimitCountContainer: { padding: "5px", textAlign: "center", },

  userLimitCount: { fontSize: "1.25em", },

  usersList: {
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    height: "90%",
    [theme.breakpoints.down("sm")]: { maxHeight: "80%", },
  },

  cardOverviewContainer: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",

    display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		flexWrap: "wrap",
		gap: "25px",
    ...theme.scrollbarStyles,
  },

  userSlot: {
    border: `2px solid ${theme.palette.primary.pastel}`,
		borderRadius: "20px",
    padding: "5px",
    width: "250px",
		minHeight: "150px",
    maxHeight: "150px",
		transition: 'transform 0.3s',
		opacity: "0.90",

    display: "flex",
    flexDirection: "row",

		'&:hover': { transform: "translateY(-5px)", opacity: "1", },
  },

  profilePicContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    borderRadius: "20px 0px 0px 20px",
    padding: "10px",
  },

  profilePic: { backgroundColor: theme.palette.primary.pastel, },

  infoContainer: {
    width: "100%",
    borderRadius: "0px 20px 20px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  userInfoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "5px",

    width: "100%",
    height: "70%",

    borderRadius: "0px 20px 0px 0px",
  },

  userName: {
    fontSize: "1.25em",
    display: "flex",
    flexDirection: "row",
    gap: "5px",
  },

  userNumber: { fontSize: "1em", fontStyle: "italic", },

  userEmail: { fontSize: "1em", fontStyle: "italic", },

  actionButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "2px",

    width: "100%",
    height: "30%",
    borderRadius: "0px 0px 20px 0px",
  },

  // *** View Options ***
  viewOptions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1em",
    padding: "0px 1em 0px 0px",
    width: "100%",
  },

  viewOptionsSubContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",

    border: `2px solid ${theme.palette.text.primary}`,
    borderRadius: "5px",
    padding: "5px",
  },

  viewActiveOption: { color: theme.palette.primary.main, },

  viewOptionsDivider: { fontWeight: "bold", height: "100%", fontSize: "1.75em", },

  //  *******************************
  //  ** Online and Offline Status **
  //  *******************************
  onlineStatus: {
    backgroundColor: "green",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
  },

  onlineStatusCard: {
    backgroundColor: "green",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    position: "relative",
    left: "1em",
    top: "-1em",
  },

  offlineStatus: {
    backgroundColor: "gray",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
  },

  offlineStatusCard: {
    backgroundColor: "gray",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    position: "relative",
    left: "1em",
    top: "-1em",
  },
}));

const Users = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const { user: contextUser } = useContext(AuthContext);
  const { onlineUsers } = useSocketAuthenticated();
  const [users, dispatch] = useReducer(reducer, []);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  const [userLimitCount, setUserLimitCount] = useState(0);
  const [insertedUsersCount, setInsertedUsersCount] = useState(0);

  const [usersViewLayout, setUsersViewLayout] = useState(localStorage.getItem("usersViewLayout"));
  const [typeOfUser, setTypeOfUser] = useState(localStorage.getItem("usersViewType"));

  const [newInternalChatModalOpen, setNewInternalChatModalOpen] = useState(false);
  const [newInternalChatSelectedUserId, setNewInternalChatSelectedUserId] = useState(null);

  const [newInternalGroupModalOpen, setNewInternalGroupModalOpen] = useState(false);
  const [newInternalGroupSelectedUserId, setNewInternalGroupSelectedUserId] = useState(null);

  const [allowGroups, setAllowGroups] = useState("disabled");



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, typeOfUser]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam, pageNumber, typeOfUser },
          });
          dispatch({ type: "LOAD_USERS", payload: data.users });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (error) {
          console.log("Users Use Effect 1 Error:", error);
          toastError(error);
        }
      };
      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, typeOfUser]);

  useEffect(() => {
    const delayDebounceFN = setTimeout(() => {

      const fetches = async () => {
        const apiCalls = [api.get("/limitUserCount/"), api.get("/settings/allowGroups")];
        const [LimitUserCountResponse, AllowGroupsResponse] = await Promise.all(apiCalls);
        setUserLimitCount(LimitUserCountResponse.data[0].totalUsers);
        setInsertedUsersCount(LimitUserCountResponse.data[1]);
        setAllowGroups(AllowGroupsResponse.data.value);
      };

      fetches();
    });
    return () => clearTimeout(delayDebounceFN);
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(false);
  };

  const handleSearch = (event) => { setSearchParam(event.target.value.toLowerCase()); };

  const loadMore = () => { setPageNumber((prevState) => prevState + 1); };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setUserModalOpen(true);
  };

  const handleOpenInternalChatModal = (userId) => {
    setNewInternalChatModalOpen(true);
    setNewInternalChatSelectedUserId(userId);
  };

  const handleCloseInternalChatModal = () => {
    setNewInternalChatModalOpen(false);
    setNewInternalChatSelectedUserId(null);
  };

  const handleOpenInternalGroupModal = (userId) => {
    setNewInternalGroupModalOpen(true);
    setNewInternalGroupSelectedUserId(userId);
  };

  const handleCloseInternalGroupModal = () => {
    setNewInternalGroupModalOpen(false);
    setNewInternalGroupSelectedUserId(null);
  };

  const handleOpenDeleteUserModal = (user) => {
    setConfirmModalOpen(true);
    setDeletingUser(user);
  };

  const handleDeleteUser = async (userId) => {
    if (contextUser.id === userId) {
      toast.info(i18n.t("users.toasts.notDeleted"));
      return;
    }

    try {
      await api.delete(`/users/${userId}`);
      setInsertedUsersCount((prevValue) => prevValue - 1);
    } catch (error) {
      console.log("Handle Delete User Error:", error);
      toastError(error);
    }
    
    setDeletingUser(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) { loadMore(); }
  };

  const handleUsersView = (layout) => {
    localStorage.setItem("usersViewLayout", layout);
    setUsersViewLayout(layout);
  };

  const handleTypeOfUserView = (type) => {
    localStorage.setItem("usersViewType", type);
    setTypeOfUser(type);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <MainContainer>
      <SocketUsers dispatch={dispatch} />

      <ConfirmationModal
        title={
          deletingUser &&
          `${i18n.t("users.confirmationModal.deleteTitle")} ${
            deletingUser.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteUser(deletingUser.id)}
      >
        {i18n.t("users.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      <NewInternalChatModal
        modalOpen={newInternalChatModalOpen}
        onClose={handleCloseInternalChatModal}
        internalChatSelectedUserId={newInternalChatSelectedUserId}
      />
      
      <NewInternalGroupModal
        modalOpen={newInternalGroupModalOpen}
        onClose={handleCloseInternalGroupModal}
        internalGroupSelectedUserId={newInternalGroupSelectedUserId}
      />

      <UserModal
        open={userModalOpen}
        onClose={handleCloseUserModal}
        updateRegisteredUsersCount={setInsertedUsersCount}
        aria-labelledby="form-dialog-title"
        userId={selectedUser && selectedUser.id}
      />

      <MainHeader>
        <Title>{i18n.t("users.title")}</Title>

        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("users.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />

          {contextUser.profile === "admin" && contextUser.configEnabled && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenUserModal}
              className={classes.floatingButton}
            >
              {i18n.t("users.buttons.add")}
            </Button>
          )}
        </MainHeaderButtonsWrapper>
      </MainHeader>

      <Paper className={classes.mainPaper} variant="outlined">
        {/* 
          *****************
          ** Users Count **
          *****************
        */}
        {contextUser.profile === "admin" && (
          <>
            <div className={classes.userLimitCountContainer}>
              <Typography className={classes.userLimitCount}><b>{insertedUsersCount}</b> {i18n.t("users.usedUsersCount.outOf")} {userLimitCount} {i18n.t("users.usedUsersCount.users")}</Typography>
            </div>
            <br />
          </>
        )}

        {/* 
          ******************
          ** View Options **
          ******************
        */}
        <div className={classes.viewOptions}>
          {/* 
            ***********************
            ** Type of User View **
            ***********************          
          */}
          <div className={classes.viewOptionsSubContainer}>
            <Tooltip title={i18n.t("users.tooltips.bothLayout")} placement="top-start" arrow>
              <IconButton
                className={`${classes.icon} ${classes.actionButton}`}
                size="small"
                onClick={() => handleTypeOfUserView("0")}
              >
                <PersonOutlinedIcon className={typeOfUser === "0" ? `${classes.viewActiveOption}` : ``} />
                <GroupOutlinedIcon className={typeOfUser === "0" ? `${classes.viewActiveOption}` : ``} />
              </IconButton>
            </Tooltip>

            <span className={classes.viewOptionsDivider}>|</span>

            <Tooltip title={i18n.t("users.tooltips.adminsLayout")} placement="top-start" arrow>
              <IconButton
                className={classes.icon}
                size="small"
                onClick={() => handleTypeOfUserView("1")}
              >
                <PersonOutlinedIcon className={typeOfUser === "1" ? `${classes.viewActiveOption} ${classes.actionButton}` : `${classes.actionButton}`} />
              </IconButton>
            </Tooltip>

            <span className={classes.viewOptionsDivider}>|</span>

            <Tooltip title={i18n.t("users.tooltips.usersLayout")} placement="top-start" arrow>
              <IconButton
                className={classes.icon}
                size="small"
                onClick={() => handleTypeOfUserView("2")}
              >
                <GroupOutlinedIcon className={typeOfUser === "2" ? `${classes.viewActiveOption} ${classes.actionButton}` : `${classes.actionButton}`} />
              </IconButton>
            </Tooltip>
          </div>



          {/* 
            *****************
            ** Layout View **
            *****************          
          */}
          <div className={classes.viewOptionsSubContainer}>
            <Tooltip title={i18n.t("users.tooltips.listLayout")} placement="top-start" arrow>
              <IconButton
                className={classes.icon}
                size="small"
                onClick={() => handleUsersView("list")}
              >
                <ViewHeadlineIcon className={usersViewLayout === "list" ? `${classes.viewActiveOption} ${classes.actionButton}` : `${classes.actionButton}`} />
              </IconButton>
            </Tooltip>

            <span className={classes.viewOptionsDivider}>|</span>

            <Tooltip title={i18n.t("users.tooltips.cardLayout")} placement="top-start" arrow>
              <IconButton
                className={classes.icon}
                size="small"
                onClick={() => handleUsersView("card")}
              >
                <ViewModuleIcon className={usersViewLayout === "card" ? `${classes.viewActiveOption} ${classes.actionButton}` : `${classes.actionButton}`} />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <br />

        <div className={classes.usersList} onScroll={handleScroll}>
          {/* 
            ***************
            ** List View **
            ***************
          */}
          {usersViewLayout === "list" && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="3%"></TableCell>
                  <TableCell align="center">{i18n.t("users.table.name")}</TableCell>
                  <TableCell align="center">{i18n.t("users.table.email")}</TableCell>
                  <TableCell align="center">{i18n.t("users.table.profile")}</TableCell>
                  <TableCell align="center">{i18n.t("users.table.whatsapp")}</TableCell>
                  <TableCell align="center">{i18n.t("users.table.actions")}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <>
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell align="center"><div className={onlineUsers.some(item => item.userId === user.id) ? classes.onlineStatus : classes.offlineStatus}></div></TableCell>
                      <TableCell align="center">{user.name}</TableCell>
                      <TableCell align="center">{user.email}</TableCell>
                      <TableCell align="center">{i18n.t(`userModal.form.${user.profile}`)}</TableCell>
                      <TableCell align="center">{user.whatsapp?.name}</TableCell>

                      <TableCell align="center">
                        {
                          (
                            (contextUser.profile === "admin" && contextUser.configEnabled && (!user.isOwner || (user.isOwner && contextUser.id === user.id)))
                            || (contextUser.profile === "user" && contextUser.id === user.id)
                          )
                          && (
                            <IconButton
                              className={classes.icon}
                              size="small"
                              onClick={() => handleEditUser(user)}
                            >
                              <EditIcon className={classes.actionButton} />
                            </IconButton>
                          )
                        }

                        {user.id !== contextUser.id && (
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenInternalChatModal(user.id)}
                          >
                            <ModeCommentOutlinedIcon className={classes.actionButton} />
                          </IconButton>
                        )}

                        {user.id !== contextUser.id && allowGroups === "enabled" && (
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenInternalGroupModal(user.id)}
                          >
                            <QuestionAnswerOutlinedIcon className={classes.actionButton} />
                          </IconButton>
                        )}

                        {
                          contextUser.profile === "admin"
                          && contextUser.configEnabled
                          && contextUser.id !== user.id
                          && !user.isOwner
                          && (
                            <IconButton
                              className={classes.icon}
                              size="small"
                              onClick={() => handleOpenDeleteUserModal(user)}
                            >
                              <DeleteOutlineIcon className={classes.actionButton} />
                            </IconButton>
                          )
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton columns={6} />}
                </>
              </TableBody>
            </Table>
          )}



          {/* 
            ***************
            ** Card View **
            ***************
          */}
          {usersViewLayout === "card" && (
            <div className={classes.cardOverviewContainer}>
              {users.length > 0 && users.map((user) => (
                <div className={classes.userSlot}>

                  {/* Profile Picture */}
                  <div className={classes.profilePicContainer}>
                    <Avatar className={classes.profilePic}>{user.name.substring(0, 1).toUpperCase()}</Avatar>
                    <div className={onlineUsers.some(item => item.userId === user.id) ? classes.onlineStatusCard : classes.offlineStatusCard}></div>
                  </div>

                  {/* Info Container */}
                  <div className={classes.infoContainer}>

                    {/* User Info */}
                    <div className={classes.userInfoContainer}>
                      <Typography className={classes.userName}>
                        {user.profile === "admin" && (
                          <Tooltip title={i18n.t("users.tooltips.admin")} placement="top-start" arrow>
                            <VpnKeyIcon className={classes.actionButton} />
                          </Tooltip>
                        )}
                        <b>{user.name}</b>
                      </Typography>
                      <Typography className={classes.userNumber}>{user.number}</Typography>
                      <Typography className={classes.userEmail}>{truncateString(user.email, 23)}</Typography>
                    </div>

                    {/* Action Buttons */}
                    <div className={classes.actionButtonsContainer}>
                      {
                        (
                          (contextUser.profile === "admin" && contextUser.configEnabled && (!user.isOwner || (user.isOwner && contextUser.id === user.id)))
                          || (contextUser.profile === "user" && contextUser.id === user.id)
                        )
                        && (
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleEditUser(user)}
                          >
                            <EditIcon className={classes.actionButton} />
                          </IconButton>
                        )
                      }

                      {user.id !== contextUser.id && (
                        <IconButton
                          className={classes.icon}
                          size="small"
                          onClick={() => handleOpenInternalChatModal(user.id)}
                        >
                          <ModeCommentOutlinedIcon className={classes.actionButton} />
                        </IconButton>
                      )}

                      {user.id !== contextUser.id && allowGroups === "enabled" && (
                        <IconButton
                          className={classes.icon}
                          size="small"
                          onClick={() => handleOpenInternalGroupModal(user.id)}
                        >
                          <QuestionAnswerOutlinedIcon className={classes.actionButton} />
                        </IconButton>
                      )}

                      {
                        contextUser.profile === "admin"
                        && contextUser.configEnabled
                        && contextUser.id !== user.id
                        && !user.isOwner
                        && (
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenDeleteUserModal(user)}
                          >
                            <DeleteOutlineIcon className={classes.actionButton} />
                          </IconButton>
                        )
                      }
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Paper>
    </MainContainer>
  );
};

export default Users;
