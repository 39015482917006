export const dateFormatterConstructor = ({ locale }) => {
  return new Intl.DateTimeFormat(locale);
};

export const dateTimeFormatterConstructor = ({ locale, dateStyle, timeStyle }) => {
  return new Intl.DateTimeFormat(locale, { dateStyle, timeStyle });
};

export const numberFormatterConstructor = ({ locale, style, minimumFractionDigits, maximumFractionDigits }) => {
  return new Intl.NumberFormat(locale, { style, minimumFractionDigits, maximumFractionDigits });
};