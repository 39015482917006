import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import BusinessHours from "../pages/BusinessHours/";
import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import AttendantsDashboard from "../pages/AttendantsDashboard/";
import TicketsDashboard from "../pages/TicketsDashboard/";
import RatingsDashboard from "../pages/RatingsDashboard";
import GroupsDashboard from "../pages/GroupsDashboard";
import InternalChatsDashboard from "../pages/InternalChatsDashboard";
import Tickets from "../pages/Tickets/";
import Login from "../pages/Login/";
import LoginGroup from "../pages/LoginGroup/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Queues from "../pages/Queues/";
import Categories from "../pages/Categories/";
import Subqueues from "../pages/Subqueues/";
import Profile from "../pages/Profile/";
import Flow from "../pages/Flow/";
import Labels from "../pages/Labels/";
import StarredMessages from "../pages/StarredMessages/";
import DispatchManager from "../pages/DispatchManager";
import AutomaticMessages from "../pages/AutomaticMessages";
import InternalGroupsDashboard from "../pages/InternalGroupsDashboard";
import Page404 from "../pages/Page404/";

import { AuthProvider } from "../context/Auth/AuthContext";
import { ModalImageCorsProvider } from "../context/ModalImageCors";
import { SocketAuthenticatedProvider, SocketLoginProvider, SocketProvider } from "../context/Socket/SocketContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import ModalImageCors from "../components/ModalImageCors";

import Route from "./Route";

const Routes = () => {
  console.log("- Application Init!!");

  return (
    <BrowserRouter>
      <SocketProvider>
        <SocketLoginProvider>
          <AuthProvider>
            <Switch>
              <Route exact path="/login" component={Login} />          
              <Route exact path="/loginGroup" component={LoginGroup} />
              <WhatsAppsProvider>
                <SocketAuthenticatedProvider>
                  <LoggedInLayout>
                    <ModalImageCorsProvider>
                      <ModalImageCors />
                      <Route exact path="/" component={Tickets} isPrivate />
                      <Route exact path="/dashboard" component={Dashboard} isPrivate />  
                      <Route exact path="/attendantsDashboard" component={AttendantsDashboard} isPrivate />  
                      <Route exact path="/ticketsDashboard" component={TicketsDashboard} isPrivate />              
                      <Route exact path="/ratingsDashboard" component={RatingsDashboard} isPrivate />
                      <Route exact path="/groupsDashboard" component={GroupsDashboard} isPrivate />
                      <Route exact path="/internalChatsDashboard" component={InternalChatsDashboard} isPrivate />
                      <Route exact path="/internalGroupsDashboard" component={InternalGroupsDashboard} isPrivate />
                      <Route exact path="/tickets/:ticketId?" component={Tickets} isPrivate />
                      <Route exact path="/connections" component={Connections} isPrivate />
                      <Route exact path="/contacts" component={Contacts} isPrivate />
                      <Route exact path="/labels" component={Labels} isPrivate />
                      <Route exact path="/users" component={Users} isPrivate />
                      <Route exact path="/automaticMessages" component={AutomaticMessages} isPrivate />
                      <Route exact path="/quickAnswers" component={QuickAnswers} isPrivate />
                      <Route exact path="/starredMessages" component={StarredMessages} isPrivate />
                      <Route exact path="/MyProfile" component={Profile} isPrivate />
                      <Route exact path="/Settings" component={Settings} isPrivate />
                      <Route exact path="/Queues" component={Queues} isPrivate />
                      <Route exact path="/Categories" component={Categories} isPrivate />
                      <Route exact path="/Subqueues" component={Subqueues} isPrivate />
                      <Route exact path="/Flow" component={Flow} isPrivate />
                      <Route exact path="/BusinessHours" component={BusinessHours} isPrivate />
                      <Route exact path="/dispatchManager" component={DispatchManager} isPrivate />
                      <Route exact path="/404" component={Page404} isPrivate />
                    </ModalImageCorsProvider>
                  </LoggedInLayout>
                </SocketAuthenticatedProvider>
              </WhatsAppsProvider>
            </Switch>
            <ToastContainer autoClose={3000} theme={"light"} />
          </AuthProvider>
        </SocketLoginProvider>
      </SocketProvider>
    </BrowserRouter>
  );
};

export default Routes;
