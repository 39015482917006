import { i18n } from "../../translate/i18n";

export const writeTitle = ({ document, text, positionX, positionY, fontSize }) => {
  const pageWidth = document.internal.pageSize.width;

  document.setFillColor(240, 240, 240);
  document.rect(10, 2.75, pageWidth - 20, 10, "DF");
  document.setFontSize(fontSize);
  document.setFont("helvetica", "bold");
  document.text(text, positionX, positionY, { align: "center" });
};

export const writeHeaderInfo = ({ document, text1, positionX1, positionY1, text2, positionX2, positionY2, fontSize }) => {
  document.setFontSize(fontSize);
  document.setFont("helvetica", "bold");
  document.text(text1, positionX1, positionY1);
  document.setFont("helvetica", "normal");
  document.text(text2, positionX2, positionY2);
};

export const writeBodyInfo = ({ document, text, positionX, positionY, fontSize }) => {
  document.setFontSize(fontSize);
  document.setFont("helvetica", "bold");
  document.text(text, positionX, positionY);
};

export const writeStatisticsCaption = ({ document, positionX, positionY, fontSize }) => {
  const secondaryPositionX = positionX + 5;
  const secondaryPositionY = positionY + 5;

  const captions = [
    {
      variable: i18n.t("report.statistics.variables.mean")
      , explanation: i18n.t("report.statistics.caption.mean")
    }
    , {
      variable: i18n.t("report.statistics.variables.standardDeviation")
      , explanation: i18n.t("report.statistics.caption.standardDeviation")
    }
    , {
      variable: i18n.t("report.statistics.variables.range")
      , explanation: i18n.t("report.statistics.caption.range")
    }
    , {
      variable: i18n.t("report.statistics.variables.firstQuartile")
      , explanation: i18n.t("report.statistics.caption.firstQuartile")
    }
    , {
      variable: i18n.t("report.statistics.variables.secondQuartile")
      , explanation: i18n.t("report.statistics.caption.secondQuartile")
    }
    , {
      variable: i18n.t("report.statistics.variables.thirdQuartile")
      , explanation: i18n.t("report.statistics.caption.thirdQuartile")
    }
    , {
      variable: i18n.t("report.statistics.variables.fourthQuartile")
      , explanation: i18n.t("report.statistics.caption.fourthQuartile")
    }
  ];

  writeBodyInfo({ document, text: i18n.t("report.statistics.caption.title"), positionX, positionY, fontSize });

  document.setFontSize(fontSize);

  captions.forEach((captionDictionary, index) => {
    document.setFont("helvetica", "normal");
    document.text(
      `- ${captionDictionary.variable}: ${captionDictionary.explanation}`
      , secondaryPositionX
      , secondaryPositionY + index * 5
    );
  });
};