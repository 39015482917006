import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import useQueues from "../../hooks/useQueues";
import useUserQueues from "../../hooks/useUserQueues";
import useSubqueues from "../../hooks/useSubqueues";

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
	floatingButton: {
    transition: 'transform 0.3s',
  
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  }
}));

const EditTicketModal = ({ modalOpen, onClose, ticket, ticketStatus }) => {
	//	***************
	//	** Variables **
	//	***************
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	
	const [queues, setQueues] = useState([]);
	const [selectedQueue, setSelectedQueue] = useState('');
	const { findAll: findAllQueues } = useQueues();
	const { findAll: findAllUserQueues } = useUserQueues();

	const [subqueues, setSubqueues] = useState(0);
	const [selectedSubqueue, setSelectedSubqueue] = useState('');
	const { findAll: findAllSubqueues } = useSubqueues();



	//	*****************
	//	** Use Effects **
	//	*****************
	useEffect(() => {
		const loadQueues = async () => {
			const list = ticketStatus === "pending" ? await findAllQueues() : await findAllUserQueues();
			setQueues(list);
		}

		loadQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	//	***************
	//	** Functions **
	//	***************
	const handleQueueChange = (e) => {
		setSelectedQueue(e.target.value);
		setSelectedSubqueue("");

		const loadSubqueues = async () => {
			const list = await findAllSubqueues();
			setSubqueues(list);
		}

		loadSubqueues();
	};

	const handleClose = () => { 
		onClose(); 
		setSelectedQueue("");
		setSelectedSubqueue("");
	};

	const handleEditTicket = async e => {
		e.preventDefault();
		
		// Queue not selected >> toast message
		if (selectedQueue === "") { toast.info(i18n.t("backendErrors.QUEUE_NOT_SELECTED")); }

		// Queue's Related Subqueues not loaded yet >> toast message
		else if (subqueues === 0) {
			toast.info(i18n.t("editTicketModal.toast.subqueuesNotLoaded"));
		}

		// Queue has related subqueues and any subqueue has been selected >> toast message
		else if (subqueues.some(subqueue => subqueue.queueId === selectedQueue) && selectedSubqueue === "") {
			toast.info(i18n.t("backendErrors.SUBQUEUE_NOT_SELECTED"));
		}

		// Queue and Related Subqueue selected >> close ticket
		else {
			setLoading(true);
			try {
				let data = {};
				data.queueId = selectedQueue;
				data.subqueueId = selectedSubqueue !== "" ? selectedSubqueue : null;
	
				await api.put(`/tickets/${ticket.id}/1`, data);
	
				setLoading(false);
				history.push(`/tickets`);
				selectedQueue("");
				selectedSubqueue("");
			}
			catch (err) {
				setLoading(false);
				toast.error(err);
			}
			finally {
				handleClose();
			}
		}
	};



	//	************
	//	** Return **
	//	************
	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form style={{padding: "0px 15px 15px 15px"}} onSubmit={handleEditTicket}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("editTicketModal.title")}
				</DialogTitle>

				<DialogContent dividers>
					<FormControl variant="outlined" className={classes.maxWidth}>
						<InputLabel>{i18n.t("editTicketModal.fieldQueueLabel")}</InputLabel>
						<Select
							value={selectedQueue}
							onChange={(e) => handleQueueChange(e)}
							label={i18n.t("editTicketModal.fieldQueuePlaceholder")}
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{queues.map((queue) => (<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>))}
						</Select>
					</FormControl>

					{selectedQueue !== "" && subqueues !== 0 && subqueues.some(subqueue => subqueue.queueId === selectedQueue) && (
						<>
							<br /><br />
							<FormControl variant="outlined" className={classes.maxWidth}>
								<InputLabel>{i18n.t("editTicketModal.fieldSubqueueLabel")}</InputLabel>
						
								<Select 
									value={selectedSubqueue}
									onChange={(e) => setSelectedSubqueue(e.target.value)}
									label={i18n.t("editTicketModal.fieldSubqueuePlaceholder")}
								>
									<MenuItem value={''}>&nbsp;</MenuItem>

									{subqueues.map((subqueue) => (
										subqueue.queueId === selectedQueue && (
											<MenuItem key={subqueue.id} value={subqueue.id}>{subqueue.name}</MenuItem>
										)
									))}
								</Select>
							</FormControl>
						</>
					)} 
				</DialogContent>

				<br />

				<DialogActions>
					<Button
						onClick={handleClose}
						color="inherit"
						disabled={loading}
						variant="outlined"
						className={classes.floatingButton}
					>
						{i18n.t("editTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
						className={classes.floatingButton}
					>
						{i18n.t("editTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default EditTicketModal;