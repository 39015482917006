import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import formatPhoneNumberBaileys from "../../utils/formatPhoneNumberBaileys";

const useStyles = makeStyles(theme => ({
	infoSection: {
		display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
		gap: "5px",
	},

	root: { display: "flex", flexWrap: "wrap", },

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": { marginRight: theme.spacing(1), },
	},

	btnWrapper: { position: "relative", },

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	floatingButton: {
		transition: "transform 0.30s",
		"&:hover": { transform: "translateY(-5px)", },
	},

  label: {
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    color: "#f5f5ff",
    fontSize: "1.1em",
  },
}));

const WhatsAppInfoModal = ({ open, onClose, whatsAppId }) => {
  //  ***************
  //  ** Variables **
  //  ***************
	const classes = useStyles();
  const [whatsAppInfo, setWhatsAppInfo] = useState(null);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (whatsAppId) {
      const delayDebounceFN = setTimeout(() => {
        const fetchWhatsAppInfo = async () => {
          const { data } = await api.get(`/whatsappInfo/${whatsAppId}`); //
          setWhatsAppInfo(data);
        };
        fetchWhatsAppInfo();
      });
      return () => clearTimeout(delayDebounceFN); 
    }
  }, [whatsAppId]);

	

  //  ***************
  //  ** Functions **
  //  ***************
	const handleClose = () => { onClose(); };



  //  ************
  //  ** Return **
  //  ************
	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="paper"
			>
				<DialogTitle>
          {i18n.t("whatsappInfoModal.title")}
				</DialogTitle>

        <DialogContent dividers>
					{whatsAppInfo && (
            <>
            <span className={classes.label}>{i18n.t("whatsappInfoModal.datas.whatsAppUserName")} {capitalizeFirstLetter(whatsAppInfo.name)}</span>
            <br /><br />
            <span className={classes.label}>{i18n.t("whatsappInfoModal.datas.phoneNumber")} {formatPhoneNumberBaileys(whatsAppInfo.id)}</span>
            </>
          )} 
        </DialogContent>
			</Dialog>
		</div>
	);
};

export default WhatsAppInfoModal;
