import removeSignatureFromBody from "./removeSignatureFromBody";

function textToSpeech(text) {
  if (window.speechSynthesis?.speaking) {
    window.speechSynthesis?.cancel();
  }
  else {
    const utterance = new SpeechSynthesisUtterance(removeSignatureFromBody(text));

    utterance.lang = "pt-BR"; // uses BCP 47 format: https://www.techonthenet.com/js/language_tags.php
    utterance.pitch = 1; // pitch, from 0 to 2
    utterance.rate = 1; // speed, from 0.1 and 10
    utterance.volume = 1; // volume, from 0 to 1

    window.speechSynthesis?.speak(utterance);
  }
};

function listVoices() {
  const voices = window.speechSynthesis?.getVoices();
  voices.forEach(voice => console.log(voice));
}

export default textToSpeech;