import React, { useContext, useEffect, useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const InternalGroupParticipantsContextMenu = ({ open, onClose, anchorEl, selectedParticipant, allParticipants }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { user } = useContext(AuthContext);

  const [isPromoteOptionAvailable, setIsPromoteOptionAvailable] = useState(false);
  const [isDemoteOptionAvailable, setIsDemoteOptionAvailable] = useState(false);
  const [isRemoveOptionAvailable, setIsRemoveOptionAvailable] = useState(false);
  
  const [areThereAvailableOptions, setAreThereAvailableOptions] = useState(false);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (open && ![null, undefined].includes(selectedParticipant)) {
      /* 
        - Promote:
          \ available for 'superadmin' and 'admin'; 
          \ current user cannot be selected;
          \ selected user must be a 'member'

        - Demote:
          \ available for 'superadmin' and 'admin';
          \ current user cannot be selected;
          \ selected user must be an 'admin'

        - Add/Remove:
          \ available for 'superadmin' and 'admin';
          \ current user cannot be selected;
          \ selected user must be a 'member'
      */
      const isCurrentUserSuperadminOrAdmin = allParticipants.some(participant => participant.userId === user.id && ["superadmin", "admin"].includes(participant.userProfile));
      const isCurrentUserSelected = selectedParticipant.userId === user.id;
      const isSelectedUserAdmin = selectedParticipant.userProfile === "admin";
      const isSelectedUserMember = selectedParticipant.userProfile === "member";

      setIsPromoteOptionAvailable(isCurrentUserSuperadminOrAdmin && !isCurrentUserSelected && isSelectedUserMember);
      setIsDemoteOptionAvailable(isCurrentUserSuperadminOrAdmin && !isCurrentUserSelected && isSelectedUserAdmin);
      setIsRemoveOptionAvailable(isCurrentUserSuperadminOrAdmin && !isCurrentUserSelected && isSelectedUserMember);

      setAreThereAvailableOptions(
        (isCurrentUserSuperadminOrAdmin && !isCurrentUserSelected && isSelectedUserMember)
        || (isCurrentUserSuperadminOrAdmin && !isCurrentUserSelected && isSelectedUserAdmin)
        || (isCurrentUserSuperadminOrAdmin && !isCurrentUserSelected && isSelectedUserMember)
      );
    }
  }, [open, selectedParticipant, allParticipants, user]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();

    setIsPromoteOptionAvailable(false);
    setIsDemoteOptionAvailable(false);
    setIsRemoveOptionAvailable(false);
    setAreThereAvailableOptions(false);
  };

  const handlePromoteDemoteParticipant = async (ticketUserId, isPromotion) => {
    try {
      await api.patch(`/ticketUsers/promoteDemote/${ticketUserId}`, { isPromotion });
      onClose();
    } catch (exception) {
      toastError(exception);
    }
  };

  const handleRemoveParticipant = async (ticketUserId) => {
    try {
      await api.delete(`/ticketUsers/remove/${ticketUserId}`);
      onClose();
    } catch (exception) {
      toastError(exception);
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
    {areThereAvailableOptions && (
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        onContextMenu={event => event.preventDefault()}
      >
        {isPromoteOptionAvailable && (
          <MenuItem onClick={() => handlePromoteDemoteParticipant(selectedParticipant.id, true)}>
            {i18n.t("internalGroupParticipantsContextMenu.promote")}
          </MenuItem>
        )}
        
        {isDemoteOptionAvailable && (
          <MenuItem onClick={() => handlePromoteDemoteParticipant(selectedParticipant.id, false)}>
            {i18n.t("internalGroupParticipantsContextMenu.demote")}
          </MenuItem>
        )}
        
        {isRemoveOptionAvailable && (
          <MenuItem onClick={() => handleRemoveParticipant(selectedParticipant.id)}>
            {i18n.t("internalGroupParticipantsContextMenu.remove")}
          </MenuItem>
        )}
      </Menu>
    )}
    </>
  );
};

export default InternalGroupParticipantsContextMenu;
